export const marineWorldTheme = {
    "id": 169,
    "name": "عالم البحار",
    "slug": "marine-world",
    "description": "يضم مجموعة متنوعة من الكائنات البحرية.",
    "textures": {
        "right": "/memoryGame/themes/marine-world/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/marine-world/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/marine-world/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/marine-world/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/marine-world/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/marine-world/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "السلحفاة البحرية",
            "slug": "sea-turtle",
            "picture": "memoryGame/themes/marine-world/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/marine-world/sounds/sea-turtle_ar.mp3",
                "fr": "memoryGame/themes/marine-world/sounds/sea-turtle_fr.mp3",
                "en": "memoryGame/themes/marine-world/sounds/sea-turtle_en.mp3"
            },
            "funFact": "السلحفاة البحرية تعيش لعقود طويلة.",
            "info": {
                "description": "السلحفاة البحرية هي واحدة من أقدم الكائنات البحرية.",
                "bodyInfo": [
                    "تتمتع بقشرة صلبة تحميها.",
                    "تتغذى على الأعشاب البحرية والسمك.",
                    "تحتاج إلى الماء للعيش."
                ],
                "funFacts": [
                    "يمكن لبعض الأنواع العيش حتى 100 عام.",
                    "تعد السلحفاة من الزواحف، وتحتاج إلى السباحة للعيش.",
                    "تتميز بالقدرة على التنقل عبر المحيطات."
                ]
            }
        },
        {
            "id": 2,
            "name": "الأخطبوط",
            "slug": "octopus",
            "picture": "memoryGame/themes/marine-world/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/marine-world/sounds/octopus_ar.mp3",
                "fr": "memoryGame/themes/marine-world/sounds/octopus_fr.mp3",
                "en": "memoryGame/themes/marine-world/sounds/octopus_en.mp3"
            },
            "funFact": "الأخطبوط يملك ثمانية أذرع.",
            "info": {
                "description": "الأخطبوط هو حيوان بحري ذكي جداً.",
                "bodyInfo": [
                    "يمكنه تغيير لونه وملمسه.",
                    "يتغذى على الأسماك والرخويات.",
                    "يستخدم أذرعه للسباحة."
                ],
                "funFacts": [
                    "يمكن للأخطبوط الهروب من المفترسين باستخدام الحبر.",
                    "يمتلك الأخطبوط قدرة مذهلة على التعلم.",
                    "يعتبر الأخطبوط من الحيوانات الأكثر ذكاءً في البحر."
                ]
            }
        },
        {
            "id": 3,
            "name": "فرس البحر",
            "slug": "seahorse",
            "picture": "memoryGame/themes/marine-world/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/marine-world/sounds/seahorse_ar.mp3",
                "fr": "memoryGame/themes/marine-world/sounds/seahorse_fr.mp3",
                "en": "memoryGame/themes/marine-world/sounds/seahorse_en.mp3"
            },
            "funFact": "فرس البحر يسبح عمودياً.",
            "info": {
                "description": "فرس البحر هو نوع مميز من الأسماك.",
                "bodyInfo": [
                    "تتميز بجسمها المشابه لفرس.",
                    "يتغذى على القشريات الصغيرة.",
                    "يعتبر من الأنواع المهددة بالانقراض."
                ],
                "funFacts": [
                    "تتكون العائلات في فرس البحر من الذكور والإناث معاً.",
                    "يمكن أن يحمل الذكر البيض في كيس خاص.",
                    "يعيش في الأعشاب البحرية والشعاب المرجانية."
                ]
            }
        },
        {
            "id": 4,
            "name": "الدلفين",
            "slug": "dolphin",
            "picture": "memoryGame/themes/marine-world/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/marine-world/sounds/dolphin_ar.mp3",
                "fr": "memoryGame/themes/marine-world/sounds/dolphin_fr.mp3",
                "en": "memoryGame/themes/marine-world/sounds/dolphin_en.mp3"
            },
            "funFact": "الدلفين يعتبر من أذكى الحيوانات البحرية.",
            "info": {
                "description": "الدلفين هو نوع من الثدييات البحرية.",
                "bodyInfo": [
                    "يعيش في مجموعات تسمى أسراب.",
                    "يتغذى على الأسماك والحبار.",
                    "يستخدم الأصوات للتواصل."
                ],
                "funFacts": [
                    "يمكن للدلافين القفز من الماء إلى ارتفاعات كبيرة.",
                    "تعتبر من الحيوانات الاجتماعية للغاية.",
                    "تستخدم الدلافين التخطيط لحل المشكلات."
                ]
            }
        },
        {
            "id": 5,
            "name": "قنديل البحر",
            "slug": "jellyfish",
            "picture": "memoryGame/themes/marine-world/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/marine-world/sounds/jellyfish_ar.mp3",
                "fr": "memoryGame/themes/marine-world/sounds/jellyfish_fr.mp3",
                "en": "memoryGame/themes/marine-world/sounds/jellyfish_en.mp3"
            },
            "funFact": "قنديل البحر يمكن أن يكون سامًا.",
            "info": {
                "description": "قنديل البحر هو كائن بحري شفاف.",
                "bodyInfo": [
                    "يتكون من 95% من الماء.",
                    "يستخدم المجسات للدفاع عن نفسه.",
                    "يعيش في جميع المحيطات."
                ],
                "funFacts": [
                    "يمكن لبعض الأنواع أن تتوهج في الظلام.",
                    "قنديل البحر قد يعيش لعدة سنوات.",
                    "تعتبر قناديل البحر جزءًا من السلسلة الغذائية البحرية."
                ]
            }
        },
        {
            "id": 6,
            "name": "المحّار",
            "slug": "clam",
            "picture": "memoryGame/themes/marine-world/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/marine-world/sounds/clam_ar.mp3",
                "fr": "memoryGame/themes/marine-world/sounds/clam_fr.mp3",
                "en": "memoryGame/themes/marine-world/sounds/clam_en.mp3"
            },
            "funFact": "المحار يحتوي على لؤلؤ.",
            "info": {
                "description": "المحّار هو نوع من الرخويات.",
                "bodyInfo": [
                    "يعيش في المياه المالحة والعذبة.",
                    "يعتبر مصدراً غذائياً للعديد من الكائنات البحرية.",
                    "يعيش في قاع المحيطات."
                ],
                "funFacts": [
                    "تنتج بعض أنواع المحار اللؤلؤ.",
                    "يمكن أن يعيش المحار لعدة عقود.",
                    "تعتبر من الأطعمة الشهية للإنسان."
                ]
            }
        },
        {
            "id": 7,
            "name": "سمكة التزلج",
            "slug": "skate-fish",
            "picture": "memoryGame/themes/marine-world/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/marine-world/sounds/skate-fish_ar.mp3",
                "fr": "memoryGame/themes/marine-world/sounds/skate-fish_fr.mp3",
                "en": "memoryGame/themes/marine-world/sounds/skate-fish_en.mp3"
            },
            "funFact": "سمكة التزلج تسبح ببطء.",
            "info": {
                "description": "سمكة التزلج هي نوع من الأسماك المسطحة.",
                "bodyInfo": [
                    "تعيش في المياه المالحة.",
                    "تستخدم زعانفها للتنقل.",
                    "تتغذى على القشريات والرخويات."
                ],
                "funFacts": [
                    "تعتبر سمكة التزلج من الأسماك الغير مؤذية.",
                    "تحتاج إلى المياه النظيفة للعيش.",
                    "تعد من الكائنات البحرية المهمة للنظام البيئي."
                ]
            }
        },
        {
            "id": 8,
            "name": "سمكة المهرج",
            "slug": "clownfish",
            "picture": "memoryGame/themes/marine-world/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/marine-world/sounds/clownfish_ar.mp3",
                "fr": "memoryGame/themes/marine-world/sounds/clownfish_fr.mp3",
                "en": "memoryGame/themes/marine-world/sounds/clownfish_en.mp3"
            },
            "funFact": "سمكة المهرج تعيش في الشعاب المرجانية.",
            "info": {
                "description": "سمكة المهرج هي نوع ملون من الأسماك.",
                "bodyInfo": [
                    "تعيش في علاقات تكافلية مع قناديل البحر.",
                    "تتغذى على الطحالب والحشرات.",
                    "تعتبر من الأنواع المحبوبة لدى الناس."
                ],
                "funFacts": [
                    "سمكة المهرج كانت معروفة بفضل فيلم \"البحث عن نيمو\".",
                    "يمكن لسمكة المهرج أن تتكيف مع بيئتها.",
                    "تعتبر سمكة المهرج من الأسماك الجريئة."
                ]
            }
        },
        {
            "id": 9,
            "name": "سلطعون",
            "slug": "crab",
            "picture": "memoryGame/themes/marine-world/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/marine-world/sounds/crab_ar.mp3",
                "fr": "memoryGame/themes/marine-world/sounds/crab_fr.mp3",
                "en": "memoryGame/themes/marine-world/sounds/crab_en.mp3"
            },
            "funFact": "السلطعون يمشي جانبياً.",
            "info": {
                "description": "السلطعون هو كائن بحري له قشور.",
                "bodyInfo": [
                    "يعيش في قاع البحر.",
                    "يتغذى على الأسماك والنباتات.",
                    "يمتلك مخالب قوية للدفاع عن نفسه."
                ],
                "funFacts": [
                    "يمكن لبعض الأنواع أن تعيش في المياه العذبة.",
                    "يعتبر السلطعون جزءاً من الأطباق البحرية.",
                    "يمتلك بعض الأنواع ألواناً زاهية."
                ]
            }
        },
        {
            "id": 10,
            "name": "النجمة البحرية",
            "slug": "starfish",
            "picture": "memoryGame/themes/marine-world/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/marine-world/sounds/starfish_ar.mp3",
                "fr": "memoryGame/themes/marine-world/sounds/starfish_fr.mp3",
                "en": "memoryGame/themes/marine-world/sounds/starfish_en.mp3"
            },
            "funFact": "النجمة البحرية يمكن أن تفقد ذراعها وتنمو من جديد.",
            "info": {
                "description": "النجمة البحرية هي كائن بحري مثير للاهتمام.",
                "bodyInfo": [
                    "لديها شكل نجمة.",
                    "تعيش في قاع المحيط.",
                    "تتغذى على الرخويات."
                ],
                "funFacts": [
                    "يمكن لبعض أنواع النجوم البحرية أن تعيش لعدة عقود.",
                    "تستطيع النجوم البحرية التجدد بعد فقدان ذراعها.",
                    "تعتبر من الكائنات البحرية المميزة."
                ]
            }
        }
    ]
};
