import React, { useEffect, useRef, useState } from 'react';
import {Container,Grid, Typography, Box, Stack, Button, Chip} from '@mui/material';
import CardStack from "../Component/CardsStack";
import { motion } from "framer-motion";
import 'react-h5-audio-player/lib/styles.css';
import { themes } from "../json/memory_game/themes";
import TexturedBox from "../Component/ThreeDBox";
import { useCart } from "react-use-cart";
import FloatingCart from "../Component/common/FloatingCart";
import axios from "axios";
import {getRandomItem, injectProductsIntoThemes} from "../functions/helper";
import LaminatedCards from "../Component/MaterialAndSpecification";
import AgeRangePlayersComponent from "../Component/AgeRangePlayersComponent";
import SkillSection from "../Component/memory_game/SkillSection";
import QrCodeSection from "../Component/memory_game/QrCodeSection";
import QrCodeWebsiteExample from "../Component/memory_game/QrCodeExample";
import LoadingScreen from "../Component/common/LoadingScreen";
import Slider from "../Component/memory_game/CardsSlider";
import {openCart} from "../store/commonConfigSlice";
import {useDispatch} from "react-redux";
import FixedBottomComponent from "../Component/common/FixesBottomComponent";

const MemoryGame = () => {
    const { addItem, inCart } = useCart(); // Destructure addItem from useCart
    const [products, setProducts] = useState(themes.map(theme => theme.id));
    const [updatedThemes, setUpdatedThemes] = useState([]);
    const [error, setError] = useState(null);
    const [randomTheme, setRandomTheme] = useState(null); // Initialize as null
    const [randomCard, setRandomCard] = useState(null); // Initialize as null
    const cardRef = useRef(null); // Create a ref for the card
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [isDomReady, setIsDomReady] = useState(false); // Ensure DOM is ready

    // Ensure DOM is ready before rendering the canvas
    useEffect(() => {
        setIsDomReady(true);
    }, []);

    useEffect(() => {


        // Extract product IDs from themes
        const productIds = themes.flatMap(theme =>
            theme.products ? theme.products.map(product => product.id) : [] // Check if products exist
        );


        const fetchProducts = async () => {
            try {
                const response = await axios.post(`https://api.sghiri.tn/front/products/by_ids`, {
                    ids: products,
                }, {
                    headers: {
                        'Content-Type': 'application/json', // Ensure the correct content type is set
                    }
                });

                // Inject fetched products into themes
                const themesWithProducts = injectProductsIntoThemes(themes, response.data);

                setUpdatedThemes(themesWithProducts);
                setRandomTheme(getRandomItem(themesWithProducts)); // Set random theme when data is available
                setRandomCard(getRandomItem(themesWithProducts[0].cards)); // Set random card from the first theme
                setError(null); // Reset error state if fetch is successful
            } catch (err) {
                setError(err.response?.data?.error || 'An error occurred while fetching products');
                setUpdatedThemes([]); // Clear previous themes on error
            }
        };

        // Fetch products if there are IDs to fetch
        if (products.length > 0) {
            fetchProducts().then(() => setIsLoading(false));
        } else {
            setUpdatedThemes(themes); // No IDs to fetch; use the original themes
        }
    }, []);



    // Function to handle button click
    const handleAddTheme = (theme) => {
        const item = {
            name: theme.name,
            id: theme.id, // Assuming each theme has a unique ID
            price: theme.price, // Price of the item
            quantity: 1, // Quantity of the item
        };

        addItem(item); // Call addItem to add the theme to the cart
    };

    // Function to update the random card
    const handleSeeAnotherExample = () => {
        const newTheme = getRandomItem(updatedThemes); // Pick a new random theme
        const newCard = getRandomItem(newTheme.cards); // Pick a new random card from the new theme
        setRandomTheme(newTheme); // Update the state with the new theme
        setRandomCard(newCard); // Update the state with the new card

        // Scroll to the MemoryCard component
        if (cardRef.current) {
            cardRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const skillsData = [
        {
            title: "تقوية الذاكرة البصرية",
            description: "تعتمد اللعبة على تذكر أماكن البطاقات المتشابهة، مما يحسن من قدرة اللاعب على تذكر التفاصيل المرئية.",
            image: "/memoryGame/char/1.png",
        },
        {
            title: "التركيز والانتباه",
            description: "يحتاج اللاعب إلى التركيز أثناء الكشف عن البطاقات ليتمكن من العثور على الأزواج المتشابهة، مما يعزز قدرته على الانتباه.",
            image: "/memoryGame/char/2.png",
        },
        {
            title: "التحليل والاستراتيجية",
            description: "يبدأ اللاعب في تطوير استراتيجيات معينة لتذكر مواقع البطاقات واستخدام هذه المعلومات للتغلب على خصومه.",
            image: "/memoryGame/char/3.png",
        },
        {
            title: "الإدراك المكاني",
            description: "تحسن اللعبة الإدراك المكاني للاعبين من خلال تذكر مواقع البطاقات وترتيبها على اللوحة.",
            image: "/memoryGame/char/4.png",
        },
        {
            title: "الصبر والمثابرة",
            description: "يتطلب النجاح في اللعبة الصبر والمثابرة، خاصة إذا لم يعثر اللاعب على الأزواج المتطابقة بسرعة.",
            image: "/memoryGame/char/5.png",
        },
    ];
    const [isVisible, setIsVisible] = useState(true);
    const sections = [

        <QrCodeSection/>,
        <QrCodeWebsiteExample randomCard={randomCard} randomTheme={randomTheme} handleButton={handleSeeAnotherExample} cardRef={cardRef} />,
        // <LaminatedCards/>,
        <AgeRangePlayersComponent/>,
        <SkillSection index={0} skill={skillsData[0]}/>,
        <SkillSection index={1} skill={skillsData[1]}/>,
        <SkillSection index={2} skill={skillsData[2]}/>,
        <SkillSection index={3} skill={skillsData[3]}/>,
        <SkillSection index={4} skill={skillsData[4]}/>
    ];












    return (
        <>
            {/*{(!isLoading || !isDomReady) && (<LoadingScreen/>)}*/}
            {/*{*/}
            {/*    isDomReady &&*/}
                <Container maxWidth="lg">
                    <Grid container justifyContent={"center"} mt={5} spacing={2} xs={12}>
                        {/*<FloatingCart/>*/}
                        <FixedBottomComponent themes={updatedThemes}/>
                        <Grid container item justifyContent={"center"} xs={12}>
                            <motion.img
                                src={"/memoryGame/logo@4x.png"}
                                style={{ height: 200 }}
                                initial={{ scale: 0 }}
                                // animate={{ scale: 1 }}
                                whileInView={{scale : 1}}
                                transition={{
                                    type: "spring", // Use spring for a bouncy effect
                                    stiffness: 500, // Controls the "springiness" (higher = less bouncy)
                                    damping: 10, // Controls the oscillation (lower = more bounces)
                                    mass: 1, // Controls the weight of the spring
                                }}
                            />
                        </Grid>
                        <Grid container item justifyContent={"center"} xs={12} mt={2}>
                            <Typography variant="h4"
                                        sx={{
                                            color : "#0f76b3"
                                            }}
                                        gutterBottom>تعريف اللعبة</Typography>
                        </Grid>

                        <Grid container item xs={12}>
                            <Container maxWidth="sm" sx={{padding : 0}}>
                                <Grid container item spacing={1} justifyContent={"center"}>
                                    <Stack
                                        direction={"column"}
                                        spacing={2}
                                    >
                                        <Typography align={"center"} variant={"h6"}>
                                            لعبة الذاكرة هي وسيلة ممتعة و تفاعلية لتعزيز مهارات الذاكرة و التركيز لدى الأطفال.
                                            كما تمكّنهم من اكتساب مفردات جديدة و توسيع آفاقهم اللغوية بثلاث لغات مختلفة،
                                        </Typography>
                                        <Typography
                                            align={"center"}
                                            variant="h5"
                                            gutterBottom
                                            sx={{
                                                color : "#449d18"
                                            }}
                                        >
                                            العربية ، الفرنسية و الإنجلزية
                                        </Typography>
                                        <Typography align={"center"} color={"primary"} variant={"h4"}>
                                            مما يجعل التعلّم مغامرة شيّقة
                                        </Typography>

                                        <Slider/>
                                    </Stack>
                                </Grid>
                                <Grid container item xs={12} justifyContent={"center"} mt={5}>
                                    <Typography align={"center"} variant={"h3"}>متوفرة في 15 محور مختلف</Typography>
                                </Grid>

                                {
                                    updatedThemes.map((theme, index) => (
                                        <React.Fragment key={index}>
                                            {isVisible && (
                                                <>
                                                    <Grid container item xs={12} justifyContent="center" mt={5}>
                                                        <Typography align="center" variant="h3">
                                                            {theme.name} {/* Replace with dynamic title */}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid container item xs={12} justifyContent="center" mt={5}>
                                                        {isDomReady && <TexturedBox setIsLoaded={setIsLoading} textures={theme.textures}/>}

                                                    </Grid>

                                                    <Grid container item xs={12} mt={5}>
                                                        <CardStack theme={theme} />
                                                    </Grid>


                                                    {/* Floating Action Button */}
                                                    <Grid container justifyContent="center">
                                                        <motion.div
                                                            initial={{ opacity: 0, scale: 0.8 }}
                                                            whileInView={{ opacity: 1, scale: 1 }}
                                                            transition={{ duration: 0.5, delay: 0.2 }}
                                                        >
                                                            {
                                                                !inCart(theme.id) ?
                                                                    <Stack
                                                                        spacing={2}
                                                                        justifyContent="center"
                                                                    >
                                                                        <Typography variant={"h5"} color={"#2196F3"} align={"center"} direction={"rtl"}>
                                                                            سعر المحور : {theme.price}  ملّيم
                                                                        </Typography>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => handleAddTheme(theme)}
                                                                        >
                                                                            إضافة هذا المحور إلى السلّة
                                                                        </Button>
                                                                    </Stack>
                                                                     :
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => dispatch(openCart())}
                                                                    >
                                                                        تعديل الكميّة
                                                                    </Button>

                                                            }
                                                        </motion.div>
                                                    </Grid>

                                                    <Grid container item xs={12} mt={10}>
                                                        {sections[index]}
                                                    </Grid>
                                                </>
                                            )}
                                        </React.Fragment>
                                    ))
                                }



                            </Container>
                        </Grid>
                    </Grid>
                </Container>
            {/*}*/}
        </>



    );
};

export default MemoryGame;
