import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import {calendarImages} from "../../paths/calndarImages";

// Function to shuffle an array
const shuffleArray = (array) => {
    let shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
};

// Function to get 5 random days from each month
const getRandomDaysFromEachMonth = () => {
    const randomDays = [];
    for (let month = 1; month <= 12; month++) {
        const rectoDays = calendarImages[month]?.recto || [];
        const daysCount = rectoDays.length;

        if (daysCount > 0) {
            const days = Array.from({ length: daysCount }, (_, i) => ({ month, day: i }));
            const shuffledDays = shuffleArray(days).slice(0, 5);
            randomDays.push(...shuffledDays);
        }
    }
    return randomDays;
};

const preloadImage = (src) => {
    const img = new Image();
    img.src = src;
};

const AnimatedPapers = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [randomDays] = useState(getRandomDaysFromEachMonth());
    const [currentRandomIndex, setCurrentRandomIndex] = useState(0);

    const { month, day } = randomDays[currentRandomIndex] || { month: 1, day: 0 };
    const rectoImage = calendarImages[month]?.recto[day] || "default_recto.jpg";
    const versoImage = calendarImages[month]?.verso[day] || "default_verso.jpg";

    useEffect(() => {
        const nextIndex = (currentRandomIndex + 1) % randomDays.length;
        const nextDay = randomDays[nextIndex];
        if (nextDay) {
            const nextRectoImage = calendarImages[nextDay.month]?.recto[nextDay.day] || "default_recto.jpg";
            const nextVersoImage = calendarImages[nextDay.month]?.verso[nextDay.day] || "default_verso.jpg";
            preloadImage(nextRectoImage);
            preloadImage(nextVersoImage);
        }
    }, [currentRandomIndex, randomDays]);

    const cardVariants = {
        hidden: { opacity: 0, scale: 0.8, x: 0, y: 0 },
        visibleRecto: {
            opacity: 1,
            scale: 1,
            x: isSmallScreen ? 0 : "-120px",
            y: isSmallScreen ? "-5px" : 0,
            rotate: isSmallScreen ? 0 : -10,
            transition: { duration: 0.8 },
        },
        visibleVerso: {
            opacity: 1,
            scale: 1,
            x: isSmallScreen ? 0 : "120px",
            y: isSmallScreen ? "5px" : 0,
            rotate: isSmallScreen ? 0 : 10,
            transition: { duration: 0.8 },
        },
    };

    const showAnotherExample = () => {
        const nextIndex = (currentRandomIndex + 1) % randomDays.length;
        setCurrentRandomIndex(nextIndex);
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                height: isSmallScreen ? "700px" : "600px",
                minHeight: isSmallScreen ? "700px" : "600px",
                position: "relative",
                overflow: "hidden",
                mb: 10
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: isSmallScreen ? "column" : "row",
                    position: "relative",
                }}
            >
                <motion.img
                    key={`recto-${month}-${day}`}
                    src={rectoImage}
                    alt="Recto Paper"
                    style={{
                        width: isSmallScreen ? "60%" : "25%",
                        height: "auto",
                        objectFit: "contain",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                    variants={cardVariants}
                    initial="hidden"
                    animate="visibleRecto"
                />
                <motion.img
                    key={`verso-${month}-${day}`}
                    src={versoImage}
                    alt="Verso Paper"
                    style={{
                        width: isSmallScreen ? "60%" : "25%",
                        height: "auto",
                        objectFit: "contain",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                    variants={cardVariants}
                    initial="hidden"
                    animate="visibleVerso"
                />
            </Box>
            <Button
                variant="contained"
                onClick={showAnotherExample}
                sx={{
                    position: "absolute",
                    bottom: isSmallScreen ? "10px" : "20px",
                    right: isSmallScreen ? "10px" : "20px",
                    zIndex: 10,
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    backgroundColor: theme.palette.primary.main,
                }}
            >
                مثال أخر
            </Button>
        </Box>
    );
};

export default AnimatedPapers;
