export const getRandomSoftColor = () => {
    const softColors = [
        "#fce4ec", // soft pink
        "#e3f2fd", // soft blue
        "#f3e5f5", // soft lavender
        "#e8f5e9", // soft mint green
        "#fff9c4", // soft yellow
        "#f0f4c3", // soft lime
        "#f0e4d7", // soft beige
    ];
    const randomIndex = Math.floor(Math.random() * softColors.length);
    return softColors[randomIndex];
};



export const injectProductsIntoThemes = (themes, products) => {


    const updatedThemes = [];
    themes.forEach(theme => {
        const product = products.find(product => product.id === theme.id);
        if(product){
           updatedThemes.push({
               ...theme,
               name : product.name,
               price : product.price
           })
        }

    });

    return updatedThemes;
};

export const getRandomItem = (array) => {
    return array[Math.floor(Math.random() * array.length)];
};