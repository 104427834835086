export const humanBodyTheme = {
    "id": 168,
    "name": "جسم الإنسان",
    "slug": "human-body",
    "description": "يضم مجموعة متنوعة من أجزاء الجسم المختلفة.",
    "textures": {
        "right": "/memoryGame/themes/human-body/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/human-body/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/human-body/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/human-body/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/human-body/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/human-body/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "العيون",
            "slug": "eyes",
            "picture": "memoryGame/themes/human-body/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/human-body/sounds/eyes_ar.mp3",
                "fr": "memoryGame/themes/human-body/sounds/eyes_fr.mp3",
                "en": "memoryGame/themes/human-body/sounds/eyes_en.mp3"
            },
            "funFact": "الأنف يساعدنا في الشم.",
            "info": {
                "description": "العيون هما عضوين حيويين لرؤية العالم من حولنا.",
                "bodyInfo": [
                    "كل عين تحتوي على قرنية وعدسة.",
                    "تمكننا من رؤية الألوان والأشكال.",
                    "تتكون من أجزاء مثل الحدقة والشبكية."
                ],
                "funFacts": [
                    "يمكن للعين أن تميز بين ملايين الألوان.",
                    "تعتبر من أكثر الأعضاء حساسية.",
                    "تحتاج العين إلى العناية للحفاظ على صحتها."
                ]
            }
        },
        {
            "id": 2,
            "name": "الأنف",
            "slug": "nose",
            "picture": "memoryGame/themes/human-body/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/human-body/sounds/nose_ar.mp3",
                "fr": "memoryGame/themes/human-body/sounds/nose_fr.mp3",
                "en": "memoryGame/themes/human-body/sounds/nose_en.mp3"
            },
            "funFact": "الأنف يساعدنا في الشم.",
            "info": {
                "description": "الأنف هو العضو المسؤول عن حاسة الشم.",
                "bodyInfo": [
                    "يتكون من غضاريف وعضلات.",
                    "يحتوي على خلايا حسية لتحديد الروائح.",
                    "يعمل على ترطيب الهواء قبل دخوله الرئتين."
                ],
                "funFacts": [
                    "يمكن للأنف تمييز آلاف الروائح المختلفة.",
                    "يعتبر الأنف جزءًا مهمًا من الجهاز التنفسي.",
                    "يمتلك الأنف أيضاً دورًا في التذوق."
                ]
            }
        },
        {
            "id": 3,
            "name": "الأسنان",
            "slug": "teeth",
            "picture": "memoryGame/themes/human-body/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/human-body/sounds/teeth_ar.mp3",
                "fr": "memoryGame/themes/human-body/sounds/teeth_fr.mp3",
                "en": "memoryGame/themes/human-body/sounds/teeth_en.mp3"
            },
            "funFact": "الأسنان تساعدنا في المضغ.",
            "info": {
                "description": "الأسنان هي الأعضاء المسؤولة عن طحن الطعام.",
                "bodyInfo": [
                    "توجد أنواع مختلفة من الأسنان مثل الأنياب والأضراس.",
                    "تساعد في التحدث بشكل صحيح.",
                    "تحتاج الأسنان إلى العناية اليومية."
                ],
                "funFacts": [
                    "للإنسان البالغ 32 سناً.",
                    "تتكون الأسنان من المينا والعاج.",
                    "يمكن أن تتأثر الأسنان بالتغذية السيئة."
                ]
            }
        },
        {
            "id": 4,
            "name": "الأذن",
            "slug": "ear",
            "picture": "memoryGame/themes/human-body/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/human-body/sounds/ear_ar.mp3",
                "fr": "memoryGame/themes/human-body/sounds/ear_fr.mp3",
                "en": "memoryGame/themes/human-body/sounds/ear_en.mp3"
            },
            "funFact": "الأذن تساعدنا في السمع.",
            "info": {
                "description": "الأذن هي العضو المسؤول عن حاسة السمع.",
                "bodyInfo": [
                    "تتكون من ثلاثة أجزاء: الأذن الخارجية، الأذن الوسطى، والأذن الداخلية.",
                    "تساعدنا في التوازن أيضًا.",
                    "تحتاج الأذن إلى العناية لتجنب الالتهابات."
                ],
                "funFacts": [
                    "الأذن يمكن أن تسمع الأصوات بمختلف الترددات.",
                    "السمع يمكن أن يتأثر بالعوامل البيئية.",
                    "تعتبر الأذن من الأعضاء المعقدة في الجسم."
                ]
            }
        },
        {
            "id": 5,
            "name": "اليد",
            "slug": "hand",
            "picture": "memoryGame/themes/human-body/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/human-body/sounds/hand_ar.mp3",
                "fr": "memoryGame/themes/human-body/sounds/hand_fr.mp3",
                "en": "memoryGame/themes/human-body/sounds/hand_en.mp3"
            },
            "funFact": "اليد تستخدم للكتابة والأكل.",
            "info": {
                "description": "اليد هي عضو يساعدنا في أداء العديد من الأنشطة.",
                "bodyInfo": [
                    "تتكون اليد من الكف والأصابع.",
                    "تستخدم في الإمساك بالأشياء.",
                    "يمكن أن تتأثر بالإصابات."
                ],
                "funFacts": [
                    "اليد يمكن أن تقوم بمئات الحركات المختلفة.",
                    "تعتبر اليد جزءًا أساسيًا في التواصل.",
                    "تحتاج اليد إلى العناية للحفاظ على المرونة."
                ]
            }
        },
        {
            "id": 6,
            "name": "الساق",
            "slug": "leg",
            "picture": "memoryGame/themes/human-body/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/human-body/sounds/leg_ar.mp3",
                "fr": "memoryGame/themes/human-body/sounds/leg_fr.mp3",
                "en": "memoryGame/themes/human-body/sounds/leg_en.mp3"
            },
            "funFact": "الساق تساعدنا على المشي.",
            "info": {
                "description": "الساق هي العضو المسؤول عن الحركة.",
                "bodyInfo": [
                    "تتكون من الفخذ والساق والقدم.",
                    "تساعد في دعم وزن الجسم.",
                    "تحتاج الساق إلى القوة والمرونة."
                ],
                "funFacts": [
                    "تعتبر الساق جزءًا أساسيًا في الحركة.",
                    "يمكن أن تتأثر بالسقوط أو الإصابات.",
                    "تحتاج الساق إلى التمارين للحفاظ على صحتها."
                ]
            }
        },
        {
            "id": 7,
            "name": "اللسان",
            "slug": "tongue",
            "picture": "memoryGame/themes/human-body/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/human-body/sounds/tongue_ar.mp3",
                "fr": "memoryGame/themes/human-body/sounds/tongue_fr.mp3",
                "en": "memoryGame/themes/human-body/sounds/tongue_en.mp3"
            },
            "funFact": "اللسان يساعدنا في التذوق.",
            "info": {
                "description": "اللسان هو العضو المسؤول عن حاسة التذوق.",
                "bodyInfo": [
                    "يحتوي على براعم التذوق.",
                    "يساعد في الكلام أيضًا.",
                    "يحتاج إلى العناية للحفاظ على صحته."
                ],
                "funFacts": [
                    "يمكن للسان تمييز خمسة أنواع من النكهات.",
                    "يعتبر اللسان من أكثر العضلات نشاطًا.",
                    "يحتاج اللسان إلى نظافة منتظمة."
                ]
            }
        },
        {
            "id": 8,
            "name": "الذراع",
            "slug": "arm",
            "picture": "memoryGame/themes/human-body/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/human-body/sounds/arm_ar.mp3",
                "fr": "memoryGame/themes/human-body/sounds/arm_fr.mp3",
                "en": "memoryGame/themes/human-body/sounds/arm_en.mp3"
            },
            "funFact": "الذراع تساعدنا في رفع الأشياء.",
            "info": {
                "description": "الذراع هي العضو الذي يربط بين الكتف واليد.",
                "bodyInfo": [
                    "تتكون من ثلاثة أجزاء: الكتف، الساعد، واليد.",
                    "تساعد في حركة اليد بشكل أكبر.",
                    "يمكن أن تتعرض للإصابات."
                ],
                "funFacts": [
                    "تعتبر الذراع جزءًا مهمًا في الأنشطة اليومية.",
                    "تحتاج الذراع إلى قوة ومرونة.",
                    "تعتبر من الأعضاء التي تحتاج إلى تمرين مستمر."
                ]
            }
        },
        {
            "id": 9,
            "name": "القدم",
            "slug": "foot",
            "picture": "memoryGame/themes/human-body/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/human-body/sounds/foot_ar.mp3",
                "fr": "memoryGame/themes/human-body/sounds/foot_fr.mp3",
                "en": "memoryGame/themes/human-body/sounds/foot_en.mp3"
            },
            "funFact": "القدم تساعدنا في المشي.",
            "info": {
                "description": "القدم هي الجزء السفلي من الساق.",
                "bodyInfo": [
                    "تتكون من الكعب والأصابع.",
                    "تساعد في التوازن.",
                    "تحتاج إلى العناية لتجنب الألم."
                ],
                "funFacts": [
                    "يمتلك الشخص البالغ 26 عظمة في القدم.",
                    "تعتبر القدمين من الأجزاء التي تحمل وزن الجسم.",
                    "تحتاج القدمين إلى الراحة بعد الأنشطة."
                ]
            }
        },
        {
            "id": 10,
            "name": "الرأس",
            "slug": "head",
            "picture": "memoryGame/themes/human-body/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/human-body/sounds/head_ar.mp3",
                "fr": "memoryGame/themes/human-body/sounds/head_fr.mp3",
                "en": "memoryGame/themes/human-body/sounds/head_en.mp3"
            },
            "funFact": "الرأس يحتوي على المخ.",
            "info": {
                "description": "الرأس هو الجزء العلوي من الجسم.",
                "bodyInfo": [
                    "يحتوي على الدماغ والعينين والأذنين.",
                    "يعتبر مركز التحكم في الجسم.",
                    "يحتاج إلى الحماية من الإصابات."
                ],
                "funFacts": [
                    "الدماغ يحتوي على مليارات الخلايا العصبية.",
                    "يعتبر الرأس جزءًا مهمًا من التعلم والتفكير.",
                    "تحتاج الرأس إلى الراحة والنوم الجيد."
                ]
            }
        }
    ]
};
