import { createSlice } from '@reduxjs/toolkit';

// Initial state
const initialState = {
    isCartOpen: false,
};

// Create the slice
const commonConfigSlice = createSlice({
    name: 'commonConfig', // Name of the slice
    initialState,
    reducers: {
        toggleCart: (state) => {
            state.isCartOpen = !state.isCartOpen;  // Toggle the cart open state
        },
        openCart: (state) => {
            state.isCartOpen = true;  // Set the cart to open
        },
        closeCart: (state) => {
            state.isCartOpen = false;  // Set the cart to closed
        },
    },
});

// Export actions to use in components
export const { toggleCart, openCart, closeCart } = commonConfigSlice.actions;

// Export the reducer to register in the store
export default commonConfigSlice.reducer;
