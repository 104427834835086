export const insectsTheme = {
    "id": 163,
    "name": "الحشرات",
    "slug": "insects",
    "description": "يتضمن مجموعة من الحشرات.",
    "textures": {
        "right": "/memoryGame/themes/insects/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/insects/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/insects/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/insects/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/insects/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/insects/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "الذباب",
            "slug": "fly",
            "picture": "memoryGame/themes/insects/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/insects/sounds/fly_ar.mp3",
                "fr": "memoryGame/themes/insects/sounds/fly_fr.mp3",
                "en": "memoryGame/themes/insects/sounds/fly_en.mp3"
            },
            "funFact": "الذباب يتواجد في جميع أنحاء العالم.",
            "info": {
                "description": "الذبابة هي حشرة صغيرة تملك جناحين.",
                "bodyInfo": [
                    "الذبابة تُعتبر من أسرع الحشرات طيرانًا.",
                    "تتغذى على مجموعة متنوعة من المواد.",
                    "يمكن أن تنقل الذبابة الأمراض."
                ],
                "funFacts": [
                    "بعض أنواع الذباب تساعد في تلقيح الأزهار.",
                    "تعيش الذبابة لبضعة أسابيع فقط.",
                    "تُستخدم في الأبحاث العلمية."
                ]
            }
        },
        {
            "id": 2,
            "name": "الجراد",
            "slug": "grasshopper",
            "picture": "memoryGame/themes/insects/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/insects/sounds/grasshopper_ar.mp3",
                "fr": "memoryGame/themes/insects/sounds/grasshopper_fr.mp3",
                "en": "memoryGame/themes/insects/sounds/grasshopper_en.mp3"
            },
            "funFact": "الجراد يمكنه القفز لمسافات طويلة.",
            "info": {
                "description": "الجراد هو حشرة معروفة بقفزاتها العالية.",
                "bodyInfo": [
                    "يعتبر الجراد من آفات المحاصيل.",
                    "يتغذى الجراد على النباتات.",
                    "يعيش الجراد في مجموعات كبيرة."
                ],
                "funFacts": [
                    "يستطيع الجراد تغيير لونه حسب البيئة.",
                    "يمكن للجراد أن يأكل كميات كبيرة من النباتات.",
                    "تستطيع أسراب الجراد تدمير المحاصيل."
                ]
            }
        },
        {
            "id": 3,
            "name": "النملة",
            "slug": "ant",
            "picture": "memoryGame/themes/insects/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/insects/sounds/ant_ar.mp3",
                "fr": "memoryGame/themes/insects/sounds/ant_fr.mp3",
                "en": "memoryGame/themes/insects/sounds/ant_en.mp3"
            },
            "funFact": "النمل يعيش في مستعمرات كبيرة.",
            "info": {
                "description": "النمل هو حشرة صغيرة تعيش في مجموعات.",
                "bodyInfo": [
                    "تتميز النملة بقوتها النسبية.",
                    "يعمل النمل معًا لجمع الغذاء.",
                    "يمكن أن تعيش مستعمرات النمل لسنوات."
                ],
                "funFacts": [
                    "بعض أنواع النمل تملك القدرة على التنبؤ بالطقس.",
                    "يمكن للنمل حمل أشياء تعادل وزنه عدة مرات.",
                    "تتعاون النمل مع أنواع أخرى من الحشرات."
                ]
            }
        },
        {
            "id": 4,
            "name": "الدعسوقة",
            "slug": "ladybug",
            "picture": "memoryGame/themes/insects/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/insects/sounds/ladybug_ar.mp3",
                "fr": "memoryGame/themes/insects/sounds/ladybug_fr.mp3",
                "en": "memoryGame/themes/insects/sounds/ladybug_en.mp3"
            },
            "funFact": "دعسوقة تُعتبر حشرة مفيدة للمزارعين.",
            "info": {
                "description": "الدعسوقة هي حشرة صغيرة لها ألوان زاهية.",
                "bodyInfo": [
                    "تأكل الدعسوقة حشرات الآفات.",
                    "تُعتبر الدعسوقة رمزًا للحظ الجيد.",
                    "تعيش الدعسوقة في الحدائق."
                ],
                "funFacts": [
                    "تتغير ألوان الدعسوقة حسب النوع.",
                    "يمكن للدعسوقة أن تتكاثر بسرعة.",
                    "تُعتبر الدعسوقة واحدة من أجمل الحشرات."
                ]
            }
        },
        {
            "id": 5,
            "name": "اليعسوب",
            "slug": "dragonfly",
            "picture": "memoryGame/themes/insects/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/insects/sounds/dragonfly_ar.mp3",
                "fr": "memoryGame/themes/insects/sounds/dragonfly_fr.mp3",
                "en": "memoryGame/themes/insects/sounds/dragonfly_en.mp3"
            },
            "funFact": "اليعسوب هو طائر سريع جداً.",
            "info": {
                "description": "اليعسوب هو حشرة معروفة بجناحيها الكبيرين.",
                "bodyInfo": [
                    "يتغذى اليعسوب على الحشرات الأخرى.",
                    "يمكن أن يطير اليعسوب في جميع الاتجاهات.",
                    "يمتاز بألوانه الزاهية."
                ],
                "funFacts": [
                    "يعيش اليعسوب في البيئات المائية.",
                    "يمكن لليعسوب أن يطير بسرعة تصل إلى 60 كم/ساعة.",
                    "تُعتبر اليعسوب من أقدم الحشرات على الأرض."
                ]
            }
        },
        {
            "id": 6,
            "name": "الدبور",
            "slug": "wasp",
            "picture": "memoryGame/themes/insects/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/insects/sounds/wasp_ar.mp3",
                "fr": "memoryGame/themes/insects/sounds/wasp_fr.mp3",
                "en": "memoryGame/themes/insects/sounds/wasp_en.mp3"
            },
            "funFact": "الدبابير تملك لدغة مؤلمة.",
            "info": {
                "description": "الدبور هو حشرة قريبة من النحل.",
                "bodyInfo": [
                    "يتغذى الدبور على الحشرات الأخرى.",
                    "يعيش الدبور في مستعمرات.",
                    "تعتبر الدبابير مفيدة في الزراعة."
                ],
                "funFacts": [
                    "يمكن أن تهاجم الدبابير إذا شعرت بالتهديد.",
                    "تتعاون الدبابير في بناء الأعشاش.",
                    "بعض الأنواع من الدبابير تأكل الفواكه."
                ]
            }
        },
        {
            "id": 7,
            "name": "العنكبوت",
            "slug": "spider",
            "picture": "memoryGame/themes/insects/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/insects/sounds/spider_ar.mp3",
                "fr": "memoryGame/themes/insects/sounds/spider_fr.mp3",
                "en": "memoryGame/themes/insects/sounds/spider_en.mp3"
            },
            "funFact": "العنكبوت يُعتبر حشرة مفترسة.",
            "info": {
                "description": "العنكبوت هو حيوان له ثمانية أرجل.",
                "bodyInfo": [
                    "يتغذى العنكبوت على الحشرات الأخرى.",
                    "يستخدم العنكبوت خيوطه لصيد الفرائس.",
                    "يمكن أن يعيش العنكبوت في البيئات المتنوعة."
                ],
                "funFacts": [
                    "بعض أنواع العناكب يمكن أن تكون سامة.",
                    "تستخدم خيوط العنكبوت في الطب.",
                    "هناك أنواع من العناكب تكون اجتماعية."
                ]
            }
        },
        {
            "id": 8,
            "name": "الفراشة",
            "slug": "butterfly",
            "picture": "memoryGame/themes/insects/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/insects/sounds/butterfly_ar.mp3",
                "fr": "memoryGame/themes/insects/sounds/butterfly_fr.mp3",
                "en": "memoryGame/themes/insects/sounds/butterfly_en.mp3"
            },
            "funFact": "الفراشات تعيش لفترة قصيرة.",
            "info": {
                "description": "الفراشة هي حشرة معروفة بأجنحتها الملونة.",
                "bodyInfo": [
                    "تتغذى الفراشات على رحيق الأزهار.",
                    "تمتاز الفراشات بألوانها المتنوعة.",
                    "يمكن أن تتغير الفراشة خلال دورة حياتها."
                ],
                "funFacts": [
                    "بعض الأنواع تهاجر لمسافات طويلة.",
                    "تُعتبر الفراشات رمزًا للجمال.",
                    "تحتاج الفراشات إلى درجات حرارة دافئة للطيران."
                ]
            }
        },
        {
            "id": 9,
            "name": "الصرصور",
            "slug": "cockroach",
            "picture": "memoryGame/themes/insects/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/insects/sounds/cockroach_ar.mp3",
                "fr": "memoryGame/themes/insects/sounds/cockroach_fr.mp3",
                "en": "memoryGame/themes/insects/sounds/cockroach_en.mp3"
            },
            "funFact": "الصرصور يُعتبر من أسرع الحشرات.",
            "info": {
                "description": "الصرصور هو حشرة لها جسم مسطح.",
                "bodyInfo": [
                    "يتغذى الصرصور على بقايا الطعام.",
                    "يمكن أن يعيش الصرصور لفترة طويلة دون طعام.",
                    "يتواجد الصرصور في الأماكن الدافئة."
                ],
                "funFacts": [
                    "يمكن للصرصور أن يركض بسرعة تصل إلى 5 كم/ساعة.",
                    "تعتبر بعض الأنواع من الصراصير حشرات مائية.",
                    "تُستخدم الصراصير في بعض الثقافات كطعام."
                ]
            }
        },
        {
            "id": 10,
            "name": "النحل",
            "slug": "bee",
            "picture": "memoryGame/themes/insects/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/insects/sounds/bee_ar.mp3",
                "fr": "memoryGame/themes/insects/sounds/bee_fr.mp3",
                "en": "memoryGame/themes/insects/sounds/bee_en.mp3"
            },
            "funFact": "النحل يلعب دورًا هامًا في تلقيح الأزهار.",
            "info": {
                "description": "النحل هو حشرة مهمة للإنتاج الزراعي.",
                "bodyInfo": [
                    "تتعاون النحل في جمع الرحيق.",
                    "تعيش النحل في مستعمرات.",
                    "يمكن أن تنتج النحل العسل."
                ],
                "funFacts": [
                    "النحل يموت بعد اللدغة.",
                    "تستطيع النحل تحديد موقع الزهور من خلال الرائحة.",
                    "تعتبر النحل من أكثر الحشرات أهمية في الطبيعة."
                ]
            }
        }
    ]
};
