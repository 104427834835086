import React from "react";
import Masonry from "@mui/lab/Masonry";
import { Box, Typography, Card, CardMedia } from "@mui/material";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Optional for blur effect

const ClientReviews = () => {
    // List of image URLs
    const images = Array.from({ length: 12 }, (_, i) => `calendar/avis/${i + 1}.jpg`);

    return (
        <Box
            sx={{
                padding: 4,
                backgroundColor: "#f5f5f5",
                borderRadius: 2,
                textAlign: "center",
            }}
        >
            {/* Animated Title */}
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.7 }}
                viewport={{ once: true }}
            >
                <Typography
                    variant="h4"
                    component="h2"
                    sx={{
                        marginBottom: 4,
                        fontWeight: "bold",
                        color: "#333",
                    }}
                >
                    أراء الحرفاء
                </Typography>
            </motion.div>

            {/* Masonry Grid */}
            <Masonry
                columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                spacing={2}
                sx={{
                    margin: 0,
                }}
            >
                {images.map((src, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, scale: 0.8 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.5,
                            delay: index * 0.1,
                            ease: "easeInOut",
                        }}
                        viewport={{ once: true }}
                    >
                        <Card
                            elevation={3}
                            sx={{
                                borderRadius: 2,
                                overflow: "hidden",
                                boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                                },
                            }}
                        >
                            <LazyLoadImage
                                effect="blur" // Optional: Adds a blur effect while loading
                                src={src}
                                alt={`Client review ${index + 1}`}
                                style={{
                                    width: "100%",
                                    height: "auto",
                                }}
                            />
                        </Card>
                    </motion.div>
                ))}
            </Masonry>
        </Box>
    );
};

export default ClientReviews;
