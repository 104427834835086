import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Typography from '@mui/material/Typography';
import moment from "moment";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {Stack} from "@mui/material";
import {useState} from "react";

export default function DeliveryTimeline({order}) {

    const orderDate = moment(order.created_at);
    const [Zin , setZIn] = useState(true);

    function isWeekEnd(add) {
        let newDate = null;
        if(orderDate.format("dd") === "Sa") {
            newDate = moment(orderDate).add(add + 1,"day").format("DD-MM-Y");
        } else {
            newDate = moment(orderDate).add(add ,"day").format("DD-MM-Y");
        }
        return newDate;
    }

    return (
        <Timeline position="alternate">
            {/* Order Reception */}
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    <Stack>
                        {orderDate.locale('fr').format("DD-MM-Y HH:mm")}
                    </Stack>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color="success">
                        <DoneAllIcon sx={{ color: "white" }} />
                    </TimelineDot>
                    <TimelineConnector color="success" />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Réception de la commande
                    </Typography>
                    <Typography>
                        Commande reçue. Merci pour votre confiance.
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            {/* Pickup by Intigo */}
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="left"
                    variant="body2"
                    color="text.secondary"
                >
                    {isWeekEnd(1)}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                        <ExitToAppIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Stack>
                        <Typography variant="h6" component="span">
                            Prise en charge par Intigo
                        </Typography>
                        <Typography>
                            Commande prise en charge par Intigo pour livraison.
                        </Typography>
                    </Stack>
                </TimelineContent>
            </TimelineItem>

            {/* Delivery Estimated Date */}
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    <Stack>
                        Entre le {isWeekEnd(2)} et le {isWeekEnd(3)}
                    </Stack>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                        <LocalShippingIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Stack justifyContent="center">
                        <Typography variant="h6" component="span">
                            Livraison en cours
                        </Typography>
                        <Typography>
                            Livraison estimée entre {isWeekEnd(1)} et {isWeekEnd(2)}.
                        </Typography>
                    </Stack>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
}
