export const calendarImages = {
    "1": {
        "monthNameArabic": "\u064a\u0646\u0627\u064a\u0631",
        "recto": [
            "calendar/papers/1/recto/1.jpg",
            "calendar/papers/1/recto/2.jpg",
            "calendar/papers/1/recto/3.jpg",
            "calendar/papers/1/recto/4.jpg",
            "calendar/papers/1/recto/5.jpg",
            "calendar/papers/1/recto/6.jpg",
            "calendar/papers/1/recto/7.jpg",
            "calendar/papers/1/recto/8.jpg",
            "calendar/papers/1/recto/9.jpg",
            "calendar/papers/1/recto/10.jpg",
            "calendar/papers/1/recto/11.jpg",
            "calendar/papers/1/recto/12.jpg",
            "calendar/papers/1/recto/13.jpg",
            "calendar/papers/1/recto/14.jpg",
            "calendar/papers/1/recto/15.jpg",
            "calendar/papers/1/recto/16.jpg",
            "calendar/papers/1/recto/17.jpg",
            "calendar/papers/1/recto/18.jpg",
            "calendar/papers/1/recto/19.jpg",
            "calendar/papers/1/recto/20.jpg",
            "calendar/papers/1/recto/21.jpg",
            "calendar/papers/1/recto/22.jpg",
            "calendar/papers/1/recto/23.jpg",
            "calendar/papers/1/recto/24.jpg",
            "calendar/papers/1/recto/25.jpg",
            "calendar/papers/1/recto/26.jpg",
            "calendar/papers/1/recto/27.jpg",
            "calendar/papers/1/recto/28.jpg",
            "calendar/papers/1/recto/29.jpg",
            "calendar/papers/1/recto/30.jpg",
            "calendar/papers/1/recto/31.jpg"
        ],
        "verso": [
            "calendar/papers/1/VERSO/1.jpg",
            "calendar/papers/1/VERSO/2.jpg",
            "calendar/papers/1/VERSO/3.jpg",
            "calendar/papers/1/VERSO/4.jpg",
            "calendar/papers/1/VERSO/5.jpg",
            "calendar/papers/1/VERSO/6.jpg",
            "calendar/papers/1/VERSO/7.jpg",
            "calendar/papers/1/VERSO/8.jpg",
            "calendar/papers/1/VERSO/9.jpg",
            "calendar/papers/1/VERSO/10.jpg",
            "calendar/papers/1/VERSO/11.jpg",
            "calendar/papers/1/VERSO/12.jpg",
            "calendar/papers/1/VERSO/13.jpg",
            "calendar/papers/1/VERSO/14.jpg",
            "calendar/papers/1/VERSO/15.jpg",
            "calendar/papers/1/VERSO/16.jpg",
            "calendar/papers/1/VERSO/17.jpg",
            "calendar/papers/1/VERSO/18.jpg",
            "calendar/papers/1/VERSO/19.jpg",
            "calendar/papers/1/VERSO/20.jpg",
            "calendar/papers/1/VERSO/21.jpg",
            "calendar/papers/1/VERSO/22.jpg",
            "calendar/papers/1/VERSO/23.jpg",
            "calendar/papers/1/VERSO/24.jpg",
            "calendar/papers/1/VERSO/25.jpg",
            "calendar/papers/1/VERSO/26.jpg",
            "calendar/papers/1/VERSO/27.jpg",
            "calendar/papers/1/VERSO/28.jpg",
            "calendar/papers/1/VERSO/29.jpg",
            "calendar/papers/1/VERSO/30.jpg",
            "calendar/papers/1/VERSO/31.jpg"
        ]
    },
    "2": {
        "monthNameArabic": "\u0641\u0628\u0631\u0627\u064a\u0631",
        "recto": [
            "calendar/papers/2/recto/1.jpg",
            "calendar/papers/2/recto/2.jpg",
            "calendar/papers/2/recto/3.jpg",
            "calendar/papers/2/recto/4.jpg",
            "calendar/papers/2/recto/5.jpg",
            "calendar/papers/2/recto/6.jpg",
            "calendar/papers/2/recto/7.jpg",
            "calendar/papers/2/recto/8.jpg",
            "calendar/papers/2/recto/9.jpg",
            "calendar/papers/2/recto/10.jpg",
            "calendar/papers/2/recto/11.jpg",
            "calendar/papers/2/recto/12.jpg",
            "calendar/papers/2/recto/13.jpg",
            "calendar/papers/2/recto/14.jpg",
            "calendar/papers/2/recto/15.jpg",
            "calendar/papers/2/recto/16.jpg",
            "calendar/papers/2/recto/17.jpg",
            "calendar/papers/2/recto/18.jpg",
            "calendar/papers/2/recto/19.jpg",
            "calendar/papers/2/recto/20.jpg",
            "calendar/papers/2/recto/21.jpg",
            "calendar/papers/2/recto/22.jpg",
            "calendar/papers/2/recto/23.jpg",
            "calendar/papers/2/recto/24.jpg",
            "calendar/papers/2/recto/25.jpg",
            "calendar/papers/2/recto/26.jpg",
            "calendar/papers/2/recto/27.jpg",
            "calendar/papers/2/recto/28.jpg"
        ],
        "verso": [
            "calendar/papers/2/VERSO/1.jpg",
            "calendar/papers/2/VERSO/2.jpg",
            "calendar/papers/2/VERSO/3.jpg",
            "calendar/papers/2/VERSO/4.jpg",
            "calendar/papers/2/VERSO/5.jpg",
            "calendar/papers/2/VERSO/6.jpg",
            "calendar/papers/2/VERSO/7.jpg",
            "calendar/papers/2/VERSO/8.jpg",
            "calendar/papers/2/VERSO/9.jpg",
            "calendar/papers/2/VERSO/10.jpg",
            "calendar/papers/2/VERSO/11.jpg",
            "calendar/papers/2/VERSO/12.jpg",
            "calendar/papers/2/VERSO/13.jpg",
            "calendar/papers/2/VERSO/14.jpg",
            "calendar/papers/2/VERSO/15.jpg",
            "calendar/papers/2/VERSO/16.jpg",
            "calendar/papers/2/VERSO/17.jpg",
            "calendar/papers/2/VERSO/18.jpg",
            "calendar/papers/2/VERSO/19.jpg",
            "calendar/papers/2/VERSO/20.jpg",
            "calendar/papers/2/VERSO/21.jpg",
            "calendar/papers/2/VERSO/22.jpg",
            "calendar/papers/2/VERSO/23.jpg",
            "calendar/papers/2/VERSO/24.jpg",
            "calendar/papers/2/VERSO/25.jpg",
            "calendar/papers/2/VERSO/26.jpg",
            "calendar/papers/2/VERSO/27.jpg",
            "calendar/papers/2/VERSO/28.jpg"
        ]
    },
    "3": {
        "monthNameArabic": "\u0645\u0627\u0631\u0633",
        "recto": [
            "calendar/papers/3/recto/1.jpg",
            "calendar/papers/3/recto/2.jpg",
            "calendar/papers/3/recto/3.jpg",
            "calendar/papers/3/recto/4.jpg",
            "calendar/papers/3/recto/5.jpg",
            "calendar/papers/3/recto/6.jpg",
            "calendar/papers/3/recto/7.jpg",
            "calendar/papers/3/recto/8.jpg",
            "calendar/papers/3/recto/9.jpg",
            "calendar/papers/3/recto/10.jpg",
            "calendar/papers/3/recto/11.jpg",
            "calendar/papers/3/recto/12.jpg",
            "calendar/papers/3/recto/13.jpg",
            "calendar/papers/3/recto/14.jpg",
            "calendar/papers/3/recto/15.jpg",
            "calendar/papers/3/recto/16.jpg",
            "calendar/papers/3/recto/17.jpg",
            "calendar/papers/3/recto/18.jpg",
            "calendar/papers/3/recto/19.jpg",
            "calendar/papers/3/recto/20.jpg",
            "calendar/papers/3/recto/21.jpg",
            "calendar/papers/3/recto/22.jpg",
            "calendar/papers/3/recto/23.jpg",
            "calendar/papers/3/recto/24.jpg",
            "calendar/papers/3/recto/25.jpg",
            "calendar/papers/3/recto/26.jpg",
            "calendar/papers/3/recto/27.jpg",
            "calendar/papers/3/recto/28.jpg",
            "calendar/papers/3/recto/29.jpg",
            "calendar/papers/3/recto/30.jpg",
            "calendar/papers/3/recto/31.jpg"
        ],
        "verso": [
            "calendar/papers/3/VERSO/1.jpg",
            "calendar/papers/3/VERSO/2.jpg",
            "calendar/papers/3/VERSO/3.jpg",
            "calendar/papers/3/VERSO/4.jpg",
            "calendar/papers/3/VERSO/5.jpg",
            "calendar/papers/3/VERSO/6.jpg",
            "calendar/papers/3/VERSO/7.jpg",
            "calendar/papers/3/VERSO/8.jpg",
            "calendar/papers/3/VERSO/9.jpg",
            "calendar/papers/3/VERSO/10.jpg",
            "calendar/papers/3/VERSO/11.jpg",
            "calendar/papers/3/VERSO/12.jpg",
            "calendar/papers/3/VERSO/13.jpg",
            "calendar/papers/3/VERSO/14.jpg",
            "calendar/papers/3/VERSO/15.jpg",
            "calendar/papers/3/VERSO/16.jpg",
            "calendar/papers/3/VERSO/17.jpg",
            "calendar/papers/3/VERSO/18.jpg",
            "calendar/papers/3/VERSO/19.jpg",
            "calendar/papers/3/VERSO/20.jpg",
            "calendar/papers/3/VERSO/21.jpg",
            "calendar/papers/3/VERSO/22.jpg",
            "calendar/papers/3/VERSO/23.jpg",
            "calendar/papers/3/VERSO/24.jpg",
            "calendar/papers/3/VERSO/25.jpg",
            "calendar/papers/3/VERSO/26.jpg",
            "calendar/papers/3/VERSO/27.jpg",
            "calendar/papers/3/VERSO/28.jpg",
            "calendar/papers/3/VERSO/29.jpg",
            "calendar/papers/3/VERSO/30.jpg",
            "calendar/papers/3/VERSO/31.jpg"
        ]
    },
    "4": {
        "monthNameArabic": "\u0623\u0628\u0631\u064a\u0644",
        "recto": [
            "calendar/papers/4/recto/1.jpg",
            "calendar/papers/4/recto/2.jpg",
            "calendar/papers/4/recto/3.jpg",
            "calendar/papers/4/recto/4.jpg",
            "calendar/papers/4/recto/5.jpg",
            "calendar/papers/4/recto/6.jpg",
            "calendar/papers/4/recto/7.jpg",
            "calendar/papers/4/recto/8.jpg",
            "calendar/papers/4/recto/9.jpg",
            "calendar/papers/4/recto/10.jpg",
            "calendar/papers/4/recto/11.jpg",
            "calendar/papers/4/recto/12.jpg",
            "calendar/papers/4/recto/13.jpg",
            "calendar/papers/4/recto/14.jpg",
            "calendar/papers/4/recto/15.jpg",
            "calendar/papers/4/recto/16.jpg",
            "calendar/papers/4/recto/17.jpg",
            "calendar/papers/4/recto/18.jpg",
            "calendar/papers/4/recto/19.jpg",
            "calendar/papers/4/recto/20.jpg",
            "calendar/papers/4/recto/21.jpg",
            "calendar/papers/4/recto/22.jpg",
            "calendar/papers/4/recto/23.jpg",
            "calendar/papers/4/recto/24.jpg",
            "calendar/papers/4/recto/25.jpg",
            "calendar/papers/4/recto/26.jpg",
            "calendar/papers/4/recto/27.jpg",
            "calendar/papers/4/recto/28.jpg",
            "calendar/papers/4/recto/29.jpg",
            "calendar/papers/4/recto/30.jpg"
        ],
        "verso": [
            "calendar/papers/4/VERSO/1.jpg",
            "calendar/papers/4/VERSO/2.jpg",
            "calendar/papers/4/VERSO/3.jpg",
            "calendar/papers/4/VERSO/4.jpg",
            "calendar/papers/4/VERSO/5.jpg",
            "calendar/papers/4/VERSO/6.jpg",
            "calendar/papers/4/VERSO/7.jpg",
            "calendar/papers/4/VERSO/8.jpg",
            "calendar/papers/4/VERSO/9.jpg",
            "calendar/papers/4/VERSO/10.jpg",
            "calendar/papers/4/VERSO/11.jpg",
            "calendar/papers/4/VERSO/12.jpg",
            "calendar/papers/4/VERSO/13.jpg",
            "calendar/papers/4/VERSO/14.jpg",
            "calendar/papers/4/VERSO/15.jpg",
            "calendar/papers/4/VERSO/16.jpg",
            "calendar/papers/4/VERSO/17.jpg",
            "calendar/papers/4/VERSO/18.jpg",
            "calendar/papers/4/VERSO/19.jpg",
            "calendar/papers/4/VERSO/20.jpg",
            "calendar/papers/4/VERSO/21.jpg",
            "calendar/papers/4/VERSO/22.jpg",
            "calendar/papers/4/VERSO/23.jpg",
            "calendar/papers/4/VERSO/24.jpg",
            "calendar/papers/4/VERSO/25.jpg",
            "calendar/papers/4/VERSO/26.jpg",
            "calendar/papers/4/VERSO/27.jpg",
            "calendar/papers/4/VERSO/28.jpg",
            "calendar/papers/4/VERSO/29.jpg",
            "calendar/papers/4/VERSO/30.jpg"
        ]
    },
    "5": {
        "monthNameArabic": "\u0645\u0627\u064a\u0648",
        "recto": [
            "calendar/papers/5/recto/1.jpg",
            "calendar/papers/5/recto/2.jpg",
            "calendar/papers/5/recto/3.jpg",
            "calendar/papers/5/recto/4.jpg",
            "calendar/papers/5/recto/5.jpg",
            "calendar/papers/5/recto/6.jpg",
            "calendar/papers/5/recto/7.jpg",
            "calendar/papers/5/recto/8.jpg",
            "calendar/papers/5/recto/9.jpg",
            "calendar/papers/5/recto/10.jpg",
            "calendar/papers/5/recto/11.jpg",
            "calendar/papers/5/recto/12.jpg",
            "calendar/papers/5/recto/13.jpg",
            "calendar/papers/5/recto/14.jpg",
            "calendar/papers/5/recto/15.jpg",
            "calendar/papers/5/recto/16.jpg",
            "calendar/papers/5/recto/17.jpg",
            "calendar/papers/5/recto/18.jpg",
            "calendar/papers/5/recto/19.jpg",
            "calendar/papers/5/recto/20.jpg",
            "calendar/papers/5/recto/21.jpg",
            "calendar/papers/5/recto/22.jpg",
            "calendar/papers/5/recto/23.jpg",
            "calendar/papers/5/recto/24.jpg",
            "calendar/papers/5/recto/25.jpg",
            "calendar/papers/5/recto/26.jpg",
            "calendar/papers/5/recto/27.jpg",
            "calendar/papers/5/recto/28.jpg",
            "calendar/papers/5/recto/29.jpg",
            "calendar/papers/5/recto/30.jpg",
            "calendar/papers/5/recto/31.jpg"
        ],
        "verso": [
            "calendar/papers/5/VERSO/1.jpg",
            "calendar/papers/5/VERSO/2.jpg",
            "calendar/papers/5/VERSO/3.jpg",
            "calendar/papers/5/VERSO/4.jpg",
            "calendar/papers/5/VERSO/5.jpg",
            "calendar/papers/5/VERSO/6.jpg",
            "calendar/papers/5/VERSO/7.jpg",
            "calendar/papers/5/VERSO/8.jpg",
            "calendar/papers/5/VERSO/9.jpg",
            "calendar/papers/5/VERSO/10.jpg",
            "calendar/papers/5/VERSO/11.jpg",
            "calendar/papers/5/VERSO/12.jpg",
            "calendar/papers/5/VERSO/13.jpg",
            "calendar/papers/5/VERSO/14.jpg",
            "calendar/papers/5/VERSO/15.jpg",
            "calendar/papers/5/VERSO/16.jpg",
            "calendar/papers/5/VERSO/17.jpg",
            "calendar/papers/5/VERSO/18.jpg",
            "calendar/papers/5/VERSO/19.jpg",
            "calendar/papers/5/VERSO/20.jpg",
            "calendar/papers/5/VERSO/21.jpg",
            "calendar/papers/5/VERSO/22.jpg",
            "calendar/papers/5/VERSO/23.jpg",
            "calendar/papers/5/VERSO/24.jpg",
            "calendar/papers/5/VERSO/25.jpg",
            "calendar/papers/5/VERSO/26.jpg",
            "calendar/papers/5/VERSO/27.jpg",
            "calendar/papers/5/VERSO/28.jpg",
            "calendar/papers/5/VERSO/29.jpg",
            "calendar/papers/5/VERSO/30.jpg",
            "calendar/papers/5/VERSO/31.jpg"
        ]
    },
    "6": {
        "monthNameArabic": "\u064a\u0648\u0646\u064a\u0648",
        "recto": [
            "calendar/papers/6/recto/1.jpg",
            "calendar/papers/6/recto/2.jpg",
            "calendar/papers/6/recto/3.jpg",
            "calendar/papers/6/recto/4.jpg",
            "calendar/papers/6/recto/5.jpg",
            "calendar/papers/6/recto/6.jpg",
            "calendar/papers/6/recto/7.jpg",
            "calendar/papers/6/recto/8.jpg",
            "calendar/papers/6/recto/9.jpg",
            "calendar/papers/6/recto/10.jpg",
            "calendar/papers/6/recto/11.jpg",
            "calendar/papers/6/recto/12.jpg",
            "calendar/papers/6/recto/13.jpg",
            "calendar/papers/6/recto/14.jpg",
            "calendar/papers/6/recto/15.jpg",
            "calendar/papers/6/recto/16.jpg",
            "calendar/papers/6/recto/17.jpg",
            "calendar/papers/6/recto/18.jpg",
            "calendar/papers/6/recto/19.jpg",
            "calendar/papers/6/recto/20.jpg",
            "calendar/papers/6/recto/21.jpg",
            "calendar/papers/6/recto/22.jpg",
            "calendar/papers/6/recto/23.jpg",
            "calendar/papers/6/recto/24.jpg",
            "calendar/papers/6/recto/25.jpg",
            "calendar/papers/6/recto/26.jpg",
            "calendar/papers/6/recto/27.jpg",
            "calendar/papers/6/recto/28.jpg",
            "calendar/papers/6/recto/29.jpg",
            "calendar/papers/6/recto/30.jpg"
        ],
        "verso": [
            "calendar/papers/6/VERSO/1.jpg",
            "calendar/papers/6/VERSO/2.jpg",
            "calendar/papers/6/VERSO/3.jpg",
            "calendar/papers/6/VERSO/4.jpg",
            "calendar/papers/6/VERSO/5.jpg",
            "calendar/papers/6/VERSO/6.jpg",
            "calendar/papers/6/VERSO/7.jpg",
            "calendar/papers/6/VERSO/8.jpg",
            "calendar/papers/6/VERSO/9.jpg",
            "calendar/papers/6/VERSO/10.jpg",
            "calendar/papers/6/VERSO/11.jpg",
            "calendar/papers/6/VERSO/12.jpg",
            "calendar/papers/6/VERSO/13.jpg",
            "calendar/papers/6/VERSO/14.jpg",
            "calendar/papers/6/VERSO/15.jpg",
            "calendar/papers/6/VERSO/16.jpg",
            "calendar/papers/6/VERSO/17.jpg",
            "calendar/papers/6/VERSO/18.jpg",
            "calendar/papers/6/VERSO/19.jpg",
            "calendar/papers/6/VERSO/20.jpg",
            "calendar/papers/6/VERSO/21.jpg",
            "calendar/papers/6/VERSO/22.jpg",
            "calendar/papers/6/VERSO/23.jpg",
            "calendar/papers/6/VERSO/24.jpg",
            "calendar/papers/6/VERSO/25.jpg",
            "calendar/papers/6/VERSO/26.jpg",
            "calendar/papers/6/VERSO/27.jpg",
            "calendar/papers/6/VERSO/28.jpg",
            "calendar/papers/6/VERSO/29.jpg",
            "calendar/papers/6/VERSO/30.jpg"
        ]
    },
    "7": {
        "monthNameArabic": "\u064a\u0648\u0644\u064a\u0648",
        "recto": [
            "calendar/papers/7/recto/1.jpg",
            "calendar/papers/7/recto/2.jpg",
            "calendar/papers/7/recto/3.jpg",
            "calendar/papers/7/recto/4.jpg",
            "calendar/papers/7/recto/5.jpg",
            "calendar/papers/7/recto/6.jpg",
            "calendar/papers/7/recto/7.jpg",
            "calendar/papers/7/recto/8.jpg",
            "calendar/papers/7/recto/9.jpg",
            "calendar/papers/7/recto/10.jpg",
            "calendar/papers/7/recto/11.jpg",
            "calendar/papers/7/recto/12.jpg",
            "calendar/papers/7/recto/13.jpg",
            "calendar/papers/7/recto/14.jpg",
            "calendar/papers/7/recto/15.jpg",
            "calendar/papers/7/recto/16.jpg",
            "calendar/papers/7/recto/17.jpg",
            "calendar/papers/7/recto/18.jpg",
            "calendar/papers/7/recto/19.jpg",
            "calendar/papers/7/recto/20.jpg",
            "calendar/papers/7/recto/21.jpg",
            "calendar/papers/7/recto/22.jpg",
            "calendar/papers/7/recto/23.jpg",
            "calendar/papers/7/recto/24.jpg",
            "calendar/papers/7/recto/25.jpg",
            "calendar/papers/7/recto/26.jpg",
            "calendar/papers/7/recto/27.jpg",
            "calendar/papers/7/recto/28.jpg",
            "calendar/papers/7/recto/29.jpg",
            "calendar/papers/7/recto/30.jpg",
            "calendar/papers/7/recto/31.jpg"
        ],
        "verso": [
            "calendar/papers/7/VERSO/1.jpg",
            "calendar/papers/7/VERSO/2.jpg",
            "calendar/papers/7/VERSO/3.jpg",
            "calendar/papers/7/VERSO/4.jpg",
            "calendar/papers/7/VERSO/5.jpg",
            "calendar/papers/7/VERSO/6.jpg",
            "calendar/papers/7/VERSO/7.jpg",
            "calendar/papers/7/VERSO/8.jpg",
            "calendar/papers/7/VERSO/9.jpg",
            "calendar/papers/7/VERSO/10.jpg",
            "calendar/papers/7/VERSO/11.jpg",
            "calendar/papers/7/VERSO/12.jpg",
            "calendar/papers/7/VERSO/13.jpg",
            "calendar/papers/7/VERSO/14.jpg",
            "calendar/papers/7/VERSO/15.jpg",
            "calendar/papers/7/VERSO/16.jpg",
            "calendar/papers/7/VERSO/17.jpg",
            "calendar/papers/7/VERSO/18.jpg",
            "calendar/papers/7/VERSO/19.jpg",
            "calendar/papers/7/VERSO/20.jpg",
            "calendar/papers/7/VERSO/21.jpg",
            "calendar/papers/7/VERSO/22.jpg",
            "calendar/papers/7/VERSO/23.jpg",
            "calendar/papers/7/VERSO/24.jpg",
            "calendar/papers/7/VERSO/25.jpg",
            "calendar/papers/7/VERSO/26.jpg",
            "calendar/papers/7/VERSO/27.jpg",
            "calendar/papers/7/VERSO/28.jpg",
            "calendar/papers/7/VERSO/29.jpg",
            "calendar/papers/7/VERSO/30.jpg",
            "calendar/papers/7/VERSO/31.jpg"
        ]
    },
    "8": {
        "monthNameArabic": "\u0623\u063a\u0633\u0637\u0633",
        "recto": [
            "calendar/papers/8/recto/1.jpg",
            "calendar/papers/8/recto/2.jpg",
            "calendar/papers/8/recto/3.jpg",
            "calendar/papers/8/recto/4.jpg",
            "calendar/papers/8/recto/5.jpg",
            "calendar/papers/8/recto/6.jpg",
            "calendar/papers/8/recto/7.jpg",
            "calendar/papers/8/recto/8.jpg",
            "calendar/papers/8/recto/9.jpg",
            "calendar/papers/8/recto/10.jpg",
            "calendar/papers/8/recto/11.jpg",
            "calendar/papers/8/recto/12.jpg",
            "calendar/papers/8/recto/13.jpg",
            "calendar/papers/8/recto/14.jpg",
            "calendar/papers/8/recto/15.jpg",
            "calendar/papers/8/recto/16.jpg",
            "calendar/papers/8/recto/17.jpg",
            "calendar/papers/8/recto/18.jpg",
            "calendar/papers/8/recto/19.jpg",
            "calendar/papers/8/recto/20.jpg",
            "calendar/papers/8/recto/21.jpg",
            "calendar/papers/8/recto/22.jpg",
            "calendar/papers/8/recto/23.jpg",
            "calendar/papers/8/recto/24.jpg",
            "calendar/papers/8/recto/25.jpg",
            "calendar/papers/8/recto/26.jpg",
            "calendar/papers/8/recto/27.jpg",
            "calendar/papers/8/recto/28.jpg",
            "calendar/papers/8/recto/29.jpg",
            "calendar/papers/8/recto/30.jpg",
            "calendar/papers/8/recto/31.jpg"
        ],
        "verso": [
            "calendar/papers/8/VERSO/1.jpg",
            "calendar/papers/8/VERSO/2.jpg",
            "calendar/papers/8/VERSO/3.jpg",
            "calendar/papers/8/VERSO/4.jpg",
            "calendar/papers/8/VERSO/5.jpg",
            "calendar/papers/8/VERSO/6.jpg",
            "calendar/papers/8/VERSO/7.jpg",
            "calendar/papers/8/VERSO/8.jpg",
            "calendar/papers/8/VERSO/9.jpg",
            "calendar/papers/8/VERSO/10.jpg",
            "calendar/papers/8/VERSO/11.jpg",
            "calendar/papers/8/VERSO/12.jpg",
            "calendar/papers/8/VERSO/13.jpg",
            "calendar/papers/8/VERSO/14.jpg",
            "calendar/papers/8/VERSO/15.jpg",
            "calendar/papers/8/VERSO/16.jpg",
            "calendar/papers/8/VERSO/17.jpg",
            "calendar/papers/8/VERSO/18.jpg",
            "calendar/papers/8/VERSO/19.jpg",
            "calendar/papers/8/VERSO/20.jpg",
            "calendar/papers/8/VERSO/21.jpg",
            "calendar/papers/8/VERSO/22.jpg",
            "calendar/papers/8/VERSO/23.jpg",
            "calendar/papers/8/VERSO/24.jpg",
            "calendar/papers/8/VERSO/25.jpg",
            "calendar/papers/8/VERSO/26.jpg",
            "calendar/papers/8/VERSO/27.jpg",
            "calendar/papers/8/VERSO/28.jpg",
            "calendar/papers/8/VERSO/29.jpg",
            "calendar/papers/8/VERSO/30.jpg",
            "calendar/papers/8/VERSO/31.jpg"
        ]
    },
    "9": {
        "monthNameArabic": "\u0633\u0628\u062a\u0645\u0628\u0631",
        "recto": [
            "calendar/papers/9/recto/1.jpg",
            "calendar/papers/9/recto/2.jpg",
            "calendar/papers/9/recto/3.jpg",
            "calendar/papers/9/recto/4.jpg",
            "calendar/papers/9/recto/5.jpg",
            "calendar/papers/9/recto/6.jpg",
            "calendar/papers/9/recto/7.jpg",
            "calendar/papers/9/recto/8.jpg",
            "calendar/papers/9/recto/9.jpg",
            "calendar/papers/9/recto/10.jpg",
            "calendar/papers/9/recto/11.jpg",
            "calendar/papers/9/recto/12.jpg",
            "calendar/papers/9/recto/13.jpg",
            "calendar/papers/9/recto/14.jpg",
            "calendar/papers/9/recto/15.jpg",
            "calendar/papers/9/recto/16.jpg",
            "calendar/papers/9/recto/17.jpg",
            "calendar/papers/9/recto/18.jpg",
            "calendar/papers/9/recto/19.jpg",
            "calendar/papers/9/recto/20.jpg",
            "calendar/papers/9/recto/21.jpg",
            "calendar/papers/9/recto/22.jpg",
            "calendar/papers/9/recto/23.jpg",
            "calendar/papers/9/recto/24.jpg",
            "calendar/papers/9/recto/25.jpg",
            "calendar/papers/9/recto/26.jpg",
            "calendar/papers/9/recto/27.jpg",
            "calendar/papers/9/recto/28.jpg",
            "calendar/papers/9/recto/29.jpg",
            "calendar/papers/9/recto/30.jpg"
        ],
        "verso": [
            "calendar/papers/9/VERSO/1.jpg",
            "calendar/papers/9/VERSO/2.jpg",
            "calendar/papers/9/VERSO/3.jpg",
            "calendar/papers/9/VERSO/4.jpg",
            "calendar/papers/9/VERSO/5.jpg",
            "calendar/papers/9/VERSO/6.jpg",
            "calendar/papers/9/VERSO/7.jpg",
            "calendar/papers/9/VERSO/8.jpg",
            "calendar/papers/9/VERSO/9.jpg",
            "calendar/papers/9/VERSO/10.jpg",
            "calendar/papers/9/VERSO/11.jpg",
            "calendar/papers/9/VERSO/12.jpg",
            "calendar/papers/9/VERSO/13.jpg",
            "calendar/papers/9/VERSO/14.jpg",
            "calendar/papers/9/VERSO/15.jpg",
            "calendar/papers/9/VERSO/16.jpg",
            "calendar/papers/9/VERSO/17.jpg",
            "calendar/papers/9/VERSO/18.jpg",
            "calendar/papers/9/VERSO/19.jpg",
            "calendar/papers/9/VERSO/20.jpg",
            "calendar/papers/9/VERSO/21.jpg",
            "calendar/papers/9/VERSO/22.jpg",
            "calendar/papers/9/VERSO/23.jpg",
            "calendar/papers/9/VERSO/24.jpg",
            "calendar/papers/9/VERSO/25.jpg",
            "calendar/papers/9/VERSO/26.jpg",
            "calendar/papers/9/VERSO/27.jpg",
            "calendar/papers/9/VERSO/28.jpg",
            "calendar/papers/9/VERSO/29.jpg",
            "calendar/papers/9/VERSO/30.jpg"
        ]
    },
    "10": {
        "monthNameArabic": "\u0623\u0643\u062a\u0648\u0628\u0631",
        "recto": [
            "calendar/papers/10/recto/1.jpg",
            "calendar/papers/10/recto/2.jpg",
            "calendar/papers/10/recto/3.jpg",
            "calendar/papers/10/recto/4.jpg",
            "calendar/papers/10/recto/5.jpg",
            "calendar/papers/10/recto/6.jpg",
            "calendar/papers/10/recto/7.jpg",
            "calendar/papers/10/recto/8.jpg",
            "calendar/papers/10/recto/9.jpg",
            "calendar/papers/10/recto/10.jpg",
            "calendar/papers/10/recto/11.jpg",
            "calendar/papers/10/recto/12.jpg",
            "calendar/papers/10/recto/13.jpg",
            "calendar/papers/10/recto/14.jpg",
            "calendar/papers/10/recto/15.jpg",
            "calendar/papers/10/recto/16.jpg",
            "calendar/papers/10/recto/17.jpg",
            "calendar/papers/10/recto/18.jpg",
            "calendar/papers/10/recto/19.jpg",
            "calendar/papers/10/recto/20.jpg",
            "calendar/papers/10/recto/21.jpg",
            "calendar/papers/10/recto/22.jpg",
            "calendar/papers/10/recto/23.jpg",
            "calendar/papers/10/recto/24.jpg",
            "calendar/papers/10/recto/25.jpg",
            "calendar/papers/10/recto/26.jpg",
            "calendar/papers/10/recto/27.jpg",
            "calendar/papers/10/recto/28.jpg",
            "calendar/papers/10/recto/29.jpg",
            "calendar/papers/10/recto/30.jpg",
            "calendar/papers/10/recto/31.jpg"
        ],
        "verso": [
            "calendar/papers/10/VERSO/1.jpg",
            "calendar/papers/10/VERSO/2.jpg",
            "calendar/papers/10/VERSO/3.jpg",
            "calendar/papers/10/VERSO/4.jpg",
            "calendar/papers/10/VERSO/5.jpg",
            "calendar/papers/10/VERSO/6.jpg",
            "calendar/papers/10/VERSO/7.jpg",
            "calendar/papers/10/VERSO/8.jpg",
            "calendar/papers/10/VERSO/9.jpg",
            "calendar/papers/10/VERSO/10.jpg",
            "calendar/papers/10/VERSO/11.jpg",
            "calendar/papers/10/VERSO/12.jpg",
            "calendar/papers/10/VERSO/13.jpg",
            "calendar/papers/10/VERSO/14.jpg",
            "calendar/papers/10/VERSO/15.jpg",
            "calendar/papers/10/VERSO/16.jpg",
            "calendar/papers/10/VERSO/17.jpg",
            "calendar/papers/10/VERSO/18.jpg",
            "calendar/papers/10/VERSO/19.jpg",
            "calendar/papers/10/VERSO/20.jpg",
            "calendar/papers/10/VERSO/21.jpg",
            "calendar/papers/10/VERSO/22.jpg",
            "calendar/papers/10/VERSO/23.jpg",
            "calendar/papers/10/VERSO/24.jpg",
            "calendar/papers/10/VERSO/25.jpg",
            "calendar/papers/10/VERSO/26.jpg",
            "calendar/papers/10/VERSO/27.jpg",
            "calendar/papers/10/VERSO/28.jpg",
            "calendar/papers/10/VERSO/29.jpg",
            "calendar/papers/10/VERSO/30.jpg",
            "calendar/papers/10/VERSO/31.jpg"
        ]
    },
    "11": {
        "monthNameArabic": "\u0646\u0648\u0641\u0645\u0628\u0631",
        "recto": [
            "calendar/papers/11/recto/1.jpg",
            "calendar/papers/11/recto/2.jpg",
            "calendar/papers/11/recto/3.jpg",
            "calendar/papers/11/recto/4.jpg",
            "calendar/papers/11/recto/5.jpg",
            "calendar/papers/11/recto/6.jpg",
            "calendar/papers/11/recto/7.jpg",
            "calendar/papers/11/recto/8.jpg",
            "calendar/papers/11/recto/9.jpg",
            "calendar/papers/11/recto/10.jpg",
            "calendar/papers/11/recto/11.jpg",
            "calendar/papers/11/recto/12.jpg",
            "calendar/papers/11/recto/13.jpg",
            "calendar/papers/11/recto/14.jpg",
            "calendar/papers/11/recto/15.jpg",
            "calendar/papers/11/recto/16.jpg",
            "calendar/papers/11/recto/17.jpg",
            "calendar/papers/11/recto/18.jpg",
            "calendar/papers/11/recto/19.jpg",
            "calendar/papers/11/recto/20.jpg",
            "calendar/papers/11/recto/21.jpg",
            "calendar/papers/11/recto/22.jpg",
            "calendar/papers/11/recto/23.jpg",
            "calendar/papers/11/recto/24.jpg",
            "calendar/papers/11/recto/25.jpg",
            "calendar/papers/11/recto/26.jpg",
            "calendar/papers/11/recto/27.jpg",
            "calendar/papers/11/recto/28.jpg",
            "calendar/papers/11/recto/29.jpg",
            "calendar/papers/11/recto/30.jpg"
        ],
        "verso": [
            "calendar/papers/11/VERSO/1.jpg",
            "calendar/papers/11/VERSO/2.jpg",
            "calendar/papers/11/VERSO/3.jpg",
            "calendar/papers/11/VERSO/4.jpg",
            "calendar/papers/11/VERSO/5.jpg",
            "calendar/papers/11/VERSO/6.jpg",
            "calendar/papers/11/VERSO/7.jpg",
            "calendar/papers/11/VERSO/8.jpg",
            "calendar/papers/11/VERSO/9.jpg",
            "calendar/papers/11/VERSO/10.jpg",
            "calendar/papers/11/VERSO/11.jpg",
            "calendar/papers/11/VERSO/12.jpg",
            "calendar/papers/11/VERSO/13.jpg",
            "calendar/papers/11/VERSO/14.jpg",
            "calendar/papers/11/VERSO/15.jpg",
            "calendar/papers/11/VERSO/16.jpg",
            "calendar/papers/11/VERSO/17.jpg",
            "calendar/papers/11/VERSO/18.jpg",
            "calendar/papers/11/VERSO/19.jpg",
            "calendar/papers/11/VERSO/20.jpg",
            "calendar/papers/11/VERSO/21.jpg",
            "calendar/papers/11/VERSO/22.jpg",
            "calendar/papers/11/VERSO/23.jpg",
            "calendar/papers/11/VERSO/24.jpg",
            "calendar/papers/11/VERSO/25.jpg",
            "calendar/papers/11/VERSO/26.jpg",
            "calendar/papers/11/VERSO/27.jpg",
            "calendar/papers/11/VERSO/28.jpg",
            "calendar/papers/11/VERSO/29.jpg",
            "calendar/papers/11/VERSO/30.jpg"
        ]
    },
    "12": {
        "monthNameArabic": "\u062f\u064a\u0633\u0645\u0628\u0631",
        "recto": [
            "calendar/papers/12/recto/1.jpg",
            "calendar/papers/12/recto/2.jpg",
            "calendar/papers/12/recto/3.jpg",
            "calendar/papers/12/recto/4.jpg",
            "calendar/papers/12/recto/5.jpg",
            "calendar/papers/12/recto/6.jpg",
            "calendar/papers/12/recto/7.jpg",
            "calendar/papers/12/recto/8.jpg",
            "calendar/papers/12/recto/9.jpg",
            "calendar/papers/12/recto/10.jpg",
            "calendar/papers/12/recto/11.jpg",
            "calendar/papers/12/recto/12.jpg",
            "calendar/papers/12/recto/13.jpg",
            "calendar/papers/12/recto/14.jpg",
            "calendar/papers/12/recto/15.jpg",
            "calendar/papers/12/recto/16.jpg",
            "calendar/papers/12/recto/17.jpg",
            "calendar/papers/12/recto/18.jpg",
            "calendar/papers/12/recto/19.jpg",
            "calendar/papers/12/recto/20.jpg",
            "calendar/papers/12/recto/21.jpg",
            "calendar/papers/12/recto/22.jpg",
            "calendar/papers/12/recto/23.jpg",
            "calendar/papers/12/recto/24.jpg",
            "calendar/papers/12/recto/25.jpg",
            "calendar/papers/12/recto/26.jpg",
            "calendar/papers/12/recto/27.jpg",
            "calendar/papers/12/recto/28.jpg",
            "calendar/papers/12/recto/29.jpg",
            "calendar/papers/12/recto/30.jpg",
            "calendar/papers/12/recto/31.jpg"
        ],
        "verso": [
            "calendar/papers/12/VERSO/1.jpg",
            "calendar/papers/12/VERSO/2.jpg",
            "calendar/papers/12/VERSO/3.jpg",
            "calendar/papers/12/VERSO/4.jpg",
            "calendar/papers/12/VERSO/5.jpg",
            "calendar/papers/12/VERSO/6.jpg",
            "calendar/papers/12/VERSO/7.jpg",
            "calendar/papers/12/VERSO/8.jpg",
            "calendar/papers/12/VERSO/9.jpg",
            "calendar/papers/12/VERSO/10.jpg",
            "calendar/papers/12/VERSO/11.jpg",
            "calendar/papers/12/VERSO/12.jpg",
            "calendar/papers/12/VERSO/13.jpg",
            "calendar/papers/12/VERSO/14.jpg",
            "calendar/papers/12/VERSO/15.jpg",
            "calendar/papers/12/VERSO/16.jpg",
            "calendar/papers/12/VERSO/17.jpg",
            "calendar/papers/12/VERSO/18.jpg",
            "calendar/papers/12/VERSO/19.jpg",
            "calendar/papers/12/VERSO/20.jpg",
            "calendar/papers/12/VERSO/21.jpg",
            "calendar/papers/12/VERSO/22.jpg",
            "calendar/papers/12/VERSO/23.jpg",
            "calendar/papers/12/VERSO/24.jpg",
            "calendar/papers/12/VERSO/25.jpg",
            "calendar/papers/12/VERSO/26.jpg",
            "calendar/papers/12/VERSO/27.jpg",
            "calendar/papers/12/VERSO/28.jpg",
            "calendar/papers/12/VERSO/29.jpg",
            "calendar/papers/12/VERSO/30.jpg",
            "calendar/papers/12/VERSO/31.jpg"
        ]
    }
};