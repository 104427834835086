export const wildAnimalsTheme = {
    "id": 158,
    "name": "الحيوانات البريّة",
    "slug": "wild-animals",
    "description": "يضم مجموعة من الحيوانات التي تعيش في البرية.",
    "textures": {
        "right": "/memoryGame/themes/wild-animals/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/wild-animals/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/wild-animals/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/wild-animals/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/wild-animals/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/wild-animals/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "الأسد",
            "slug": "lion",
            "picture": "memoryGame/themes/wild-animals/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/wild-animals/sounds/lion_ar.mp3",
                "fr": "memoryGame/themes/wild-animals/sounds/lion_fr.mp3",
                "en": "memoryGame/themes/wild-animals/sounds/lion_en.mp3"
            },
            "funFact": "الأسد هو ملك الغابة.",
            "info": {
                "description": "الأسد هو حيوان مفترس يُعتبر رمز القوة.",
                "bodyInfo": [
                    "يعيش الأسد في مجموعات تسمى الزمر.",
                    "يتغذى الأسد على الحيوانات الأخرى.",
                    "يمكن أن يعيش الأسد حتى 15 سنة."
                ],
                "funFacts": [
                    "الأسد هو ثاني أكبر القطط بعد النمر.",
                    "يمكن للأسد أن يقفز لمسافة تصل إلى 10 أمتار.",
                    "تمتلك الأسود شعورًا قويًا بالعائلة."
                ]
            }
        },
        {
            "id": 2,
            "name": "وحيد القرن",
            "slug": "rhino",
            "picture": "memoryGame/themes/wild-animals/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/wild-animals/sounds/rhino_ar.mp3",
                "fr": "memoryGame/themes/wild-animals/sounds/rhino_fr.mp3",
                "en": "memoryGame/themes/wild-animals/sounds/rhino_en.mp3"
            },
            "funFact": "وحيد القرن يُعتبر من أكبر الحيوانات.",
            "info": {
                "description": "وحيد القرن هو حيوان ثديي يتميز بقرنه الكبير.",
                "bodyInfo": [
                    "يعيش وحيد القرن في السافانا.",
                    "يتغذى وحيد القرن على الأعشاب.",
                    "يمكن أن يعيش وحيد القرن حتى 50 سنة."
                ],
                "funFacts": [
                    "تعتبر وحيد القرن من الحيوانات المهددة بالانقراض.",
                    "يمتلك وحيد القرن جلدًا سميكًا.",
                    "تستطيع وحيد القرن الركض بسرعة تصل إلى 40 كم/ساعة."
                ]
            }
        },
        {
            "id": 3,
            "name": "النمر",
            "slug": "tiger",
            "picture": "memoryGame/themes/wild-animals/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/wild-animals/sounds/tiger_ar.mp3",
                "fr": "memoryGame/themes/wild-animals/sounds/tiger_fr.mp3",
                "en": "memoryGame/themes/wild-animals/sounds/tiger_en.mp3"
            },
            "funFact": "النمر هو أكبر أنواع القطط.",
            "info": {
                "description": "النمر هو حيوان مفترس معروف بنمطه المميز.",
                "bodyInfo": [
                    "يعيش النمر في الغابات.",
                    "يتغذى النمر على الحيوانات الأخرى.",
                    "يمكن أن يعيش النمر حتى 15 سنة."
                ],
                "funFacts": [
                    "يستطيع النمر السباحة جيدًا.",
                    "تعتبر نمور البنغال من أكبر الأنواع.",
                    "تستخدم النمور الخطوط لتمويه نفسها."
                ]
            }
        },
        {
            "id": 4,
            "name": "الفيل",
            "slug": "elephant",
            "picture": "memoryGame/themes/wild-animals/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/wild-animals/sounds/elephant_ar.mp3",
                "fr": "memoryGame/themes/wild-animals/sounds/elephant_fr.mp3",
                "en": "memoryGame/themes/wild-animals/sounds/elephant_en.mp3"
            },
            "funFact": "الفيل يُعتبر أكبر حيوان بري.",
            "info": {
                "description": "الفيل هو حيوان ثديي يمتاز بجسمه الضخم وأذنه الكبيرة.",
                "bodyInfo": [
                    "يعيش الفيل في الغابات والسافانا.",
                    "يتغذى الفيل على النباتات.",
                    "يمكن أن يعيش الفيل حتى 60 سنة."
                ],
                "funFacts": [
                    "تستطيع الفيلة أن تتواصل مع بعضها باستخدام الأصوات.",
                    "تمتلك الفيلة ذاكرة قوية.",
                    "تعتبر الفيلة من الحيوانات الاجتماعية."
                ]
            }
        },
        {
            "id": 5,
            "name": "الزرافة",
            "slug": "giraffe",
            "picture": "memoryGame/themes/wild-animals/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/wild-animals/sounds/giraffe_ar.mp3",
                "fr": "memoryGame/themes/wild-animals/sounds/giraffe_fr.mp3",
                "en": "memoryGame/themes/wild-animals/sounds/giraffe_en.mp3"
            },
            "funFact": "الزرافة هي أطول حيوان في العالم.",
            "info": {
                "description": "الزرافة هي حيوان ثديي يتميز برقبتها الطويلة.",
                "bodyInfo": [
                    "تعيش الزرافة في السافانا.",
                    "تتغذى الزرافة على الأوراق والفواكه.",
                    "يمكن أن تعيش الزرافة حتى 25 سنة."
                ],
                "funFacts": [
                    "تستطيع الزرافة أن تصل إلى ارتفاع 6 أمتار.",
                    "تمتلك الزرافة لسانًا طويلاً يصل طوله إلى 50 سم.",
                    "تعتبر الزرافة من الحيوانات الاجتماعية."
                ]
            }
        },
        {
            "id": 6,
            "name": "الباندا",
            "slug": "panda",
            "picture": "memoryGame/themes/wild-animals/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/wild-animals/sounds/panda_ar.mp3",
                "fr": "memoryGame/themes/wild-animals/sounds/panda_fr.mp3",
                "en": "memoryGame/themes/wild-animals/sounds/panda_en.mp3"
            },
            "funFact": "الباندا تُعتبر رمز الحماية في الصين.",
            "info": {
                "description": "الباندا هو حيوان ثديي معروف بجسمه السمين وفروه الأسود والأبيض.",
                "bodyInfo": [
                    "تعيش الباندا في الغابات الجبلية.",
                    "تتغذى الباندا بشكل رئيسي على الخيزران.",
                    "يمكن أن تعيش الباندا حتى 20 سنة."
                ],
                "funFacts": [
                    "تعتبر الباندا من الحيوانات العازبة.",
                    "تستطيع الباندا تسلق الأشجار.",
                    "تُعتبر الباندا من الحيوانات المهددة بالانقراض."
                ]
            }
        },
        {
            "id": 7,
            "name": "الكنغر",
            "slug": "kangaroo",
            "picture": "memoryGame/themes/wild-animals/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/wild-animals/sounds/kangaroo_ar.mp3",
                "fr": "memoryGame/themes/wild-animals/sounds/kangaroo_fr.mp3",
                "en": "memoryGame/themes/wild-animals/sounds/kangaroo_en.mp3"
            },
            "funFact": "الكنغر هو رمز أستراليا.",
            "info": {
                "description": "الكنغر هو حيوان ثديي معروف بقفزاته الطويلة.",
                "bodyInfo": [
                    "يعيش الكنغر في أستراليا.",
                    "يتغذى الكنغر على الأعشاب.",
                    "يمكن أن يعيش الكنغر حتى 20 سنة."
                ],
                "funFacts": [
                    "يستطيع الكنغر القفز لمسافة تصل إلى 9 أمتار.",
                    "يعتبر الكنغر من الحيوانات الاجتماعية.",
                    "يمتلك الكنغر جيبًا لحمل صغاره."
                ]
            }
        },
        {
            "id": 8,
            "name": "القرد",
            "slug": "monkey",
            "picture": "memoryGame/themes/wild-animals/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/wild-animals/sounds/monkey_ar.mp3",
                "fr": "memoryGame/themes/wild-animals/sounds/monkey_fr.mp3",
                "en": "memoryGame/themes/wild-animals/sounds/monkey_en.mp3"
            },
            "funFact": "القرد يُعتبر من أذكى الحيوانات.",
            "info": {
                "description": "القرد هو حيوان ثديي يتميز بذكائه وسلوكه الاجتماعي.",
                "bodyInfo": [
                    "يعيش القرد في الغابات.",
                    "يتغذى القرد على الفواكه والأوراق.",
                    "يمكن أن يعيش القرد حتى 30 سنة."
                ],
                "funFacts": [
                    "تستطيع بعض أنواع القردة استخدام الأدوات.",
                    "يعتبر القرد من الحيوانات الاجتماعية.",
                    "تستخدم القرود الأصوات للتواصل مع بعضها."
                ]
            }
        },
        {
            "id": 9,
            "name": "الغزال",
            "slug": "gazelle",
            "picture": "memoryGame/themes/wild-animals/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/wild-animals/sounds/gazelle_ar.mp3",
                "fr": "memoryGame/themes/wild-animals/sounds/gazelle_fr.mp3",
                "en": "memoryGame/themes/wild-animals/sounds/gazelle_en.mp3"
            },
            "funFact": "الغزال يعتبر من أسرع الحيوانات.",
            "info": {
                "description": "الغزال هو حيوان ثديي معروف برشاقته.",
                "bodyInfo": [
                    "يعيش الغزال في السهول والأراضي المفتوحة.",
                    "يتغذى الغزال على الأعشاب والأوراق.",
                    "يمكن أن يعيش الغزال حتى 10 سنوات."
                ],
                "funFacts": [
                    "يمكن للغزال الجري بسرعة تصل إلى 80 كيلومتر في الساعة.",
                    "تتميز الغزلان برشاقتها وقدرتها على القفز.",
                    "الغزلان تعيش في مجموعات صغيرة."
                ]
            }
        },
        {
            "id": 10,
            "name": "الحمار الوحشي",
            "slug": "zebra",
            "picture": "memoryGame/themes/wild-animals/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/wild-animals/sounds/zebra_ar.mp3",
                "fr": "memoryGame/themes/wild-animals/sounds/zebra_fr.mp3",
                "en": "memoryGame/themes/wild-animals/sounds/zebra_en.mp3"
            },
            "funFact": "الحمار الوحشي معروف بخطوطه السوداء والبيضاء.",
            "info": {
                "description": "الحمار الوحشي هو حيوان ثديي ينتمي إلى عائلة الخيليات.",
                "bodyInfo": [
                    "يعيش الحمار الوحشي في السهول الإفريقية.",
                    "يتغذى الحمار الوحشي على الأعشاب.",
                    "يمكن أن يعيش الحمار الوحشي حتى 25 سنة."
                ],
                "funFacts": [
                    "خطوط الحمار الوحشي تساعده على التمويه.",
                    "يعتبر الحمار الوحشي من الحيوانات الاجتماعية.",
                    "تعيش الحمير الوحشية في مجموعات تُعرف بالقَطعان."
                ]
            }
        }

    ]
};
