export const animalsTheme = {
    "id": 157,
    "name": "الحيوانات",
    "slug": "animals",
    "description": "يتضمن مجموعة متنوعة من الحيوانات.",
    "textures": {
        "right": "/memoryGame/themes/animals/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/animals/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/animals/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/animals/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/animals/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/animals/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "الدجاجة",
            "slug": "chicken",
            "picture": "memoryGame/themes/animals/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/animals/sounds/chicken_ar.mp3",
                "fr": "memoryGame/themes/animals/sounds/chicken_fr.mp3",
                "en": "memoryGame/themes/animals/sounds/chicken_en.mp3"
            },
            "funFact": "تُعتبر الدجاجة مصدرًا للبيض واللحوم.",
            "info": {
                "description": "الدجاجة هي طائر محلي يُربى في المزارع.",
                "bodyInfo": [
                    "تتغذى الدجاجة على الحبوب.",
                    "تعيش الدجاجة في مجموعات تسمى القطيع.",
                    "يمكن أن تعيش الدجاجة حتى 8 سنوات."
                ],
                "funFacts": [
                    "تستطيع الدجاجة التواصل مع بعضها باستخدام أصوات مختلفة.",
                    "تُعتبر الدجاجة من أذكى الطيور.",
                    "تبيض الدجاجة بيضًا يتراوح لونه من الأبيض إلى البني."
                ]
            }
        },
        {
            "id": 2,
            "name": "البطة",
            "slug": "duck",
            "picture": "memoryGame/themes/animals/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/animals/sounds/duck_ar.mp3",
                "fr": "memoryGame/themes/animals/sounds/duck_fr.mp3",
                "en": "memoryGame/themes/animals/sounds/duck_en.mp3"
            },
            "funFact": "البطة تستطيع السباحة بمهارة.",
            "info": {
                "description": "البطة هي طائر مائي يُعرف بجسمه المستدير.",
                "bodyInfo": [
                    "تتغذى البطة على النباتات والحشرات.",
                    "تعيش البطة في المسطحات المائية.",
                    "يمكن أن تعيش البطة لعدة سنوات."
                ],
                "funFacts": [
                    "تمتاز البطة بقدرتها على الطيران.",
                    "تستطيع البطة أن ترى في جميع الاتجاهات.",
                    "بعض أنواع البط تعيش في مجموعات كبيرة."
                ]
            }
        },
        {
            "id": 3,
            "name": "الدبّ",
            "slug": "bear",
            "picture": "memoryGame/themes/animals/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/animals/sounds/bear_ar.mp3",
                "fr": "memoryGame/themes/animals/sounds/bear_fr.mp3",
                "en": "memoryGame/themes/animals/sounds/bear_en.mp3"
            },
            "funFact": "الدببة تعتبر من أكبر الحيوانات البرية.",
            "info": {
                "description": "الدب هو حيوان ثديي يمتاز بحجمه الكبير.",
                "bodyInfo": [
                    "يعيش الدب في الغابات والجبال.",
                    "يتغذى الدب على النباتات والأسماك.",
                    "يمكن أن يعيش الدب حتى 25 عامًا."
                ],
                "funFacts": [
                    "تعتبر الدببة من الحيوانات القوية.",
                    "يمكن للدب أن يركض بسرعة تصل إلى 40 كم/ساعة.",
                    "تعيش الدببة في مجموعة أو بمفردها."
                ]
            }
        },
        {
            "id": 4,
            "name": "الكنغر",
            "slug": "kangaroo",
            "picture": "memoryGame/themes/animals/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/animals/sounds/kangaroo_ar.mp3",
                "fr": "memoryGame/themes/animals/sounds/kangaroo_fr.mp3",
                "en": "memoryGame/themes/animals/sounds/kangaroo_en.mp3"
            },
            "funFact": "الكنغر يُعتبر رمزًا لأستراليا.",
            "info": {
                "description": "الكنغر هو حيوان ثديي يتميز بقفزاته العالية.",
                "bodyInfo": [
                    "يعيش الكنغر في المناطق العشبية.",
                    "يتغذى الكنغر على الأعشاب.",
                    "يمكن أن يقفز الكنغر لمسافات طويلة."
                ],
                "funFacts": [
                    "تعيش الكنغر في مجموعات تُسمى القطيع.",
                    "يمكن للكنغر أن يقفز بارتفاع يصل إلى ثلاثة أمتار.",
                    "تعتبر الكنغر من الحيوانات السلمية."
                ]
            }
        },
        {
            "id": 5,
            "name": "الفأر",
            "slug": "mouse",
            "picture": "memoryGame/themes/animals/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/animals/sounds/mouse_ar.mp3",
                "fr": "memoryGame/themes/animals/sounds/mouse_fr.mp3",
                "en": "memoryGame/themes/animals/sounds/mouse_en.mp3"
            },
            "funFact": "الفأر يُعتبر من القوارض الشائعة.",
            "info": {
                "description": "الفأر هو حيوان صغير يُستخدم في الأبحاث.",
                "bodyInfo": [
                    "يعيش الفأر في البيئات المتنوعة.",
                    "يتغذى الفأر على الحبوب والفواكه.",
                    "يمكن أن يتكاثر الفأر بسرعة."
                ],
                "funFacts": [
                    "تستطيع الفئران العيش في معظم البيئات.",
                    "تمتاز الفئران بذكائها وقدرتها على التعلم.",
                    "تستخدم الفئران في التجارب العلمية."
                ]
            }
        },
        {
            "id": 6,
            "name": "الخروف",
            "slug": "sheep",
            "picture": "memoryGame/themes/animals/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/animals/sounds/sheep_ar.mp3",
                "fr": "memoryGame/themes/animals/sounds/sheep_fr.mp3",
                "en": "memoryGame/themes/animals/sounds/sheep_en.mp3"
            },
            "funFact": "الخروف يُعتبر مصدرًا للصوف.",
            "info": {
                "description": "الخروف هو حيوان يُربى للحصول على اللحوم والصوف.",
                "bodyInfo": [
                    "يعيش الخروف في المزارع.",
                    "يتغذى الخروف على الأعشاب.",
                    "يمكن أن يعيش الخروف لعدة سنوات."
                ],
                "funFacts": [
                    "تستطيع الأغنام أن تتذكر الوجوه.",
                    "تعتبر الأغنام من الحيوانات الاجتماعية.",
                    "تستخدم الأغنام في إنتاج الألبان."
                ]
            }
        },
        {
            "id": 7,
            "name": "الغزال",
            "slug": "deer",
            "picture": "memoryGame/themes/animals/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/animals/sounds/deer_ar.mp3",
                "fr": "memoryGame/themes/animals/sounds/deer_fr.mp3",
                "en": "memoryGame/themes/animals/sounds/deer_en.mp3"
            },
            "funFact": "الغزال يتميز برشاقته وسرعته.",
            "info": {
                "description": "الغزال هو حيوان ثديي يُعتبر رمزًا للجمال.",
                "bodyInfo": [
                    "يعيش الغزال في الغابات والسافانا.",
                    "يتغذى الغزال على الأعشاب.",
                    "يمكن أن يعيش الغزال حتى 10 سنوات."
                ],
                "funFacts": [
                    "تستطيع الغزلان القفز لمسافات طويلة.",
                    "يعتبر الغزال من الحيوانات السريعة.",
                    "تُستخدم الغزلان في الصيد."
                ]
            }
        },
        {
            "id": 8,
            "name": "السنجاب",
            "slug": "squirrel",
            "picture": "memoryGame/themes/animals/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/animals/sounds/squirrel_ar.mp3",
                "fr": "memoryGame/themes/animals/sounds/squirrel_fr.mp3",
                "en": "memoryGame/themes/animals/sounds/squirrel_en.mp3"
            },
            "funFact": "السنجاب يحب الجوز والمكسرات.",
            "info": {
                "description": "السنجاب هو حيوان صغير يتميز بذيله الكثيف.",
                "bodyInfo": [
                    "يعيش السنجاب في الأشجار.",
                    "يتغذى السنجاب على المكسرات والفواكه.",
                    "يمكن أن يعيش السنجاب لعدة سنوات."
                ],
                "funFacts": [
                    "تعتبر السناجب من الحيوانات الذكية.",
                    "تستخدم السناجب أقدامها الأمامية لفتح المكسرات."
                ]
            }
        },
        {
            "id": 9,
            "name": "الحلزون",
            "slug": "snail",
            "picture": "memoryGame/themes/animals/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/animals/sounds/snail_ar.mp3",
                "fr": "memoryGame/themes/animals/sounds/snail_fr.mp3",
                "en": "memoryGame/themes/animals/sounds/snail_en.mp3"
            },
            "funFact": "الحلزون يستطيع أن يحمل قوقعته أينما ذهب.",
            "info": {
                "description": "الحلزون هو حيوان بطيء يتحرك باستخدام عضلات قدمه الوحيدة.",
                "bodyInfo": [
                    "يعيش الحلزون في الأماكن الرطبة.",
                    "يتغذى الحلزون على النباتات والطحالب.",
                    "يمكن أن يعيش الحلزون لعدة سنوات ويترك أثراً من المخاط أثناء حركته."
                ],
                "funFacts": [
                    "تستطيع الحلزونات الانسحاب بالكامل داخل قوقعاتها لحماية أنفسها.",
                    "تعتبر الحلزونات من أبطأ الحيوانات.",
                    "تعيش بعض الحلزونات في المياه العذبة أو المالحة."
                ]
            }
        }
        ,
        {
            "id": 10,
            "name": "الحصان",
            "slug": "horse",
            "picture": "memoryGame/themes/animals/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/animals/sounds/horse_ar.mp3",
                "fr": "memoryGame/themes/animals/sounds/horse_fr.mp3",
                "en": "memoryGame/themes/animals/sounds/horse_en.mp3"
            },
            "funFact": "الخيول تُستخدم في الرياضات والفروسية.",
            "info": {
                "description": "الحصان هو حيوان كبير وقوي.",
                "bodyInfo": [
                    "يعيش الحصان في الحظائر.",
                    "يتغذى الحصان على الأعشاب والحبوب.",
                    "يمكن أن يعيش الحصان لعدة عقود."
                ],
                "funFacts": [
                    "تستطيع الخيول الجري بسرعة تصل إلى 70 كم/ساعة.",
                    "تعتبر الخيول من الحيوانات الاجتماعية.",
                    "تستخدم الخيول في النقل والزراعة."
                ]
            }
        }
    ]
}
