// store/productsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import appConfig from '../config/appConfig'; // Import appConfig

// Async thunk using Axios to fetch product data
export const fetchProductsData = createAsyncThunk(
    'products/fetchProductsData',
    async (ids, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${appConfig.apiBaseUrl}/products`, // Use apiBaseUrl from config
                { ids },
                {
                    headers: appConfig.headers,
                    timeout: appConfig.timeout,
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch product data');
        }
    }
);

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductsData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProductsData.fulfilled, (state, action) => {
                const fetchedData = action.payload;
                const dataMap = new Map(fetchedData.map((item) => [item.id, item]));

                state.products = state.products.map((product) => ({
                    ...product,
                    ...dataMap.get(product.id),
                }));
                state.status = 'succeeded';
            })
            .addCase(fetchProductsData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'An error occurred';
            });
    },
});

export default productsSlice.reducer;
