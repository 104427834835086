export const tensTheme = {
    "id": 160,
    "name": "الأرقام - العشرات",
    "slug": "tens",
    "description": "يتضمن الأرقام من 10 إلى 90.",
    "textures": {
        "right": "/memoryGame/themes/numbers/tens/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/numbers/tens/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/numbers/tens/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/numbers/tens/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/numbers/tens/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/numbers/tens/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "الرقم 10",
            "slug": "ten",
            "picture": "memoryGame/themes/numbers/tens/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/tens/sounds/ten_ar.mp3",
                "fr": "memoryGame/themes/numbers/tens/sounds/ten_fr.mp3",
                "en": "memoryGame/themes/numbers/tens/sounds/ten_en.mp3"
            },
            "funFact": "الرقم 10 يُعتبر الرقم الأساسي الذي يبدأ العد العشري.",
            "info": {
                "description": "الرقم 10 هو الرقم الأول في مجموعة العشرات. يُستخدم في العديد من الأنظمة العدية.",
                "bodyInfo": [
                    "الرقم 10 يُستخدم في العمليات الرياضية الأساسية.",
                    "يُعتبر الرقم 10 مثاليًا في الألعاب والاختبارات.",
                    "يُستخدم الرقم 10 لقياس الوقت (10 ثوانٍ) في العديد من الأنشطة."
                ],
                "funFacts": [
                    "يُعتبر الرقم 10 رقم الحظ في العديد من الثقافات.",
                    "العدد 10 هو قاعدة النظام العشري.",
                    "الأصابع العشرة على اليدين تُستخدم كمرجع للعد."
                ]
            }
        },
        {
            "id": 2,
            "name": "الرقم 20",
            "slug": "twenty",
            "picture": "memoryGame/themes/numbers/tens/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/tens/sounds/twenty_ar.mp3",
                "fr": "memoryGame/themes/numbers/tens/sounds/twenty_fr.mp3",
                "en": "memoryGame/themes/numbers/tens/sounds/twenty_en.mp3"
            },
            "funFact": "الرقم 20 يُعتبر نقطة انطلاق للعد في العشرات.",
            "info": {
                "description": "الرقم 20 هو أول رقم في مجموعة العشرينات، ويستخدم بشكل متكرر في العد.",
                "bodyInfo": [
                    "الرقم 20 يُستخدم لتحديد الأعمار في العديد من الثقافات.",
                    "يُمثل الرقم 20 في معظم الأحيان مجموعة كاملة من الأشياء.",
                    "يستخدم الرقم 20 في القياسات (مثل 20 سم)."
                ],
                "funFacts": [
                    "العدد 20 هو رقم عادي جدًا في العديد من الألعاب.",
                    "في الرياضيات، 20 هو عدد صحيح زوجي.",
                    "الرقم 20 هو جزء من الأعداد الطبيعية."
                ]
            }
        },
        {
            "id": 3,
            "name": "الرقم 30",
            "slug": "thirty",
            "picture": "memoryGame/themes/numbers/tens/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/tens/sounds/thirty_ar.mp3",
                "fr": "memoryGame/themes/numbers/tens/sounds/thirty_fr.mp3",
                "en": "memoryGame/themes/numbers/tens/sounds/thirty_en.mp3"
            },
            "funFact": "الرقم 30 يُعتبر عددًا مثاليًا للإشارة إلى ثلاث مجموعات من 10.",
            "info": {
                "description": "الرقم 30 هو الرقم الثالث في مجموعة العشرات. يُعتبر عددًا مميزًا في العديد من المجالات.",
                "bodyInfo": [
                    "الرقم 30 يُستخدم لتحديد الأعداد في الدراسات والإحصائيات.",
                    "في الرياضيات، يُستخدم الرقم 30 في العمليات الحسابية.",
                    "تُستخدم المجموعة من 30 في العديد من الألعاب."
                ],
                "funFacts": [
                    "الرقم 30 يُعتبر عددًا رائعًا في اختبارات الذكاء.",
                    "يمثل الرقم 30 عددًا ممتازًا في الأعمال التجارية.",
                    "العدد 30 هو رقم صحيح يمثل ثلاثة عشر."
                ]
            }
        },
        {
            "id": 4,
            "name": "الرقم 40",
            "slug": "forty",
            "picture": "memoryGame/themes/numbers/tens/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/tens/sounds/forty_ar.mp3",
                "fr": "memoryGame/themes/numbers/tens/sounds/forty_fr.mp3",
                "en": "memoryGame/themes/numbers/tens/sounds/forty_en.mp3"
            },
            "funFact": "الرقم 40 يُعتبر عددًا ممتازًا ويستخدم في العديد من الألعاب.",
            "info": {
                "description": "الرقم 40 هو العدد الرابع في مجموعة العشرات. يُستخدم في العد والتعداد.",
                "bodyInfo": [
                    "يُمثل الرقم 40 مجموعة من أربعين شيء.",
                    "الرقم 40 يُستخدم في العمليات الرياضية.",
                    "يُعتبر الرقم 40 جزءًا من الحياة اليومية."
                ],
                "funFacts": [
                    "العدد 40 يُستخدم كحد أدنى في بعض الألعاب.",
                    "يمثل الرقم 40 عددًا طبيعيًا.",
                    "الرقم 40 هو عدد شائع في حسابات المال."
                ]
            }
        },
        {
            "id": 5,
            "name": "الرقم 50",
            "slug": "fifty",
            "picture": "memoryGame/themes/numbers/tens/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/tens/sounds/fifty_ar.mp3",
                "fr": "memoryGame/themes/numbers/tens/sounds/fifty_fr.mp3",
                "en": "memoryGame/themes/numbers/tens/sounds/fifty_en.mp3"
            },
            "funFact": "الرقم 50 يمثل منتصف مجموعة العشرات.",
            "info": {
                "description": "الرقم 50 هو الرقم الخامس في مجموعة العشرات. يُعتبر نقطة محورية في العد.",
                "bodyInfo": [
                    "العدد 50 يُستخدم في القياسات.",
                    "يمثل الرقم 50 قيمة مهمة في التجارة.",
                    "الرقم 50 شائع في الحسابات اليومية."
                ],
                "funFacts": [
                    "العدد 50 يُستخدم كثيرًا في الألعاب.",
                    "يمثل الرقم 50 عددًا صحيحًا.",
                    "يُستخدم الرقم 50 كمرجع في العديد من الاختبارات."
                ]
            }
        },
        {
            "id": 6,
            "name": "الرقم 60",
            "slug": "sixty",
            "picture": "memoryGame/themes/numbers/tens/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/tens/sounds/sixty_ar.mp3",
                "fr": "memoryGame/themes/numbers/tens/sounds/sixty_fr.mp3",
                "en": "memoryGame/themes/numbers/tens/sounds/sixty_en.mp3"
            },
            "funFact": "الرقم 60 يُستخدم في قياس الزمن، مثل الدقائق.",
            "info": {
                "description": "الرقم 60 يُعتبر الرقم السادس في مجموعة العشرات. يُستخدم في قياسات الزمن.",
                "bodyInfo": [
                    "العدد 60 يُستخدم لقياس الوقت (60 دقيقة).",
                    "يمثل الرقم 60 عددًا مهمًا في الثقافة.",
                    "يُستخدم الرقم 60 في الرياضيات."
                ],
                "funFacts": [
                    "العدد 60 يُعتبر عددًا مثاليًا في الأنظمة العددية.",
                    "يمثل الرقم 60 عددًا صحيحًا.",
                    "يُستخدم الرقم 60 كمرجع في العديد من العروض التقديمية."
                ]
            }
        },
        {
            "id": 7,
            "name": "الرقم 70",
            "slug": "seventy",
            "picture": "memoryGame/themes/numbers/tens/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/tens/sounds/seventy_ar.mp3",
                "fr": "memoryGame/themes/numbers/tens/sounds/seventy_fr.mp3",
                "en": "memoryGame/themes/numbers/tens/sounds/seventy_en.mp3"
            },
            "funFact": "الرقم 70 يُعتبر عددًا مثيرًا للاهتمام ويستخدم في العديد من المجالات.",
            "info": {
                "description": "الرقم 70 هو الرقم السابع في مجموعة العشرات. يُستخدم في العد.",
                "bodyInfo": [
                    "العدد 70 يُستخدم في القياسات.",
                    "يمثل الرقم 70 قيمة مهمة في العديد من الأنظمة.",
                    "يُستخدم الرقم 70 كمرجع."
                ],
                "funFacts": [
                    "العدد 70 يُعتبر رقمًا رائعًا في العديد من الألعاب.",
                    "يمثل الرقم 70 عددًا صحيحًا.",
                    "يُستخدم الرقم 70 في الحسابات اليومية."
                ]
            }
        },
        {
            "id": 8,
            "name": "الرقم 80",
            "slug": "eighty",
            "picture": "memoryGame/themes/numbers/tens/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/tens/sounds/eighty_ar.mp3",
                "fr": "memoryGame/themes/numbers/tens/sounds/eighty_fr.mp3",
                "en": "memoryGame/themes/numbers/tens/sounds/eighty_en.mp3"
            },
            "funFact": "الرقم 80 يُستخدم في العديد من الأنظمة العددية.",
            "info": {
                "description": "الرقم 80 هو الرقم الثامن في مجموعة العشرات. يُعتبر عددًا مهمًا في العد.",
                "bodyInfo": [
                    "العدد 80 يُستخدم في القياسات.",
                    "يمثل الرقم 80 قيمة مهمة في الأنظمة العددية.",
                    "يُستخدم الرقم 80 كمرجع."
                ],
                "funFacts": [
                    "العدد 80 يُعتبر عددًا رائعًا في الأنشطة.",
                    "يمثل الرقم 80 عددًا صحيحًا.",
                    "يُستخدم الرقم 80 في الأنشطة اليومية."
                ]
            }
        },
        {
            "id": 9,
            "name": "الرقم 90",
            "slug": "ninety",
            "picture": "memoryGame/themes/numbers/tens/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/numbers/tens/sounds/ninety_ar.mp3",
                "fr": "memoryGame/themes/numbers/tens/sounds/ninety_fr.mp3",
                "en": "memoryGame/themes/numbers/tens/sounds/ninety_en.mp3"
            },
            "funFact": "الرقم 90 هو الرقم الأخير في مجموعة العشرات.",
            "info": {
                "description": "الرقم 90 هو الرقم التاسع في مجموعة العشرات. يُستخدم في العد.",
                "bodyInfo": [
                    "العدد 90 يُستخدم في القياسات.",
                    "يمثل الرقم 90 قيمة مهمة في العد.",
                    "يُستخدم الرقم 90 كمرجع."
                ],
                "funFacts": [
                    "العدد 90 يُعتبر عددًا رائعًا في العديد من الأنشطة.",
                    "يمثل الرقم 90 عددًا صحيحًا.",
                    "يُستخدم الرقم 90 في الألعاب."
                ]
            }
        }
    ]
};
