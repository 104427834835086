import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { themes } from "../../json/memory_game/themes";

// Utility to get random cards from all themes
const getRandomCardsFromThemes = (themes, numberOfCards) => {
    const allCards = themes.reduce((acc, theme) => acc.concat(theme.cards), []);
    const shuffledCards = [...allCards].sort(() => 0.5 - Math.random());
    return shuffledCards.slice(0, numberOfCards);
};

const Slider = ({ numberOfCards = 10 }) => {
    const [currentCard, setCurrentCard] = useState(0);
    const [randomCards, setRandomCards] = useState([]);

    useEffect(() => {
        setRandomCards(getRandomCardsFromThemes(themes, numberOfCards));
    }, [themes, numberOfCards]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentCard((prevCard) => (prevCard + 1) % randomCards.length);
        }, 3000); // Change every 3 seconds
        return () => clearInterval(interval);
    }, [randomCards]);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                height: "400px", // Adjusted height for the card
                position: "relative",
                width: "100%", // Full width for centering
            }}
        >
            {randomCards.map((card, index) => (
                <motion.div
                    key={card.id}
                    initial={{ opacity: 0, x: 300 }}
                    animate={{
                        opacity: currentCard === index ? 1 : 0,
                        x: currentCard === index ? 0 : -300,
                    }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                    style={{
                        position: "absolute",
                        width: "300px", // Adjust the width as needed
                        height: "400px", // Adjust the height as needed
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Card sx={{ width: "100%", height: "100%", boxShadow: 3 }}>
                        <CardContent>
                            <Typography variant="h5" align="center" gutterBottom>
                                {card.name}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "200px", // Ensures the image is centered
                                }}
                            >
                                <img
                                    src={`/${card.picture}`}
                                    alt={card.name}
                                    style={{
                                        height: "300px",
                                        objectFit: "contain",
                                        maxHeight: "300px",
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                                    }}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </motion.div>
            ))}
        </Box>
    );
};

export default Slider;
