import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { AddTask as AddTaskIcon } from '@mui/icons-material';
import DeliveryTimeline from "../common/cart/DeliveryTimeLine";
import {frontApiCall} from "../api/config";
import {Helmet} from "react-helmet"; // Assuming this is your timeline component

export default function ConfirmOrder() {
    const { orderId } = useParams(); // Get the orderId from the URL parameters
    const [order, setOrder] = useState(null); // State to store the order details
    const [isLoading, setIsLoading] = useState(true);

    const fadeIn = {
        hidden: { opacity: 0, x: "-100%" },
        visible: { opacity: 1, x: 0 },
    };

    useEffect(() => {
        const fetchOrderData = async () => {
            try {
                // Replace with your actual API URL and authentication headers
                const response = await frontApiCall().get(`/orders/get/${orderId}`);
                setOrder(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching order data:", error);
                setIsLoading(false);
            }
        };

        if (orderId) {
            fetchOrderData();
        }
    }, [orderId]);

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Grid container item xs={12}>
            <Helmet>
                <script>
                    fbq('track', 'Purchase');
                </script>
            </Helmet>
            <Grid container item xs={12} justifyContent="center">
                {
                    !order ?
                        <Typography variant="h6" color="error">Order not found!</Typography>
                        :
                        <Stack alignItems="center" spacing={4}>
                            <motion.div
                                initial="hidden"
                                animate="visible"
                                variants={fadeIn}
                                transition={{ duration: 0.5 }}
                            >
                                <Typography variant="h5" fontWeight={900}>
                                    <AddTaskIcon sx={{ fontSize: 70 }} color="success" />
                                    Commande Confirmé !
                                </Typography>
                            </motion.div>

                            <motion.div
                                initial="hidden"
                                animate="visible"
                                variants={fadeIn}
                                transition={{ duration: 0.7 }}
                            >
                                <DeliveryTimeline order={order} />
                            </motion.div>
                        </Stack>
                }
            </Grid>
        </Grid>
    );
}
