import React from 'react';
import { Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const MotionTypography = motion(Typography);

export default function AnimatedText() {
    const textLines = [
        { text: "بإستخدام الهاتف", variant: "h6", color: "inherit" },
        { text: "يمكن لطفلك الوصول", variant: "h6", color: "inherit" },
        { text: "إلى صفحة تحتوي على", variant: "h6", color: "inherit" },
        { text: "طريقة نطق الكلمة التي توجد في البطاقة", variant: "h6", color: "inherit" },
        { text: "بــ 3 لغات", variant: "h2", color: "primary" }, // Primary color for emphasis
        { text: "عربية، فرنسية و إنجلزية", variant: "h6", color: "inherit" },
        { text: "+", variant: "h2", color: "primary" }, // Primary color for emphasis
        { text: "معلومات مشوقة و طريفة على محتوى البطاقة", variant: "h6", color: "inherit" },
    ];

    return (
        <Stack spacing={1}>
            {textLines.map((line, index) => (
                <MotionTypography
                    key={index}
                    align="right"
                    variant={line.variant} // Use variant specified in the array
                    color={line.color} // Use color specified in the array
                    initial={{ x: 1000, opacity: 0 }} // Initial state: off-screen to the right
                    animate={{ x: 0, opacity: 1 }} // Animate to original position
                    transition={{ duration: 0.3, delay: index * 0.2 }} // Staggered animation
                >
                    {line.text}
                </MotionTypography>
            ))}
        </Stack>
    );
}
