import {Box, Chip, Grid, Stack, Typography} from "@mui/material";
import {motion} from "framer-motion";
import React from "react";

const SkillSection = ({ skill, index }) => {
    return (
        <Grid
            dir={"rtl"}
            container
            item
            xs={12}
            sm={12}
            justifyContent="center"
            sx={{ marginBottom: '30px', padding: '20px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '10px', backgroundColor: '#f9f9f9' }}
        >
            <Grid container item xs={12} sm={12} justifyContent="center">
                <Stack direction="column" spacing={2} alignItems="center">
                    {/* Number Badge */}
                    <Box sx={{ position: 'relative', width: '100%' }}>
                        <Chip
                            dir={"rtl"}
                            label={` تعمل لعبة الذاكرة على : `}
                            sx={{ width:"100%", position: 'absolute', left: '10px', top: '-20px', backgroundColor: '#ff5722', color: '#fff', fontWeight: 'bold' }}
                        />
                    </Box>

                    {/* Animating the title */}
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        viewport={{ once: true }}
                    >
                        <Typography align="center" sx={{ fontSize: 18, fontWeight: "bold" }}>
                            {skill.title}
                        </Typography>
                    </motion.div>

                    {/* Animating the image */}
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        viewport={{ once: true }}
                    >
                        <img style={{ width: 100 }} src={skill.image} alt={skill.title} />
                    </motion.div>

                    {/* Animating the description */}
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                        viewport={{ once: true }}
                    >
                        <Typography align="center" sx={{ fontSize: 12, fontWeight: "bold" }}>
                            {skill.description}
                        </Typography>
                    </motion.div>
                </Stack>
            </Grid>
        </Grid>
    );
};


export default SkillSection;