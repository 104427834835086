import React, {useEffect, useRef, useState} from 'react';
import * as THREE from 'three';
import {Canvas, useLoader, useFrame} from '@react-three/fiber';
import {OrbitControls} from '@react-three/drei';

function TexturedBox({textures, setIsLoaded}) {
    const [isVisible, setIsVisible] = useState(false);
    const boxRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true); // Set visibility to true when the box is in view
            }
        }, {
            threshold: 0.1, // Trigger when 10% of the box is visible
        });

        if (boxRef.current) {
            observer.observe(boxRef.current); // Observe the boxRef
        }

        return () => {
            if (boxRef.current) {
                observer.unobserve(boxRef.current); // Cleanup the observer on unmount
            }
        };
    }, []);

    return (<div ref={boxRef} style={{height: '300px'}}>
            <Canvas
                shadows
                camera={{position: [2.7, 0, 0], fov: 25}}
                toneMapping={THREE.ACESFilmicToneMapping}
                onCreated={() => setIsLoaded(true)}
            >
                <color attach="background" args={['#ffffff']}/>
                {/* Set background to white */}
                <hemisphereLight skyColor={'#ffffff'} groundColor={'#ffffff'} intensity={0.8}/>
                <directionalLight
                    castShadow
                    position={[2.8, 5, -20]}
                    intensity={1}
                    // shadow-mapSize={{width: 200, height: 200}}
                />
                <pointLight position={[2.8, 0, 0]} intensity={0.2}/>
                <pointLight position={[-3, 0, 0]} intensity={0.1} color="white"/>
                {/* Add a backlight */}
                <ambientLight intensity={0.6}/>
                <AnimatedCube textures={textures} isVisible={isVisible}/>
            </Canvas>
        </div>);
}

function AnimatedCube({isVisible, textures}) {
    const textureLoader = useLoader(THREE.TextureLoader, [textures.right, textures.left, textures.top, textures.bottom, textures.front, textures.back]);

    // Map each texture to the cube's sides
    const materials = textureLoader.map((texture) => new THREE.MeshStandardMaterial({map: texture}));

    const meshRef = useRef();
    const light1Ref = useRef();
    const light2Ref = useRef();

    // Animation using useFrame
    useFrame(() => {
        if (isVisible && meshRef.current) {
            meshRef.current.rotation.y += 0.005; // Rotate around Y-axis

            // Animate the lights
            if (light1Ref.current) {
                light1Ref.current.position.y = Math.sin(Date.now() * 0.001) * 2 + 2; // Animate light1
            }

            if (light2Ref.current) {
                light2Ref.current.position.x = Math.cos(Date.now() * 0.001) * 5; // Animate light2
                light2Ref.current.position.z = Math.sin(Date.now() * 0.001) * 5; // Animate light2
            }
        }
    });

    return (<>
            <mesh ref={meshRef} material={materials}>
                <boxGeometry args={[0.62, 1.05, 0.13]}/>
                <SemiCircle position={[0, 0.525, 0.056]}/>
            </mesh>
            {/*<pointLight ref={light1Ref} position={[2.8, 2, 2]} intensity={1} />*/}
            {/*<pointLight ref={light2Ref} position={[-5, 5, 5]} intensity={50} color="white" />*/}
        </>);
}

function SemiCircle({position}) {
    const texture = useLoader(THREE.TextureLoader, '/memoryGame/themes/numbers/single/textures/sides/empty_back.webp');

    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;
    texture.center.set(0.5, 0.5);
    texture.repeat.set(0.5, 0.5);

    return (<mesh position={position} rotation={[Math.PI / 1, Math.PI / 2, Math.PI / 2]}>
            <cylinderGeometry args={[0.1, 0.1, 0.02, 32, 1, false, 0, Math.PI]}/>
            <meshStandardMaterial map={texture}/>
        </mesh>);
}


export default TexturedBox;