// Importing each theme's data
import { animalsTheme } from './animalsTheme';
import { clothingTheme } from './clothingTheme';
import { colorsTheme } from './colorsTheme';
import { insectsTheme } from './insectsTheme';
import { wildAnimalsTheme } from './wildAnimalsTheme';
import { vegetablesTheme } from './vegetablesTheme';
import { fruitsTheme } from './fruitsTheme';
import { humanBodyTheme } from './humanBodyTheme';
import { transportationTheme } from './transportationTheme';
import { professionsTheme } from './professionsTheme';
import { spaceWorldTheme } from './spaceWorldTheme';
import { geometricShapesTheme } from './geometricShapesTheme';
import { singleNumbersTheme } from './singleNumbersTheme';
import { marineWorldTheme } from './marineWorldTheme';
import { tensTheme } from './tensTheme';

// Combining all themes into an array
export const themes = [
    animalsTheme,
    clothingTheme,
    singleNumbersTheme,
    tensTheme,
    geometricShapesTheme,
    colorsTheme,
    insectsTheme,
    wildAnimalsTheme,
    vegetablesTheme,
    fruitsTheme,
    humanBodyTheme,
    marineWorldTheme,
    spaceWorldTheme,
    transportationTheme,
    professionsTheme,
];
