import React from "react";
import { motion } from "framer-motion";
import { Box, Typography } from "@mui/material";

// The text to animate
const textLines = [
    "حقّق الفائدة",
    "كلّ يوم بألعاب",
    "و أنشطة مختلفة و متنوعة",
];

const AnimatedCalendarText = () => {
    const textVariants = {
        hidden: { opacity: 0, y: 20 }, // Start slightly below and transparent
        visible: (i) => ({
            opacity: 1,
            y: 0, // Move to the final position
            transition: {
                delay: i * 0.5, // Stagger each line
                duration: 0.8,
                ease: "easeOut",
            },
        }),
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                fontFamily: "Tajawal, sans-serif",
            }}
        >
            {textLines.map((line, index) => (
                <motion.div
                    key={index}
                    custom={index}
                    variants={textVariants}
                    initial="hidden"
                    animate="visible"
                    style={{ marginBottom: "10px" }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                            fontWeight: "bold",
                            color: "#333",
                        }}
                    >
                        {line}
                    </Typography>
                </motion.div>
            ))}
        </Box>
    );
};

export default AnimatedCalendarText;
