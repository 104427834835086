export const spaceWorldTheme = {
    "id": 170,
    "name": "عالم الفضاء",
    "slug": "space-world",
    "description": "يستكشف عالم الفضاء وعجائبه.",
    "textures": {
        "right": "/memoryGame/themes/space-world/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/space-world/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/space-world/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/space-world/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/space-world/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/space-world/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "الأرض",
            "slug": "earth",
            "picture": "memoryGame/themes/space-world/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/space-world/sounds/earth_ar.mp3",
                "fr": "memoryGame/themes/space-world/sounds/earth_fr.mp3",
                "en": "memoryGame/themes/space-world/sounds/earth_en.mp3"
            },
            "funFact": "الأرض هي الكوكب الوحيد المعروف أنه يدعم الحياة.",
            "info": {
                "description": "الأرض هو الكوكب الذي نعيش عليه.",
                "bodyInfo": [
                    "يدور حول الشمس في مدار بيضاوي.",
                    "يمتلك غلافًا جويًا يحمي الحياة.",
                    "يتكون من اليابسة والماء."
                ],
                "funFacts": [
                    "تمثل المياه حوالي 71% من سطح الأرض.",
                    "لديها قمر واحد يدور حولها.",
                    "يعتبر الكوكب الثالث من الشمس."
                ]
            }
        },
        {
            "id": 2,
            "name": "الشمس",
            "slug": "sun",
            "picture": "memoryGame/themes/space-world/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/space-world/sounds/sun_ar.mp3",
                "fr": "memoryGame/themes/space-world/sounds/sun_fr.mp3",
                "en": "memoryGame/themes/space-world/sounds/sun_en.mp3"
            },
            "funFact": "الشمس هي نجم في مركز نظامنا الشمسي.",
            "info": {
                "description": "الشمس هي مصدر الضوء والحرارة على الأرض.",
                "bodyInfo": [
                    "تتكون من الغازات الساخنة مثل الهيدروجين والهيليوم.",
                    "تدور حول مركز المجرة.",
                    "تؤثر في مناخ الأرض وحياة الكائنات."
                ],
                "funFacts": [
                    "الشمس تزن حوالي 99.86% من الكتلة الكلية للنظام الشمسي.",
                    "تحتاج الأرض حوالي 8 دقائق لاستقبال الضوء من الشمس.",
                    "تعتبر الشمس نجمًا من النوع G."
                ]
            }
        },
        {
            "id": 3,
            "name": "كوكب المشتري",
            "slug": "jupiter",
            "picture": "memoryGame/themes/space-world/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/space-world/sounds/jupiter_ar.mp3",
                "fr": "memoryGame/themes/space-world/sounds/jupiter_fr.mp3",
                "en": "memoryGame/themes/space-world/sounds/jupiter_en.mp3"
            },
            "funFact": "كوكب المشتري هو أكبر كواكب النظام الشمسي.",
            "info": {
                "description": "يتميز كوكب المشتري بحجمه الهائل وغلافه الغازي.",
                "bodyInfo": [
                    "يمتلك حلقات رقيقة مثل زحل.",
                    "يمتلك أكثر من 79 قمرًا.",
                    "تتميز بهبوب رياح قوية وعواصف."
                ],
                "funFacts": [
                    "تدور حوله أكبر قمرين في النظام الشمسي، غاليليو وأيو.",
                    "تكون النقطة الحمراء الكبيرة عاصفة ضخمة مستمرة منذ قرون.",
                    "يمتلك مجالًا مغناطيسيًا قويًا."
                ]
            }
        },
        {
            "id": 4,
            "name": "المريخ",
            "slug": "mars",
            "picture": "memoryGame/themes/space-world/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/space-world/sounds/mars_ar.mp3",
                "fr": "memoryGame/themes/space-world/sounds/mars_fr.mp3",
                "en": "memoryGame/themes/space-world/sounds/mars_en.mp3"
            },
            "funFact": "المريخ يسمى الكوكب الأحمر.",
            "info": {
                "description": "المريخ هو رابع كوكب من الشمس.",
                "bodyInfo": [
                    "يمتلك قمريْن، فوبوس وديموس.",
                    "يحتوي على جبال وأودية كبيرة.",
                    "يتكون غلافه الجوي أساسًا من ثاني أكسيد الكربون."
                ],
                "funFacts": [
                    "تظهر آثار تدفق المياه على سطحه.",
                    "تعتبر درجات حرارته متقلبة جدًا.",
                    "يسعى العلماء لاستكشاف إمكانية الحياة عليه."
                ]
            }
        },
        {
            "id": 5,
            "name": "كوكب الزهرة",
            "slug": "venus",
            "picture": "memoryGame/themes/space-world/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/space-world/sounds/venus_ar.mp3",
                "fr": "memoryGame/themes/space-world/sounds/venus_fr.mp3",
                "en": "memoryGame/themes/space-world/sounds/venus_en.mp3"
            },
            "funFact": "كوكب الزهرة هو أقرب كوكب للأرض.",
            "info": {
                "description": "يُعرف كوكب الزهرة بالكوكب المتألق.",
                "bodyInfo": [
                    "يمتلك جوًا كثيفًا من الغازات السامة.",
                    "تدور حول الشمس بشكل عكسي.",
                    "تعتبر درجات الحرارة فيه مرتفعة للغاية."
                ],
                "funFacts": [
                    "يعتبر الزهرة هو الكوكب الأكثر سخونة في النظام الشمسي.",
                    "يمكن رؤية الزهرة بسهولة في سماء الليل.",
                    "تعتبر الجبال والسهول جزءًا من تضاريسه."
                ]
            }
        },
        {
            "id": 6,
            "name": "القمر",
            "slug": "moon",
            "picture": "memoryGame/themes/space-world/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/space-world/sounds/moon_ar.mp3",
                "fr": "memoryGame/themes/space-world/sounds/moon_fr.mp3",
                "en": "memoryGame/themes/space-world/sounds/moon_en.mp3"
            },
            "funFact": "القمر هو الوحيد الذي يدور حول الأرض.",
            "info": {
                "description": "القمر هو جسم فضائي يضيء في سماء الليل.",
                "bodyInfo": [
                    "يمتلك سطحًا مليئًا بالفوهات.",
                    "يتأثر بجاذبية الأرض.",
                    "تحدث ظواهر المد والجزر بسببه."
                ],
                "funFacts": [
                    "القمر هو خامس أكبر قمر في النظام الشمسي.",
                    "تحدث له أطوار متعددة خلال الشهر.",
                    "كان أول كائن فضائي يهبط عليه البشر."
                ]
            }
        },
        {
            "id": 7,
            "name": "صاروخ",
            "slug": "rocket",
            "picture": "memoryGame/themes/space-world/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/space-world/sounds/rocket_ar.mp3",
                "fr": "memoryGame/themes/space-world/sounds/rocket_fr.mp3",
                "en": "memoryGame/themes/space-world/sounds/rocket_en.mp3"
            },
            "funFact": "الصواريخ تستخدم لنقل البشر والأشياء إلى الفضاء.",
            "info": {
                "description": "الصاروخ هو وسيلة للنقل في الفضاء.",
                "bodyInfo": [
                    "يعمل على دفع نفسه بالغازات.",
                    "يمكن استخدامه في الأبحاث والفضاء.",
                    "توجد أنواع مختلفة من الصواريخ."
                ],
                "funFacts": [
                    "يمكن للصواريخ السفر بسرعة كبيرة.",
                    "تستخدم الصواريخ في استكشاف الفضاء.",
                    "يعتبر الصاروخ جزءًا أساسيًا من الرحلات الفضائية."
                ]
            }
        },
        {
            "id": 8,
            "name": "رائد فضاء",
            "slug": "astronaut",
            "picture": "memoryGame/themes/space-world/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/space-world/sounds/astronaut_ar.mp3",
                "fr": "memoryGame/themes/space-world/sounds/astronaut_fr.mp3",
                "en": "memoryGame/themes/space-world/sounds/astronaut_en.mp3"
            },
            "funFact": "رواد الفضاء يقومون بأبحاث في الفضاء.",
            "info": {
                "description": "رائد الفضاء هو شخص مدرب للسفر إلى الفضاء.",
                "bodyInfo": [
                    "يستخدمون بدلات خاصة للحماية.",
                    "يعملون في بيئة غير جاذبية.",
                    "يمكنهم العيش في محطات فضائية."
                ],
                "funFacts": [
                    "كان نيل أرمسترونغ أول رائد فضاء يمشي على القمر.",
                    "يستغرق تدريب رواد الفضاء عدة سنوات.",
                    "يحتاج رواد الفضاء إلى مهارات خاصة."
                ]
            }
        },
        {
            "id": 9,
            "name": "زحل",
            "slug": "saturn",
            "picture": "memoryGame/themes/space-world/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/space-world/sounds/saturn_ar.mp3",
                "fr": "memoryGame/themes/space-world/sounds/saturn_fr.mp3",
                "en": "memoryGame/themes/space-world/sounds/saturn_en.mp3"
            },
            "funFact": "زحل هو الكوكب المعروف بحلقاته.",
            "info": {
                "description": "زحل هو الكوكب السادس من الشمس.",
                "bodyInfo": [
                    "يمتلك نظام حلقات واسع وجميل.",
                    "يمتلك العديد من الأقمار.",
                    "تتشكل حلقاته من الجليد والصخور."
                ],
                "funFacts": [
                    "تعتبر حلقاته أكبر الأنظمة الحلقية في النظام الشمسي.",
                    "يمكن رؤية زحل بسهولة من الأرض.",
                    "يعتبر من الكواكب الغازية."
                ]
            }
        },
        {
            "id": 10,
            "name": "الهلال",
            "slug": "crescent",
            "picture": "memoryGame/themes/space-world/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/space-world/sounds/crescent_ar.mp3",
                "fr": "memoryGame/themes/space-world/sounds/crescent_fr.mp3",
                "en": "memoryGame/themes/space-world/sounds/crescent_en.mp3"
            },
            "funFact": "الهلال هو شكل من أشكال القمر.",
            "info": {
                "description": "الهلال هو شكل القمر عندما يكون مضاءً جزئيًا.",
                "bodyInfo": [
                    "يظهر بعد يومين من بدء الشهر الهجري.",
                    "يعتبر رمزًا مهمًا في الثقافات المختلفة.",
                    "يتغير شكله خلال الشهر."
                ],
                "funFacts": [
                    "يمكن رؤية الهلال بسهولة في السماء.",
                    "يعتبر علامة بداية الشهر في التقويم الإسلامي.",
                    "تحدث له تغييرات مستمرة خلال الشهر."
                ]
            }
        }

    ]
};
