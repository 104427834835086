import React from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import { useCart } from 'react-use-cart';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { openCart, toggleCart } from "../../store/commonConfigSlice";
import CartModal from "../../common/CartModal";

const RtlBar = styled(Box)({
    direction: 'rtl', // Right-to-left direction
});

const FixedBottomComponent = ({ themes }) => {
    const { totalItems, cartTotal, emptyCart, addItem } = useCart(); // Use react-use-cart hooks
    const dispatch = useDispatch(); // Hook for dispatching actions

    const totalThemes = themes.length;
    const remise = 10; // Discount when all themes are selected

    const handlePlaceOrder = () => {
        dispatch(toggleCart()); // Toggle the cart modal visibility using Redux
    };

    const handleBuyAllThemes = () => {
        emptyCart(); // Empty the cart first

        themes.forEach((product) => {
            addItem({
                id: product.id,
                price: parseInt(product.price),
                name: product.name,
                quantity: 1
            });
        });

        dispatch(openCart()); // Open the cart modal after adding all themes
    };

    // Calculate the total after applying the remise if all themes are selected
    const totalBeforeRemise = themes.reduce((acc, theme) => acc + parseInt(theme.price), 0);
    const totalAfterRemise = totalBeforeRemise - remise;

    return (
        <>
            {/* Cart Modal */}
            <CartModal /> {/* CartModal is always rendered, visibility is controlled by Redux */}

            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: '#fff',
                    padding: '10px',
                    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
                    zIndex: 1000,
                }}
            >
                {/* RTL Bar */}
                <RtlBar
                    sx={{
                        marginBottom: '10px',
                        padding: '10px',
                        backgroundColor: '#f7f7f7',
                        borderRadius: '4px',
                    }}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={8}>
                            <Typography variant="body1">
                                لقد قمت بإختيار {totalItems} محاور من لعبة الذاكرة
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6" align="right">
                                المجموع : {cartTotal.toFixed(3)} د
                            </Typography>
                        </Grid>
                    </Grid>
                </RtlBar>

                {/* Bottom Buttons */}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handlePlaceOrder} // Trigger modal visibility
                            sx={{ height: '56px' }}
                        >
                            Passer la commande
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handleBuyAllThemes}
                            sx={{ height: '56px' }}
                        >
                            Acheter tous les thèmes ({totalAfterRemise.toFixed(3)} dt après remise)
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default FixedBottomComponent;
