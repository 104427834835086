import React, { useState, useEffect } from "react";
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    IconButton,
    Typography,
    Stack,
    Box, Alert, Button,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import apiCall, {commonApiCall, frontApiCall, noAuthApiCall} from "../api/config";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

export default function OrderSingleProduct({allPrice = false}) {
    const [activeGov, setActiveGov] = useState({ gov: "", delegations: [] });
    const [govs, setGovs] = useState([]);
    const [loading, setLoading] = useState(true);
    const pixelId = "715804273228523"; // Replace with your Facebook Pixel ID
    ReactPixel.init(pixelId);
    ReactPixel.pageView(); // Track initial page view


    const navigate = useNavigate();

    useEffect(() => {
        commonApiCall('')
            .get("/places/govs/all?withDeleg=true")
            .then((res) => {
                setGovs(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            client: {
                fullName: "",
                phone: "",
                phoneTwo: "",
                deleg_id: "",
                address: "",
            },
            items: [
                {
                    quantity: 1,
                },
            ],
        },
        validationSchema: Yup.object({
            client: Yup.object({
                fullName: Yup.string().required("Nom complet est requis"),
                phone: Yup.string().required("Numéro de téléphone est requis"),
                address: Yup.string().required("Adresse est requise"),
                deleg_id: Yup.string().required("Délégation est requise"),
            }),
        }),
        onSubmit: (values,{setFieldError}) => {

                var data = new FormData();

                    data.append('client[fullName]', values.client.fullName);
                    data.append('client[deleg_id]', values.client.deleg_id);
                    data.append('client[address]', values.client.address);
                    data.append('client[phone]', values.client.phone);
                    data.append('client[phoneTwo]', values.client.phoneTwo);
                    data.append('client[mf]', values.client.mf);
                data.append('order[deliveryDate]', moment().format('YYYY-MM-DD'));
                data.append('order[discount]', discountRate);
                data.append('order[store_id]', 2);
                data.append('order[packageSize]', 1);
                data.append('order[paymentStatus]', 2);
                data.append('order[descProduit]', "Jeux educatifs");
                data.append('order[free_delivery]', 0);
                data.append('order[amount]', total);
                data.append('order[delivery_price]', 8);
                data.append('order[isExchange]',0);

                    data.append(`items[0][product_id]`, 172);
                    data.append(`items[0][quantity]`, quantity);

                frontApiCall("").post('/orders/create', data)
                    .then(res => {
                        // setSnackBar({
                        //     status : true,
                        //     message : "commande passée avec succès",
                        //     severity : "success"
                        // });
                        ReactPixel.track('Purchase', {currency: "TND", value: total})
                        navigate(`/confirm-order/${res.data.order.id}`);
                    })
                    .catch((error) => {

                        if (error.response.status === 400) {
                            if(error.response.data["client.phone"]){
                                setFieldError('client.phone',"le téléphone client est déjà utilisé pour un autre client");
                            }
                        }
                        // setSnackBar({
                        //     status : true,
                        //     message : "Quelque chose s'est mal passé, veuillez réessayer plus tard",
                        //     severity : "error"
                        // })
                    }).finally(() => {
                    // setProgressBar(false);
                    // if(addNew) {
                    //     history.push('/temp');
                    //     history.goBack();
                    // } else {
                    //     history.push(routesName.orders.index.path);
                    //
                    // }
                })
                ;

        }
    });

    const filterSelectedGov = (id) => {
        formik.setFieldValue("client.deleg_id", "");
        const selectedGov = govs.find((gov) => gov.id === id);
        setActiveGov({
            gov: selectedGov.id,
            delegations: selectedGov.delegations,
        });
    };

    const calculateDiscount = (quantity) => {
        if (quantity >= 10) return 0.15; // 15%
        if (quantity >= 4) return 0.10;  // 10%
        if (quantity >= 2) return 0.05;  // 5%
        return 0; // No discount
    };

    const nextDiscountInfo = (quantity) => {
        if (quantity < 2) return `Achetez 2 ou plus pour une remise de -5%.`;
        if (quantity < 4) return `Achetez 4 ou plus pour une remise de -10%.`;
        if (quantity < 10) return `Achetez 10 ou plus pour une remise de -15%.`;
        return ``;
    };

    const [quantity , setQuantity] = useState(1);
    const unitPrice = 20;
    const deliveryFee = 8;
    const discountRate = calculateDiscount(quantity);
    const subtotal = Math.round(quantity * unitPrice);
    const discountAmount = Math.round(subtotal * discountRate);
    const total = Math.round(subtotal + deliveryFee - discountAmount);

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Grid container item xs={12} justifyContent={"center"}>
            <form onSubmit={formik.handleSubmit} style={{width: "100%", maxWidth: 600}}>
                <Grid container alignContent={"center"} xs={12} spacing={2}>
                    {/* Client Information */}
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="Nom complet"
                            name="client.fullName"
                            value={formik.values.client.fullName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.client?.fullName && Boolean(formik.errors.client?.fullName)}
                            helperText={formik.touched.client?.fullName && formik.errors.client?.fullName && 'helper'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="Numéro de téléphone"
                            name="client.phone"
                            value={formik.values.client.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.client?.phone && Boolean(formik.errors.client?.phone)}
                            helperText={formik.touched.client?.phone && formik.errors.client?.phone}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Numéro de téléphone alternatif"
                            name="client.phoneTwo"
                            value={formik.values.client.phoneTwo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.client?.phoneTwo && Boolean(formik.errors.client?.phoneTwo)}
                            helperText={formik.touched.client?.phoneTwo && formik.errors.client?.phoneTwo}
                        />
                    </Grid>
                    <Grid container item xs={6}>
                        <FormControl required fullWidth>
                            <InputLabel shrink id="govs-label">
                                Gouvernorat
                            </InputLabel>
                            <Select
                                labelId="govs-label"
                                value={activeGov.gov}
                                onChange={(event) => filterSelectedGov(event.target.value)}
                            >
                                {govs.map((gov) => (
                                    <MenuItem key={`gov-${gov.id}`} value={gov.id}>
                                        {gov.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {/* Optional: Add FormHelperText if you have validation for this field */}
                            {/* <FormHelperText>{error message if any}</FormHelperText> */}
                        </FormControl>
                    </Grid>
                    <Grid container item xs={6}>
                        <FormControl required fullWidth>
                            <InputLabel shrink id="delegation-label">
                                Délégation
                            </InputLabel>
                            <Select
                                value={formik.values.client.deleg_id}
                                name="client.deleg_id"
                                onChange={formik.handleChange}
                            >
                                {activeGov.delegations.map((delegation) => (
                                    <MenuItem key={`delegation-${delegation.id}`} value={delegation.id}>
                                        {delegation.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText
                                error={getIn(formik.touched, "client.deleg_id") && Boolean(getIn(formik.errors, "client.deleg_id"))}
                            >
                                {getIn(formik.touched, "client.deleg_id") && getIn(formik.errors, "client.deleg_id")}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="Adresse"
                            name="client.address"
                            value={formik.values.client.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.client?.address && Boolean(formik.errors.client?.address)}
                            helperText={formik.touched.client?.address && formik.errors.client?.address}
                        />
                    </Grid>
                    {/* Quantity and Pricing */}
                    <Box display="flex" alignItems="center" gap={1} mt={3} p={3}>
                        <Typography variant="h5" component="div" gutterBottom>Quantité : </Typography>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                                setQuantity(prevState => prevState > 1 ? prevState - 1 : 1)
                            }}
                        >
                            <Remove/>
                        </IconButton>
                        <TextField
                            value={quantity}
                            onChange={(e) => {
                                const value = Math.max(1, Number(e.target.value));
                                setQuantity(value)
                            }}
                            type="number"
                            inputProps={{
                                min: 1,
                                style: {textAlign: "center", padding: "4px 0"},
                            }}
                            size="small"
                            variant="outlined"
                            sx={{width: 60}}
                        />
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                                const updatedQuantity = quantity + 1;
                                setQuantity(updatedQuantity)
                            }}
                        >
                            <Add/>
                        </IconButton>
                    </Box>

                    <Grid container item xs={12}>
                        <Box>
                            {nextDiscountInfo(quantity) && (
                                <Alert severity="warning" style={{fontSize: 16}}>

                                    {nextDiscountInfo(quantity)}
                                </Alert>

                            )}
                        </Box>
                        {
                            allPrice ?
                                <>

                                    <Stack sx={{width: "100%"}} direction="row" justifyContent="space-between"
                                           spacing={1}>
                                        <Typography variant="h5" component="div" gutterBottom>Remise :</Typography>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            -{discountAmount.toFixed(3)} dt ({(discountRate * 100).toFixed(0)}%)
                                        </Typography>
                                    </Stack>
                                    <Stack sx={{width: "100%"}} direction="row" justifyContent="space-between"
                                           spacing={1}>
                                        <Typography color={"primary.main"} variant="h5" component="div" gutterBottom>Total
                                            :</Typography>
                                        <Typography color={"primary.main"} variant="h5" component="div" gutterBottom>
                                            {total.toFixed(3)} dt
                                        </Typography>
                                    </Stack>
                                    <Stack style={{width: "100%"}} justifyContent="center" spacing={1}>
                                        <Button
                                            type={"submit"}
                                            variant="contained"
                                            size="large"
                                            color="success"
                                            sx={{
                                                color: "#fff",
                                                mt: 2,
                                                '&:hover': {transform: 'scale(1.1)'},
                                            }}
                                        >
                                            Confirmer la commande
                                        </Button>
                                    </Stack>
                                </>

                                :

                                <>
                                    <Stack sx={{width: "100%"}} direction="row" justifyContent="space-between"
                                           spacing={1}>
                                        <Typography variant="h5" component="div" gutterBottom>Sous-total :</Typography>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            {subtotal.toFixed(3)} dt
                                        </Typography>
                                    </Stack>
                                    <Stack sx={{width: "100%"}} direction="row" justifyContent="space-between"
                                           spacing={1}>
                                        <Typography variant="h5" component="div" gutterBottom>Remise :</Typography>
                                        <Typography color={"primary.main"} variant="h5" component="div" gutterBottom>
                                            -{discountAmount.toFixed(3)} dt ({(discountRate * 100).toFixed(0)}%)
                                        </Typography>
                                    </Stack>
                                    <Stack sx={{width: "100%"}} direction="row" justifyContent="space-between"
                                           spacing={1}>
                                        <Typography variant="h5" component="div" gutterBottom>Livraison :</Typography>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            {deliveryFee.toFixed(3)} dt
                                        </Typography>
                                    </Stack>
                                    <Stack sx={{width: "100%"}} direction="row" justifyContent="space-between"
                                           spacing={1}>
                                        <Typography color={"success"} variant="h5" component="div" gutterBottom>Total
                                            :</Typography>
                                        <Typography color={"success"} variant="h5" component="div" gutterBottom>
                                            {total.toFixed(3)} dt
                                        </Typography>
                                    </Stack>
                                    <Stack justifyContent="center" spacing={1}>
                                        <Button
                                            type={"submit"}
                                            variant="contained"
                                            size="large"
                                            color="success"
                                            sx={{
                                                color: "#fff",
                                                mt: 2,
                                                '&:hover': {transform: 'scale(1.1)'},
                                            }}
                                        >
                                            Confirmer la commande
                                        </Button>
                                    </Stack>
                                </>
                        }
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
}
