import React, { useState } from 'react';
import {Grid, Slider, Stack, Typography} from '@mui/material';
import { motion } from 'framer-motion';

const AgeRangePlayersComponent = () => {
    // State for age range and number of players
    const [ageRange, setAgeRange] = useState([18, 30]);
    const [players, setPlayers] = useState(10);

    // Handle change in slider
    const handleAgeRangeChange = (event, newValue) => {
        setAgeRange(newValue);
    };

    return (
        <Grid container item xs={12}>
            <Grid container item xs={12} justify="center">
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent={"center"}
                    sx={{
                        width: "100%",
                        backgroundColor: "primary.main",
                        color: "white",
                        borderRadius: 4,
                        padding : 4
                }}
                    dir={"rtl"}
                    direction={"column"}
                >
                    <Grid container item xs={12}>
                        <Grid container item xs={12} sm={6} justifyContent="center">
                            <Stack direction="column" spacing={2}>
                                <Typography  sx={{fontSize : 35, fontWeight :"bold", textAlign : "center"}}>العمر المناسب </Typography>
                                <Typography  sx={{fontSize : 25, fontWeight :"bold", textAlign : "center"}}>3 سنوات +</Typography>
                            </Stack>
                        </Grid>
                        <Grid container item xs={12} sm={6} justifyContent="center">
                            <Stack direction="column" spacing={2}>
                                <Typography  sx={{fontSize : 35, fontWeight :"bold", textAlign : "center"}}>عدد اللاعبين</Typography>
                                <Typography  sx={{fontSize : 35, fontWeight :"bold", textAlign : "center"}}>1 +</Typography>
                            </Stack>

                        </Grid>
                    </Grid>

                </Stack>
            </Grid>
        </Grid>
    );
};

export default AgeRangePlayersComponent;
