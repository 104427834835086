export const fruitsTheme = {
    "id": 165,
    "name": "الغلال",
    "slug": "fruits",
    "description": "يضم مجموعة من الفواكه المختلفة.",
    "textures": {
        "right": "/memoryGame/themes/fruits/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/fruits/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/fruits/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/fruits/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/fruits/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/fruits/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "الإجّاص",
            "slug": "pear",
            "picture": "memoryGame/themes/fruits/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/fruits/sounds/pear_ar.mp3",
                "fr": "memoryGame/themes/fruits/sounds/pear_fr.mp3",
                "en": "memoryGame/themes/fruits/sounds/pear_en.mp3"
            },
            "funFact": "الإجّاص يحتوي على الألياف المفيدة.",
            "info": {
                "description": "الإجّاص هو فاكهة لذيذة ومغذية.",
                "bodyInfo": [
                    "يحتوي على فيتامين C.",
                    "يعتبر مصدرًا جيدًا للألياف.",
                    "يمكن تناوله طازجًا أو في العصائر."
                ],
                "funFacts": [
                    "هناك أنواع متعددة من الإجّاص.",
                    "يمكن أن يساعد في تعزيز صحة القلب.",
                    "يعتبر فاكهة مثالية للوجبات الخفيفة."
                ]
            }
        },
        {
            "id": 2,
            "name": "الكيوي",
            "slug": "kiwi",
            "picture": "memoryGame/themes/fruits/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/fruits/sounds/kiwi_ar.mp3",
                "fr": "memoryGame/themes/fruits/sounds/kiwi_fr.mp3",
                "en": "memoryGame/themes/fruits/sounds/kiwi_en.mp3"
            },
            "funFact": "الكيوي غني بالفيتامينات.",
            "info": {
                "description": "الكيوي هو فاكهة خضراء ومغذية.",
                "bodyInfo": [
                    "يحتوي على نسبة عالية من فيتامين C.",
                    "يمكن تناوله طازجًا أو في العصائر.",
                    "يمتاز بنكهته الفريدة."
                ],
                "funFacts": [
                    "يعتبر الكيوي مفيدًا لصحة الجهاز الهضمي.",
                    "يمكن أن يساعد في تعزيز المناعة.",
                    "يحتوي على مضادات أكسدة قوية."
                ]
            }
        },
        {
            "id": 3,
            "name": "المشمش",
            "slug": "apricot",
            "picture": "memoryGame/themes/fruits/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/fruits/sounds/apricot_ar.mp3",
                "fr": "memoryGame/themes/fruits/sounds/apricot_fr.mp3",
                "en": "memoryGame/themes/fruits/sounds/apricot_en.mp3"
            },
            "funFact": "المشمش يحتوي على مضادات الأكسدة.",
            "info": {
                "description": "المشمش هو فاكهة لذيذة ومغذية.",
                "bodyInfo": [
                    "يعتبر مصدرًا جيدًا للفيتامين A.",
                    "يمكن تناوله طازجًا أو مجففًا.",
                    "يمتاز بمذاقه الحلو."
                ],
                "funFacts": [
                    "يمكن أن يساعد المشمش في تحسين صحة الجلد.",
                    "يعتبر مفيدًا لصحة العين.",
                    "يحتوي على العديد من المعادن."
                ]
            }
        },
        {
            "id": 4,
            "name": "الفراولة",
            "slug": "strawberry",
            "picture": "memoryGame/themes/fruits/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/fruits/sounds/strawberry_ar.mp3",
                "fr": "memoryGame/themes/fruits/sounds/strawberry_fr.mp3",
                "en": "memoryGame/themes/fruits/sounds/strawberry_en.mp3"
            },
            "funFact": "الفراولة غنية بالفيتامين C.",
            "info": {
                "description": "الفراولة هي فاكهة حمراء ولذيذة.",
                "bodyInfo": [
                    "تعتبر مصدرًا جيدًا للفيتامينات.",
                    "يمكن تناولها طازجة أو في الحلويات.",
                    "تمتاز بطعمها الحلو والحامض."
                ],
                "funFacts": [
                    "الفراولة من الفواكه المفضلة في الصيف.",
                    "يمكن أن تساعد في تعزيز صحة القلب.",
                    "تحتوي على مضادات أكسدة قوية."
                ]
            }
        },
        {
            "id": 5,
            "name": "الموز",
            "slug": "banana",
            "picture": "memoryGame/themes/fruits/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/fruits/sounds/banana_ar.mp3",
                "fr": "memoryGame/themes/fruits/sounds/banana_fr.mp3",
                "en": "memoryGame/themes/fruits/sounds/banana_en.mp3"
            },
            "funFact": "الموز يعتبر مصدرًا جيدًا للبوتاسيوم.",
            "info": {
                "description": "الموز هو فاكهة مشهورة ولذيذة.",
                "bodyInfo": [
                    "يعتبر خيارًا مثاليًا للوجبات الخفيفة.",
                    "يمكن تناوله طازجًا أو في العصائر.",
                    "يحتوي على الألياف."
                ],
                "funFacts": [
                    "الموز يعتبر فاكهة مثالية لزيادة الطاقة.",
                    "يمكن أن يساعد في تحسين المزاج.",
                    "يحتوي على العديد من الفيتامينات."
                ]
            }
        },
        {
            "id": 6,
            "name": "العنب",
            "slug": "grape",
            "picture": "memoryGame/themes/fruits/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/fruits/sounds/grape_ar.mp3",
                "fr": "memoryGame/themes/fruits/sounds/grape_fr.mp3",
                "en": "memoryGame/themes/fruits/sounds/grape_en.mp3"
            },
            "funFact": "العنب يعتبر فاكهة مرطبة.",
            "info": {
                "description": "العنب هو فاكهة صغيرة ولذيذة.",
                "bodyInfo": [
                    "يمكن تناوله طازجًا أو في العصائر.",
                    "يحتوي على مضادات أكسدة.",
                    "يمتاز بنكهته الحلوة."
                ],
                "funFacts": [
                    "يمكن استخدام العنب في صناعة النبيذ.",
                    "يحتوي على العديد من الفيتامينات.",
                    "يعتبر من الفواكه المثالية للوجبات الخفيفة."
                ]
            }
        },
        {
            "id": 7,
            "name": "الأناناس",
            "slug": "pineapple",
            "picture": "memoryGame/themes/fruits/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/fruits/sounds/pineapple_ar.mp3",
                "fr": "memoryGame/themes/fruits/sounds/pineapple_fr.mp3",
                "en": "memoryGame/themes/fruits/sounds/pineapple_en.mp3"
            },
            "funFact": "الأناناس يحتوي على إنزيمات تساعد في الهضم.",
            "info": {
                "description": "الأناناس هو فاكهة استوائية ولذيذة.",
                "bodyInfo": [
                    "يمكن تناوله طازجًا أو في العصائر.",
                    "يعتبر مصدرًا جيدًا للفيتامين C.",
                    "يمتاز بنكهته الحلوة والحامضة."
                ],
                "funFacts": [
                    "يستخدم الأناناس في العديد من الأطباق.",
                    "يمكن أن يساعد في تعزيز صحة الجهاز الهضمي.",
                    "يحتوي على العديد من العناصر الغذائية."
                ]
            }
        },
        {
            "id": 8,
            "name": "البطيخ",
            "slug": "watermelon",
            "picture": "memoryGame/themes/fruits/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/fruits/sounds/watermelon_ar.mp3",
                "fr": "memoryGame/themes/fruits/sounds/watermelon_fr.mp3",
                "en": "memoryGame/themes/fruits/sounds/watermelon_en.mp3"
            },
            "funFact": "البطيخ يحتوي على نسبة عالية من الماء.",
            "info": {
                "description": "البطيخ هو فاكهة صيفية رائعة.",
                "bodyInfo": [
                    "يمكن تناوله طازجًا.",
                    "يمتاز بمذاقه المنعش.",
                    "يحتوي على الفيتامينات والمعادن."
                ],
                "funFacts": [
                    "يعتبر البطيخ مثاليًا في أيام الصيف الحارة.",
                    "يمكن أن يساعد في ترطيب الجسم.",
                    "يحتوي على الأحماض الأمينية."
                ]
            }
        },
        {
            "id": 9,
            "name": "التفاح",
            "slug": "apple",
            "picture": "memoryGame/themes/fruits/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/fruits/sounds/apple_ar.mp3",
                "fr": "memoryGame/themes/fruits/sounds/apple_fr.mp3",
                "en": "memoryGame/themes/fruits/sounds/apple_en.mp3"
            },
            "funFact": "التفاح يعتبر مصدرًا جيدًا للألياف.",
            "info": {
                "description": "التفاح هو فاكهة مغذية ولذيذة.",
                "bodyInfo": [
                    "يمكن تناوله طازجًا أو في العصائر.",
                    "يمتاز بمذاقه الحلو.",
                    "يحتوي على العديد من الفيتامينات."
                ],
                "funFacts": [
                    "يعتبر التفاح من الفواكه المفضلة في العالم.",
                    "يمكن أن يساعد في تعزيز صحة القلب.",
                    "يحتوي على مضادات أكسدة قوية."
                ]
            }
        },
        {
            "id": 10,
            "name": "البرتقال",
            "slug": "orange",
            "picture": "memoryGame/themes/fruits/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/fruits/sounds/orange_ar.mp3",
                "fr": "memoryGame/themes/fruits/sounds/orange_fr.mp3",
                "en": "memoryGame/themes/fruits/sounds/orange_en.mp3"
            },
            "funFact": "البرتقال غني بفيتامين C.",
            "info": {
                "description": "البرتقال هو فاكهة لذيذة ومنعشة.",
                "bodyInfo": [
                    "يعتبر مصدرًا جيدًا للماء.",
                    "يمكن تناوله طازجًا أو في العصائر.",
                    "يمتاز بمذاقه الحامض."
                ],
                "funFacts": [
                    "يعتبر البرتقال من الفواكه المفيدة للجهاز المناعي.",
                    "يمكن أن يساعد في تحسين صحة القلب.",
                    "يحتوي على مضادات أكسدة قوية."
                ]
            }
        }

    ]
}