export const clothingTheme = {
    "id": 166,
    "name": "الملابس",
    "slug": "clothing",
    "description": "يضم مجموعة من الملابس المختلفة.",
    "textures": {
        "right": "/memoryGame/themes/clothing/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/clothing/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/clothing/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/clothing/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/clothing/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/clothing/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "الحذاء",
            "slug": "shoe",
            "picture": "memoryGame/themes/clothing/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/clothing/sounds/shoe_ar.mp3",
                "fr": "memoryGame/themes/clothing/sounds/shoe_fr.mp3",
                "en": "memoryGame/themes/clothing/sounds/shoe_en.mp3"
            },
            "funFact": "الحذاء يحمي القدمين.",
            "info": {
                "description": "الحذاء هو جزء أساسي من الملابس.",
                "bodyInfo": [
                    "يوجد أنواع مختلفة من الأحذية.",
                    "يمكن أن يكون رياضيًا أو رسميًا.",
                    "يجب اختيار الحذاء المناسب لكل مناسبة."
                ],
                "funFacts": [
                    "يعود تاريخ الأحذية إلى آلاف السنين.",
                    "يمكن أن تؤثر الأحذية على المشي والراحة.",
                    "هناك أحذية مصممة خصيصًا للرياضة."
                ]
            }
        },
        {
            "id": 2,
            "name": "الجوارب",
            "slug": "socks",
            "picture": "memoryGame/themes/clothing/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/clothing/sounds/socks_ar.mp3",
                "fr": "memoryGame/themes/clothing/sounds/socks_fr.mp3",
                "en": "memoryGame/themes/clothing/sounds/socks_en.mp3"
            },
            "funFact": "الجوارب توفر الراحة للقدمين.",
            "info": {
                "description": "الجوارب هي قطع ملابس ترتدى على القدمين.",
                "bodyInfo": [
                    "تحافظ على دفء القدمين.",
                    "تحمي القدمين من الاحتكاك.",
                    "يمكن أن تكون مصنوعة من مواد مختلفة."
                ],
                "funFacts": [
                    "يوجد أنواع متنوعة من الجوارب.",
                    "الجوارب يمكن أن تكون ملونة أو مزخرفة.",
                    "يستخدم البعض الجوارب كنوع من الأزياء."
                ]
            }
        },
        {
            "id": 3,
            "name": "التنورة",
            "slug": "skirt",
            "picture": "memoryGame/themes/clothing/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/clothing/sounds/skirt_ar.mp3",
                "fr": "memoryGame/themes/clothing/sounds/skirt_fr.mp3",
                "en": "memoryGame/themes/clothing/sounds/skirt_en.mp3"
            },
            "funFact": "التنانير تعبر عن الأناقة.",
            "info": {
                "description": "التنورة هي قطعة ملابس نسائية.",
                "bodyInfo": [
                    "تأتي بتصاميم وأطوال مختلفة.",
                    "يمكن ارتداؤها في المناسبات المختلفة.",
                    "تعتبر من الأزياء الكلاسيكية."
                ],
                "funFacts": [
                    "تم ارتداء التنانير لآلاف السنين.",
                    "يمكن أن تكون تنانير قصيرة أو طويلة.",
                    "التنانير يمكن أن تتناسب مع أنواع مختلفة من الملابس."
                ]
            }
        },
        {
            "id": 4,
            "name": "السروال",
            "slug": "pants",
            "picture": "memoryGame/themes/clothing/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/clothing/sounds/pants_ar.mp3",
                "fr": "memoryGame/themes/clothing/sounds/pants_fr.mp3",
                "en": "memoryGame/themes/clothing/sounds/pants_en.mp3"
            },
            "funFact": "السراويل توفر الراحة والتحرك بحرية.",
            "info": {
                "description": "السروال هو جزء شائع من الملابس.",
                "bodyInfo": [
                    "يتوفر بأشكال وألوان متنوعة.",
                    "يمكن أن يكون رسميًا أو غير رسمي.",
                    "يستخدم في الحياة اليومية."
                ],
                "funFacts": [
                    "يعود تاريخ السراويل إلى العصور القديمة.",
                    "يمكن ارتداء السراويل في كل الفصول.",
                    "يمكن أن تتناسب مع العديد من الأزياء."
                ]
            }
        },
        {
            "id": 5,
            "name": "تي شيرت",
            "slug": "t-shirt",
            "picture": "memoryGame/themes/clothing/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/clothing/sounds/tshirt_ar.mp3",
                "fr": "memoryGame/themes/clothing/sounds/tshirt_fr.mp3",
                "en": "memoryGame/themes/clothing/sounds/tshirt_en.mp3"
            },
            "funFact": "تي شيرت هو قطعة ملابس مريحة.",
            "info": {
                "description": "تي شيرت هو قميص قصير الأكمام.",
                "bodyInfo": [
                    "يمكن أن يكون بسيطًا أو مزخرفًا.",
                    "يمتاز بالراحة والسهولة في الارتداء.",
                    "يمكن ارتداؤه في العديد من المناسبات."
                ],
                "funFacts": [
                    "نشأ تي شيرت كملابس داخلية.",
                    "يعتبر رمزا ثقافيا في العديد من البلدان.",
                    "يمكن تخصيص تي شيرت بأشكال متعددة."
                ]
            }
        },
        {
            "id": 6,
            "name": "القبعة",
            "slug": "hat",
            "picture": "memoryGame/themes/clothing/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/clothing/sounds/hat_ar.mp3",
                "fr": "memoryGame/themes/clothing/sounds/hat_fr.mp3",
                "en": "memoryGame/themes/clothing/sounds/hat_en.mp3"
            },
            "funFact": "القبعات تحمي من الشمس.",
            "info": {
                "description": "القبعة هي قطعة ملابس تلبس على الرأس.",
                "bodyInfo": [
                    "تأتي بأشكال وألوان متعددة.",
                    "يمكن أن تكون عملية أو زينة.",
                    "تستخدم في المناسبات المختلفة."
                ],
                "funFacts": [
                    "تعتبر القبعات جزءًا من الثقافة في بعض البلدان.",
                    "يمكن أن تستخدم القبعات في الحماية من الطقس.",
                    "يعود تاريخ القبعات لآلاف السنين."
                ]
            }
        },
        {
            "id": 7,
            "name": "المعطف",
            "slug": "coat",
            "picture": "memoryGame/themes/clothing/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/clothing/sounds/coat_ar.mp3",
                "fr": "memoryGame/themes/clothing/sounds/coat_fr.mp3",
                "en": "memoryGame/themes/clothing/sounds/coat_en.mp3"
            },
            "funFact": "المعاطف تحافظ على الدفء في الأجواء الباردة.",
            "info": {
                "description": "المعطف هو قطعة ملابس خارجية.",
                "bodyInfo": [
                    "يستخدم في فصل الشتاء.",
                    "يمكن أن يكون طويلًا أو قصيرًا.",
                    "يتوفر بتصاميم مختلفة."
                ],
                "funFacts": [
                    "المعاطف تعود إلى العصور القديمة.",
                    "يمكن أن تكون مصنوعة من مواد متنوعة.",
                    "تعتبر جزءًا أساسيًا من الملابس الشتوية."
                ]
            }
        },
        {
            "id": 8,
            "name": "القميص",
            "slug": "shirt",
            "picture": "memoryGame/themes/clothing/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/clothing/sounds/shirt_ar.mp3",
                "fr": "memoryGame/themes/clothing/sounds/shirt_fr.mp3",
                "en": "memoryGame/themes/clothing/sounds/shirt_en.mp3"
            },
            "funFact": "القميص قطعة ملابس كلاسيكية.",
            "info": {
                "description": "القميص هو جزء من الملابس التقليدية.",
                "bodyInfo": [
                    "يمكن أن يكون رسميًا أو غير رسمي.",
                    "يمتاز بالراحة والتنوع.",
                    "يعتبر من الملابس الأساسية."
                ],
                "funFacts": [
                    "تعود أصول القميص إلى العصور القديمة.",
                    "يمكن أن يكون مزخرفًا أو بسيطًا.",
                    "يستخدم في المناسبات المختلفة."
                ]
            }
        },
        {
            "id": 9,
            "name": "حقيبة اليد",
            "slug": "handbag",
            "picture": "memoryGame/themes/clothing/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/clothing/sounds/handbag_ar.mp3",
                "fr": "memoryGame/themes/clothing/sounds/handbag_fr.mp3",
                "en": "memoryGame/themes/clothing/sounds/handbag_en.mp3"
            },
            "funFact": "حقيبة اليد تستخدم لحمل الأغراض.",
            "info": {
                "description": "حقيبة اليد هي نوع من الحقائب الصغيرة.",
                "bodyInfo": [
                    "تأتي بتصاميم وألوان متنوعة.",
                    "يمكن استخدامها يوميًا.",
                    "تعتبر من أكسسوارات الأزياء."
                ],
                "funFacts": [
                    "تستخدم حقائب اليد منذ العصور القديمة.",
                    "يمكن أن تكون مصنوعة من مواد متنوعة.",
                    "تعتبر جزءًا من إطلالة المرأة."
                ]
            }
        },
        {
            "id": 10,
            "name": "السروال القصير",
            "slug": "short-pants",
            "picture": "memoryGame/themes/clothing/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/clothing/sounds/short-pants_ar.mp3",
                "fr": "memoryGame/themes/clothing/sounds/short-pants_fr.mp3",
                "en": "memoryGame/themes/clothing/sounds/short-pants_en.mp3"
            },
            "funFact": "الشورت مريح ويُرتدى في الطقس الحار.",
            "info": {
                "description": "الشورت هو نوع من الملابس القصيرة يُرتدى عادة في الصيف.",
                "bodyInfo": [
                    "يأتي بأطوال وألوان متنوعة.",
                    "يمكن أن يكون مصنوعًا من القطن أو الجينز أو القماش الخفيف.",
                    "يُعتبر خيارًا مريحًا للأيام الحارة."
                ],
                "funFacts": [
                    "الشورت كان يُرتدى في الأصل فقط من قبل الأطفال.",
                    "أصبح الشورت جزءًا من الأزياء العالمية.",
                    "يستخدم الشورت في العديد من الرياضات."
                ]
            }
        }

    ]
};
