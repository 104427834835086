import React from "react";
import { Box, Typography, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import LangAudioPlayer from "./LangAudioPlayer";
import {getRandomSoftColor} from "../functions/helper";

const MemoryCard = ({ themeData, cardData }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { name: themeName } = themeData;
    const { name, picture, mp3, info , funFact } = cardData;

    return (
        <Grid
            container
            item
            sx={{
                padding: isMobile ? 2 : 5,
                backgroundColor: getRandomSoftColor(),
                borderRadius: "12px",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                marginBottom: 3,
                direction: "rtl", // Set direction to RTL
            }}
        >
            {/* Theme Title */}
            <Grid container item xs={12} justifyContent="center">

                <motion.Grid
                    container item xs={12}
                    initial={{opacity: 0, y: -20}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{duration: 0.8}}
                    viewport={{once: true}}
                >
                    <Stack direction="column" alignItems="center" spacing={2}>
                        <motion.img
                            src={"/memoryGame/logo@4x.png"}
                            style={{width : 150}}
                            initial={{scale: 0}}
                            animate={{scale: 1}}
                            transition={{
                                type: "spring", // Use spring for a bouncy effect
                                stiffness: 500, // Controls the "springiness" (higher = less bouncy)
                                damping: 10, // Controls the oscillation (lower = more bounces)
                                mass: 1, // Controls the weight of the spring
                            }}
                        />
                        <Typography
                            align="center"
                            variant={isMobile ? "h4" : "h3"}
                            color="primary"
                            sx={{
                                fontWeight: "bold",
                                fontSize: isMobile ? "1.2rem" : "2.0rem",
                                marginBottom: 2, // Add spacing below the theme title
                            }}
                        >
                            لعبة الذاكرة - محور {themeName}
                        </Typography>
                    </Stack>
                </motion.Grid>
            </Grid>

            {/* Card Title */}


            {/* Image */}
            <Grid container item xs={12} justifyContent="center">
                <Stack direction="column" alignItems="center" spacing={2}>

                    <Grid container item xs={12} justifyContent="center">
                        <motion.div
                            initial={{opacity: 0, scale: 0.8, rotateY: 0}} // Start with no horizontal rotation
                            whileInView={{opacity: 1, scale: 1, rotateY: 360}} // Spin horizontally to 360 degrees
                            transition={{delay: 0.3, duration: 0.7}}
                            viewport={{once: true}} // Only animate once when in view
                        >
                            <Stack spacing={2} alignItems="center" mt={4}>
                                <img
                                    style={{
                                        width: isMobile ? "180px" : "180px",
                                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)",
                                        borderRadius: "8px",
                                    }}
                                    src={`/${picture}`}
                                    alt={`${name} card`}
                                />
                            </Stack>
                        </motion.div>
                    </Grid>
                    <Grid container item xs={12}>
                        <motion.Grid
                            container item xs={12} justify="center"
                            initial={{opacity: 0, y: -20}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.8}}
                            viewport={{once: true}}
                        >
                            <Typography
                                align="center"
                                variant={isMobile ? "h4" : "h3"}
                                color="primary"
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: isMobile ? "1.8rem" : "2.5rem",
                                    marginBottom: 2, // Add spacing below the card title
                                }}
                            >
                                بطاقة {name}
                            </Typography>
                        </motion.Grid>
                    </Grid>
                </Stack>


            </Grid>

            {/* Audio Player */}
            <Grid item xs={12}>
                <LangAudioPlayer ar={mp3.ar} fr={mp3.fr} en={mp3.en}/>
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
                <motion.Grid
                    container
                    initial={{opacity: 0, y: 20}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{delay: 1.2, duration: 0.8}}
                    viewport={{once: true}}
                >
                    <Typography
                        variant={isMobile ? "h5" : "h4"}
                        sx={{
                            fontWeight: "bold",
                            color: "#f57f17",
                            fontSize: isMobile ? "1.5rem" : "2rem",
                            marginBottom: 2, // Add spacing below the description title
                        }}
                    >
                        {name} - {funFact}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: isMobile ? "1rem" : "1.2rem",
                            color: "#4e342e",
                            marginTop: 1,
                        }}
                    >
                        {info.description}
                    </Typography>
                </motion.Grid>
            </Grid>

            {/* Additional Info and Fun Facts */}
            <Grid item container spacing={2}>
                {/* Body Info */}
                <Grid item xs={12} md={6}>
                    <motion.Grid
                        container
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 1.2, duration: 0.7 }}
                        viewport={{ once: true }}
                    >
                        <Typography
                            variant={isMobile ? "h6" : "h5"}
                            sx={{
                                fontWeight: "bold",
                                color: "#388e3c",
                                fontSize: isMobile ? "1.3rem" : "1.5rem",
                                marginBottom: 2, // Add spacing below the body info title
                            }}
                        >
                            معلومات عن {name}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: isMobile ? "1rem" : "1.1rem",
                                color: "#6d4c41",
                                marginTop: 1,
                            }}
                        >
                            {info.bodyInfo.map((item, index) => (
                                <span key={index}>- {item}<br /></span>
                            ))}
                        </Typography>
                    </motion.Grid>
                </Grid>

                {/* Fun Facts */}
                <Grid item xs={12} md={6}>
                    <motion.Grid
                        container
                        initial={{ opacity: 0, x: 20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 1.4, duration: 0.7 }}
                        viewport={{ once: true }}
                    >
                        <Typography
                            variant={isMobile ? "h6" : "h5"}
                            sx={{
                                fontWeight: "bold",
                                color: "#388e3c",
                                fontSize: isMobile ? "1.3rem" : "1.5rem",
                                marginBottom: 2, // Add spacing below the fun facts title
                            }}
                        >
                            حقائق ممتعة عن {name}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: isMobile ? "1rem" : "1.1rem",
                                color: "#6d4c41",
                                marginTop: 1,
                            }}
                        >
                            {info.funFacts.map((fact, index) => (
                                <span key={index}>- {fact}<br /></span>
                            ))}
                        </Typography>
                    </motion.Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MemoryCard;
