import {Grid} from "@mui/material";
import VersoCard from "../VersoCard";
import AnimatedText from "../AnimatedVersoCardText";
import React from "react";

export default function QrCodeSection (){
    return(
        <Grid container item xs={12} alignItems="center">
            <Grid container item xs={12} alignItems={"center"} justifyContent={"center"}>
                <VersoCard src={"/memoryGame/themes/animals/cards/verso-card-01.webp"}/>
            </Grid>
            <Grid container item xs={12} justifyContent={"center"} justifyContent={"center"} mt={4}>
                <AnimatedText/>
            </Grid>
        </Grid>
    )
}