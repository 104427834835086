const environment = process.env.NODE_ENV || 'production'; // Determine environment

const configs = {
    development: {
        apiBaseUrl: 'http://localhost:3000/api',
        timeout: 5000,
    },
    production: {
        apiBaseUrl: 'https://your-production-api.com/api',
        timeout: 10000,
    },
};

const appConfig = configs[environment]; // Use config based on environment

export default appConfig;