import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import tn from "../src/locales/tn.json";
import ar from "../src/locales/ar.json";
import fr from "../src/locales/fr.json";



i18n
    .use(Backend)

    .use(LanguageDetector)

    .use(initReactI18next)

    .init({

        fallbackLng: "ar",
        // debug: true,
        lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "ar",
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        resources : {
            ar: {
                translation: ar
            },
            tn: {
                translation: tn
            },
            fr : {
                translation : fr
            }
        }
    });

export default i18n;
