import axios from "axios";
const devMode = false;



export const apiUrl = devMode ? "http://127.0.0.1:8000/" : "https://www.api.sghiri.tn/";

export const managerApiUrl = (url) => apiUrl + "manager" + url;
export const productsImgUrl = (imgName) => apiUrl + "/images/products/" + imgName;

const apiCall = (token) =>{
    return axios.create({
        baseURL: apiUrl + "manager",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        }
    });
}

export const noAuthApiCall = (url) => {
    return axios.create({
        baseURL : managerApiUrl(url),
        headers : {
            "Accept" : "application/json"
        }
    })
}

export const frontApiCall = (url) => {
    return  axios.create({
        baseURL : apiUrl + "front",
        headers : {
            "Accept" : "application/json"
        }
    })
}


export const commonApiCall = (url) => {
    return axios.create({
        baseURL : apiUrl  ,
        headers : {
            "Accept" : "application/json"
        }
    })
}

export default apiCall;
