export const colorsTheme = {
    "id": 162,
    "name": "الألوان",
    "slug": "colors",
    "description": "يتضمن مجموعة من الألوان.",
    "textures": {
        "right": "/memoryGame/themes/colors/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/colors/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/colors/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/colors/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/colors/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/colors/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "اللون الأحمر",
            "slug": "red",
            "picture": "memoryGame/themes/colors/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/colors/sounds/red_ar.mp3",
                "fr": "memoryGame/themes/colors/sounds/red_fr.mp3",
                "en": "memoryGame/themes/colors/sounds/red_en.mp3"
            },
            "funFact": "اللون الأحمر يُعتبر لون الحب والطاقة.",
            "info": {
                "description": "الأحمر هو لون قوي يُستخدم في التعبير عن العاطفة.",
                "bodyInfo": [
                    "الأحمر هو لون الدم والورود.",
                    "يستخدم الأحمر في إشارات التحذير.",
                    "يُعتبر الأحمر لونًا دافئًا."
                ],
                "funFacts": [
                    "في بعض الثقافات، يُعتبر الأحمر لون الحظ.",
                    "يمكن أن يُثير اللون الأحمر المشاعر القوية.",
                    "يستخدم الأحمر في العديد من الشعارات."
                ]
            }
        },
        {
            "id": 2,
            "name": "اللون الوردي",
            "slug": "pink",
            "picture": "memoryGame/themes/colors/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/colors/sounds/pink_ar.mp3",
                "fr": "memoryGame/themes/colors/sounds/pink_fr.mp3",
                "en": "memoryGame/themes/colors/sounds/pink_en.mp3"
            },
            "funFact": "اللون الوردي يُعتبر لون الرومانسية والنعومة.",
            "info": {
                "description": "الوردي هو لون يجمع بين الأحمر والأبيض.",
                "bodyInfo": [
                    "يستخدم اللون الوردي في تصميم الهدايا.",
                    "يُعتبر الوردي لونًا مريحًا.",
                    "الوردي هو لون الزهور."
                ],
                "funFacts": [
                    "يُعتبر الوردي رمزًا للحب والحنان.",
                    "يمكن أن يُستخدم الوردي في حفلات الزفاف.",
                    "يستخدم الوردي في العديد من المنتجات النسائية."
                ]
            }
        },
        {
            "id": 3,
            "name": "اللون الأخضر",
            "slug": "green",
            "picture": "memoryGame/themes/colors/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/colors/sounds/green_ar.mp3",
                "fr": "memoryGame/themes/colors/sounds/green_fr.mp3",
                "en": "memoryGame/themes/colors/sounds/green_en.mp3"
            },
            "funFact": "اللون الأخضر يُعتبر لون الطبيعة والهدوء.",
            "info": {
                "description": "الأخضر هو لون النباتات والأشجار.",
                "bodyInfo": [
                    "يستخدم الأخضر في تصميم البيئات الطبيعية.",
                    "الأخضر يُعتبر لونًا مريحًا.",
                    "يرتبط الأخضر بالنمو والتجديد."
                ],
                "funFacts": [
                    "يمثل الأخضر الصحة والحيوية.",
                    "يمكن أن يُستخدم الأخضر في تصميم المنتجات الصحية.",
                    "الأخضر موجود في العديد من الأطعمة الصحية."
                ]
            }
        },
        {
            "id": 4,
            "name": "اللون الأزرق",
            "slug": "blue",
            "picture": "memoryGame/themes/colors/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/colors/sounds/blue_ar.mp3",
                "fr": "memoryGame/themes/colors/sounds/blue_fr.mp3",
                "en": "memoryGame/themes/colors/sounds/blue_en.mp3"
            },
            "funFact": "اللون الأزرق يُعتبر لون السماء والماء.",
            "info": {
                "description": "الأزرق هو لون يُعبر عن الهدوء والسلام.",
                "bodyInfo": [
                    "يستخدم الأزرق في تصميم البيئات المائية.",
                    "الأزرق يُعتبر لونًا باردًا.",
                    "يرتبط الأزرق بالثقة والموثوقية."
                ],
                "funFacts": [
                    "يمثل الأزرق الاستقرار والهدوء.",
                    "يمكن أن يُستخدم الأزرق في الديكورات المنزلية.",
                    "الأزرق هو لون شائع في العلامات التجارية."
                ]
            }
        },
        {
            "id": 5,
            "name": "اللون الأصفر",
            "slug": "yellow",
            "picture": "memoryGame/themes/colors/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/colors/sounds/yellow_ar.mp3",
                "fr": "memoryGame/themes/colors/sounds/yellow_fr.mp3",
                "en": "memoryGame/themes/colors/sounds/yellow_en.mp3"
            },
            "funFact": "اللون الأصفر يُعتبر لون الشمس والفرح.",
            "info": {
                "description": "الأصفر هو لون يُعبر عن السعادة والتفاؤل.",
                "bodyInfo": [
                    "يستخدم الأصفر في تصميمات متعلقة بالطفولة.",
                    "الأصفر يُعتبر لونًا مشرقًا.",
                    "يرتبط الأصفر بالطاقة والنشاط."
                ],
                "funFacts": [
                    "يمكن أن يُثير الأصفر الإبداع.",
                    "يمثل الأصفر التفاؤل والبهجة.",
                    "يستخدم الأصفر في تحذيرات السلامة."
                ]
            }
        },
        {
            "id": 6,
            "name": "اللون الأبيض",
            "slug": "white",
            "picture": "memoryGame/themes/colors/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/colors/sounds/white_ar.mp3",
                "fr": "memoryGame/themes/colors/sounds/white_fr.mp3",
                "en": "memoryGame/themes/colors/sounds/white_en.mp3"
            },
            "funFact": "اللون الأبيض يُعتبر لون النقاء والبراءة.",
            "info": {
                "description": "الأبيض هو لون يُعبر عن النظافة والبساطة.",
                "bodyInfo": [
                    "يستخدم الأبيض في تصميمات الزفاف.",
                    "يمثل الأبيض البراءة والهدوء.",
                    "الأبيض يُستخدم في المستشفيات."
                ],
                "funFacts": [
                    "يمكن أن يُستخدم الأبيض في الديكور.",
                    "يمثل الأبيض النقاء والوضوح.",
                    "الأبيض هو لون شائع في الملابس."
                ]
            }
        },
        {
            "id": 7,
            "name": "اللون البنفسجي",
            "slug": "purple",
            "picture": "memoryGame/themes/colors/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/colors/sounds/purple_ar.mp3",
                "fr": "memoryGame/themes/colors/sounds/purple_fr.mp3",
                "en": "memoryGame/themes/colors/sounds/purple_en.mp3"
            },
            "funFact": "اللون البنفسجي يُعتبر لون الفخامة والإبداع.",
            "info": {
                "description": "البنفسجي هو لون يجمع بين الأزرق والأحمر.",
                "bodyInfo": [
                    "يستخدم البنفسجي في تصميمات فاخرة.",
                    "يرتبط البنفسجي بالإبداع والفن.",
                    "يمثل البنفسجي الغموض والسحر."
                ],
                "funFacts": [
                    "يمكن أن يُستخدم البنفسجي في الديكورات الفاخرة.",
                    "يمثل البنفسجي الخيال والإلهام.",
                    "يُعتبر البنفسجي رمزًا للملوك."
                ]
            }
        },
        {
            "id": 8,
            "name": "اللون البني",
            "slug": "brown",
            "picture": "memoryGame/themes/colors/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/colors/sounds/brown_ar.mp3",
                "fr": "memoryGame/themes/colors/sounds/brown_fr.mp3",
                "en": "memoryGame/themes/colors/sounds/brown_en.mp3"
            },
            "funFact": "اللون البني يُعتبر لون الأرض والطبيعة.",
            "info": {
                "description": "البني هو لون يُعبر عن الدفء والثقة.",
                "bodyInfo": [
                    "يستخدم البني في تصميمات الأثاث.",
                    "يمثل البني الاستقرار والراحة.",
                    "يُعتبر البني لونًا محايدًا."
                ],
                "funFacts": [
                    "يمكن أن يُستخدم البني في الأطعمة.",
                    "يمثل البني الطبيعة والأرض.",
                    "يستخدم البني في الفنون."
                ]
            }
        },
        {
            "id": 9,
            "name": "اللون الأسود",
            "slug": "black",
            "picture": "memoryGame/themes/colors/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/colors/sounds/black_ar.mp3",
                "fr": "memoryGame/themes/colors/sounds/black_fr.mp3",
                "en": "memoryGame/themes/colors/sounds/black_en.mp3"
            },
            "funFact": "اللون الأسود يُعتبر لون الأناقة والغموض.",
            "info": {
                "description": "الأسود هو لون يُعبر عن القوة والفخامة.",
                "bodyInfo": [
                    "يستخدم الأسود في تصميمات الأزياء.",
                    "يمثل الأسود الغموض والأناقة.",
                    "يُعتبر الأسود لونًا رسميًا."
                ],
                "funFacts": [
                    "يمكن أن يُستخدم الأسود في التصميمات العصرية.",
                    "يمثل الأسود القوة والسيطرة.",
                    "يستخدم الأسود في العديد من العلامات التجارية."
                ]
            }
        },
        {
            "id": 10,
            "name": "اللون البرتقالي",
            "slug": "orange",
            "picture": "memoryGame/themes/colors/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/colors/sounds/orange_ar.mp3",
                "fr": "memoryGame/themes/colors/sounds/orange_fr.mp3",
                "en": "memoryGame/themes/colors/sounds/orange_en.mp3"
            },
            "funFact": "اللون البرتقالي يُعتبر لون الحماس والطاقة.",
            "info": {
                "description": "البرتقالي هو لون يعبر عن النشاط والحيوية.",
                "bodyInfo": [
                    "يستخدم البرتقالي في تصاميم التسويق لجذب الانتباه.",
                    "يمثل البرتقالي التفاؤل والإبداع.",
                    "يُعتبر البرتقالي لونًا مشرقًا وحيويًا."
                ],
                "funFacts": [
                    "يُستخدم البرتقالي في إشارات المرور للتنبيه.",
                    "يمثل البرتقالي الطاقة والحركة.",
                    "يُعتبر البرتقالي لونًا محببًا في تصاميم الواجهة العصرية."
                ]
            }
        }
    ]
};
