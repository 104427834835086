import React from "react";
import { Box, Typography, Button, Grid, Container } from "@mui/material";
import { motion } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CelebrationIcon from "@mui/icons-material/Celebration";
import SchoolIcon from "@mui/icons-material/School";
import OrderSingleProduct from "../Component/OrderSingleProduct";
import {Helmet} from "react-helmet";
import { FacebookProvider, Page } from 'react-facebook';
const images = [
    "/calendar/1.jpg",
    "/calendar/2.jpg",
    "/calendar/3.jpg",
    "/calendar/4.jpg",
    "/calendar/5.jpg",
    "/calendar/6.jpg",
    "/calendar/7.jpg",
    "/calendar/8.jpg",
    "/calendar/9.jpg",
    "/calendar/10.jpg",
    "/calendar/11.jpg",
    "/calendar/12.jpg",
];

const sectionImages = [
    "/calendar/8.jpg", // Feature 1 image
    "/calendar/13.jpg", // Feature 2 image
    "/calendar/7.jpg", // Feature 3 image
];

const Calendar2025Price = () => {
    return (
        <Box>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>يومية صغيري 2025</title>
                <link rel="canonical" href="https://www.sghiri.tn/calendrier-2025"/>
                <meta property="og:image"
                      content="/calendar/1.jpg"/>

            </Helmet>
            {/* Header Section */}
            <Box
                sx={{
                    py: 4,
                    textAlign: "center",
                }}
            >
                <motion.img
                    src="/calendar/logo.png"
                    alt="Logo"
                    style={{ width: 200 }}
                    initial={{ y: -100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 500,
                        damping: 20,
                        duration: 0.5,
                        bounce: 0.5,
                    }}
                />
                <Typography
                    variant="h5"
                    component={motion.h1}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    sx={{ mt: 2 }}
                >
                    فرصة رائعة لطفلك لتعلّم شيء جديد كلّ يوم
                </Typography>
            </Box>

            {/* Carousel Section */}
            <Box
                sx={{
                    my: 5,
                    mx: "auto",
                    maxWidth: 300,
                    borderRadius: 2,
                    overflow: "hidden",
                }}
            >
                <Carousel
                    autoPlay
                    infiniteLoop
                    interval={2000}
                    showThumbs={false}
                    showStatus={false}
                    emulateTouch={true}
                    swipeable={false}
                >
                    {images.map((src, index) => (
                        <Box key={index} sx={{position: "relative"}}>
                            <img
                                src={src}
                                alt={`Planner ${index + 1}`}
                                style={{width: "100%", borderRadius: 4}}
                            />
                        </Box>
                    ))}
                </Carousel>

                <div style={{maxWidth: '500px', margin: '0 auto'}}>
                    <h2>Customer Reviews</h2>
                    <FacebookProvider appId="122390769758025">
                        <Page
                            href="https://www.facebook.com/sghiri.tn"
                            tabs="reviews"
                            width="500"
                        />
                    </FacebookProvider>
                </div>
            </Box>

            {/* Features Section */}
            <Container sx={{my: 5}} id="features">
                <Grid container spacing={4} justifyContent="center" alignItems="center">
                    {/* Feature 1 */}
                    <Grid item xs={12} md={4} spacing={2}>
                    <motion.div
                            initial={{ scale: 0.8, opacity: 0 }}
                            whileInView={{ scale: 1, opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            viewport={{ once: true }}
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <CalendarMonthIcon
                                sx={{ fontSize: 80, color: "#1E90FF", mb: 2 }}
                            />
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                📅 محتوى غني ومميز
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                يشمل التواريخ، الأيام، الفصول باللغات العربية، الفرنسية،
                                والإنجليزية.
                            </Typography>
                            <img
                                src={sectionImages[0]}
                                alt="Calendar Demo"
                                style={{
                                    width: "100%",
                                    maxWidth: "300px",
                                    borderRadius: 8,
                                    margin: "0 auto",
                                }}
                            />
                        </motion.div>
                    </Grid>
                    {/* Feature 2 */}
                    <Grid item xs={12} md={4}>
                        <motion.div
                            initial={{ scale: 0.8, opacity: 0 }}
                            whileInView={{ scale: 1, opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            viewport={{ once: true }}
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <CelebrationIcon sx={{ fontSize: 80, color: "#FF6347", mb: 2 }} />
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                🎉 الأعياد والمناسبات
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                جميع الأعياد الإسلامية والوطنية لتسهيل التخطيط.
                            </Typography>
                            <img
                                src={sectionImages[1]}
                                alt="Celebration Demo"
                                style={{
                                    width: "100%",
                                    maxWidth: "300px",
                                    borderRadius: 8,
                                    margin: "0 auto",
                                }}
                            />
                        </motion.div>
                    </Grid>
                    {/* Feature 3 */}
                    <Grid item xs={12} md={4}>
                        <motion.div
                            initial={{ scale: 0.8, opacity: 0 }}
                            whileInView={{ scale: 1, opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            viewport={{ once: true }}
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <SchoolIcon sx={{ fontSize: 80, color: "#FFA500", mb: 2 }} />
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                📖 تعليم للصغار
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                يساعد الأطفال على فهم الشهور، الأيام، ومتابعة تغيّر الفصول.
                            </Typography>
                            <img
                                src={sectionImages[2]}
                                alt="Learning Demo"
                                style={{
                                    width: "100%",
                                    maxWidth: "300px",
                                    borderRadius: 8,
                                    margin: "0 auto",
                                }}
                            />
                        </motion.div>
                    </Grid>
                </Grid>
            </Container>

            {/* Call to Action Section */}
            <Box
                sx={{
                    textAlign: "center",
                    py: 5,
                    mt: 5,
                }}
            >
                <Typography dir={"rtl"} variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
                     احصل على يوميّتك الآن بـ 28 دينار فقط!
                </Typography>
                <Typography dir={"rtl"} variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
                    واصلة لباب الدار
                </Typography>
                <OrderSingleProduct allPrice />

            </Box>
        </Box>
    );
};

export default Calendar2025Price;
