import {Button, Grid, Typography} from "@mui/material";
import MemoryCard from "../card";
import React, {useRef} from "react";

export default function QrCodeWebsiteExample({randomTheme,randomCard, handleButton ,cardRef}){


    return(
        <Grid container item xs={12} mt={2}>
            <Grid container item xs={12} justifyContent={"center"}>
                <Typography align={"center"} color={"primary"} variant={"h3"}>بطاقات تفاعلية</Typography>
            </Grid>
            <Grid container item xs={12} justifyContent={"center"} mt={5}>
                <Typography align={"center"}  variant={"body1"}>
                    يمكن لطفلك بإستخدام الهادف الولوج إلى موقغ البطاقة حيث يجد بها طريقة النطق بــ 3 لغات مختلفة
                    و معلومات عن محتوى البطاقة
                </Typography>
            </Grid>

            <Grid
                container
                item
                xs={12}
                justifyContent={"center"}
                mt={5}
                sx={{
                    border: "2px solid gray",
                }}
                dir={"rtl"}
                ref={cardRef}
            >
                {randomTheme && randomCard && (
                    <MemoryCard themeData={randomTheme} cardData={randomCard} />
                )}
            </Grid>
            <Grid container item xs={12} justifyContent={"center"} mt={3}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleButton}
                    sx={{
                        fontSize: "1.2rem",
                        backgroundColor: "#f57f17",
                        '&:hover': {
                            backgroundColor: '#ffb300',
                        },
                    }}
                >
                    رؤية مثال آخر
                </Button>
            </Grid>
        </Grid>
    )
}