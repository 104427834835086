import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { motion } from 'framer-motion';

// Number of cards will be based on the length of the theme.cards array
const CardStack = ({ theme }) => {
    const [scrollProgress, setScrollProgress] = useState(0);
    const cardCount = theme.cards.length; // Dynamic card count based on theme

    // Handle scroll event
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;
            const maxScroll = document.documentElement.scrollHeight - windowHeight;
            const scrollPercentage = Math.min(scrollPosition / maxScroll, 1); // Range: 0 to 1
            setScrollProgress(scrollPercentage);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Calculate the rotation and positioning for each card
    const getCardTransform = (index) => {
        const totalAngle = 120; // Half-circle (180 degrees)
        const anglePerCard = totalAngle / (cardCount - 1); // Distribute angles equally
        const angle = index * anglePerCard - totalAngle / 2; // Calculate each card's angle around the center

        const radius = 150; // Radius of the semi-circle
        const translateX = radius * Math.cos((angle * Math.PI) / 20); // X position in the semi-circle
        const translateY = -radius * Math.sin((angle * Math.PI) / 20); // Y position in the semi-circle (negative for upward arc)

        return { translateX, translateY, angle };
    };

    function isOdd(number) {
        return number % 2 !== 0;
    }

    return (
        <Grid
            container
            item
            xs={12}
            sx={{
                minHeight: 400,
            }}
            justifyContent="center"
        >
            {theme.cards.map((card, index) => {
                const { translateX, translateY, angle } = getCardTransform(index);
                return (
                    <motion.img
                        key={card.id}
                        src={"/" + card.picture} // Use the dynamic picture URL from the theme
                        alt={card.name}
                        style={{
                            position: 'absolute',
                            height: 150,
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                        }}
                        initial={{ x : 0 , y : 0 , scale: 1 , boxShadow : "0px 4px 10px rgba(0, 0, 0, 0.1)" }} // Initial state
                        whileInView={{
                            x : index === 0 ? 150 - 30 : isOdd(index) ? -150 + 30 * index : 150 - 35 * index,
                            y : isOdd(index) ? 200 : 0,
                            boxShadow : "0px 4px 10px rgba(0, 0, 0, 0.3)"
                        }}
                        transition={{ duration: 0.5, delay: 0.5 }} // Animation duration
                        whileHover={{ scale: 1.2, zIndex: 9999, transition: { duration: 0.2, delay: 0.2 } }}
                        viewport={{ once: true }}
                    />
                );
            })}
        </Grid>
    );
};

export default CardStack;
