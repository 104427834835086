// Layout.js
import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Box, Grid, Button } from '@mui/material';
import FloatingCart from "../Component/common/FloatingCart";
import {Helmet} from "react-helmet";

const Layout = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Header */}
            <Helmet>
                <style>
                    @import
                    url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&family=Bungee&family=Fleur+De+Leah&family=Mali:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
                </style>
            </Helmet>
            <AppBar position="static" sx={{ backgroundColor: 'primary.main', height: 100, justifyContent: 'center' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Logo */}
                    <Typography
                        variant="h6"
                        component={Link}
                        to="/"
                        sx={{
                            fontSize: { xs: '1rem', sm: '1.5rem', md: '2rem' },
                            display: 'flex',
                            alignItems: 'center',
                            color: 'white',
                            textDecoration: 'none'
                        }}
                    >
                        <img src={"/logo.png"} height={90} alt="logo" />
                    </Typography>

                    {/* Website status and navigation */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography
                            variant="body1"
                            color="white"
                            sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' }, textAlign: 'center' }}
                        >
                            Notre site est en construction ! Découvrez notre calendrier pour 2025.
                        </Typography>
                        <Button
                            component={Link}
                            to="/calendrier-2025"
                            variant="outlined"
                            color="inherit"
                            sx={{
                                fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                                textTransform: 'none',
                                borderColor: 'white',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'white',
                                    color: 'red'
                                }
                            }}
                        >
                            Voir Calendrier 2025
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Main content area */}
            <Container
                component="main"
                maxWidth="lg"
                sx={{ flexGrow: 1, py: 2, px: { xs: 2, sm: 3, md: 4 } }} // Responsive padding
            >
                <Grid container item xs={12}>
                    <Outlet />
                </Grid>
            </Container>

            {/* Footer */}
            <Box
                component="footer"
                sx={{
                    py: 2,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
                    textAlign: 'center', // Center-align text for better responsiveness
                }}
            >
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' }, // Responsive font size
                    }}
                >
                    © 2025 Sghiri.tn. All rights reserved.
                </Typography>
            </Box>
        </Box>
    );
};

export default Layout;
