import React from "react";
import { motion } from "framer-motion";
import { Grid, Box, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PsychologyIcon from "@mui/icons-material/Psychology";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";

const AnimatedTypography = () => {
    // Animation variants
    const itemVariant = {
        hidden: { opacity: 0, y: 100 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: { delay: i * 0.3, duration: 0.6 },
        }),
    };

    // Lines data
    const lines = [
        {
            icon: <AccessTimeIcon fontSize="large" />,
            text: "أوقات الصلاة / شروق الشمس و الإمساك",
            color: "#E57373",
        },
        {
            icon: <CalendarMonthIcon fontSize="large" />,
            text: "الأيّام ، الأشهر و الفصول بــ 3 لغات ( عربية ، فرنسية و إنجلزية)",
            color: "#81C784",
        },
        {
            icon: <MenuBookIcon fontSize="large" />,
            text: "أحاديث نبوية و تربية دينية",
            color: "#64B5F6",
        },
        {
            icon: <PsychologyIcon fontSize="large" />,
            text: "معلومات و ثقافة عامة",
            color: "#FFD54F",
        },
        {
            icon: <SportsEsportsIcon fontSize="large" />,
            text: "ألعاب ذكاء، ألغاز...",
            color: "#BA68C8",
        },
    ];

    return (
        <Box
            sx={{
                padding: 4,
                backgroundColor: "#f5f5f5",
                borderRadius: 2,
                overflow: "hidden",
            }}
        >
            {/* Title Section */}
            <Box
                sx={{
                    marginBottom: 4,
                    textAlign: "center",
                }}
            >
                <Typography
                    variant="h3"
                    dir={'rtl'}
                    sx={{
                        fontSize: { xs: "1.8rem", md: "2.5rem" },
                        fontWeight: "700",
                        color: "#333",
                        marginBottom: "0.5rem",
                    }}
                >
                    365 يوم تحتوي كلّ منها على
                </Typography>
            </Box>

            {/* Grid Section */}
            <Grid container spacing={3} alignItems="center">
                {lines.map((line, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <motion.div
                            variants={itemVariant}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: false, amount: 0.2 }}
                            custom={index}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "0.5rem",
                                background: "#ffffff",
                                borderRadius: "12px",
                                padding: "2rem",
                                boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                                textAlign: "center",
                                fontSize: "1.5rem",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50%",
                                    backgroundColor: "#f9f9f9",
                                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                    color: line.color,
                                }}
                            >
                                {line.icon}
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: "1.25rem",
                                    fontWeight: "500",
                                    textAlign: "center",
                                    lineHeight: 1.8,
                                    color: "#333",
                                }}
                            >
                                {line.text}
                            </Typography>
                        </motion.div>
                    </Grid>
                ))}

                {/* Title Section */}
                <Box
                    sx={{
                        marginTop: 4,
                        textAlign: "center",
                    }}
                >
                    <Typography
                        variant="h3"
                        dir={'rtl'}
                        sx={{
                            fontSize: { xs: "1.8rem", md: "2.5rem" },
                            fontWeight: "700",
                            color: "#333",
                            marginBottom: "0.5rem",
                        }}
                    >
                        و المزيد ، في رحلة ترافق طفلك سنة كاملة !
                    </Typography>
                </Box>
            </Grid>
        </Box>
    );
};

export default AnimatedTypography;
