import React, { useState, useRef } from 'react';
import { Grid, IconButton } from '@mui/material';
import { PlayCircle, PauseCircle } from '@mui/icons-material';
import { motion } from 'framer-motion';

const LangAudioPlayer = ({ ar, fr, en }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentAudio, setCurrentAudio] = useState(null);
    const audioRef = useRef(null);

    // Function to ensure URLs start with a "/"
    const ensureSlash = (url) => {
        if (!url.startsWith('/')) {
            return '/' + url;
        }
        return url;
    };

    // Determine the icon color based on the background image (flag)
    const getIconColor = (audioFile) => {
        if (audioFile === ar) return 'black'; // Color for Arabic (Tunisia flag: red)
        if (audioFile === fr) return 'darkblue'; // Color for French (France flag: blue)
        if (audioFile === en) return 'white'; // Color for English (UK flag: dark blue)
        return 'black';
    };

    const handlePlayPause = (audioFile) => {
        if (currentAudio && currentAudio !== audioFile) {
            audioRef.current.pause(); // Pause any other audio playing
            audioRef.current.src = audioFile;
            setIsPlaying(false);
        }

        if (isPlaying && currentAudio === audioFile) {
            audioRef.current.pause();
        } else {
            audioRef.current.src = audioFile;
            audioRef.current.play();
        }

        setIsPlaying(!isPlaying);
        setCurrentAudio(audioFile);
    };

    return (
        <Grid container item justifyContent="center" alignItems="center" mt={10} mb={10}>
            {/* Arabic Box */}
            <Grid item xs={12} sm={4} sx={{ mb: 2 }}> {/* Margin bottom for spacing */}
                <motion.div
                    initial={{ opacity: 0, y: 50 }} // Start hidden and below
                    animate={{ opacity: 1, y: 0 }} // Slide up to visible
                    transition={{ duration: 0.5, delay: 0.2 }} // Delay for staggered effect
                    style={{ ...styles.box, backgroundImage: 'url(https://www.countryflags.com/wp-content/uploads/tunisia-flag-png-large.png)' }}
                >
                    <IconButton onClick={() => handlePlayPause(ensureSlash(ar))} style={styles.button}>
                        {isPlaying && currentAudio === ensureSlash(ar)
                            ? <PauseCircle style={{ ...styles.icon, color: getIconColor(ar) }} />
                            : <PlayCircle style={{ ...styles.icon, color: getIconColor(ar) }} />}
                    </IconButton>
                </motion.div>
            </Grid>

            {/* French Box */}
            <Grid item xs={12} sm={4} sx={{ mb: 2 }}> {/* Margin bottom for spacing */}
                <motion.div
                    initial={{ opacity: 0, y: 50 }} // Start hidden and below
                    animate={{ opacity: 1, y: 0 }} // Slide up to visible
                    transition={{ duration: 0.5, delay: 0.4 }} // Delay for staggered effect
                    style={{ ...styles.box, backgroundImage: 'url(https://www.countryflags.com/wp-content/uploads/france-flag-png-large.png)' , backgroundSize : "contain" }}
                >
                    <IconButton onClick={() => handlePlayPause(ensureSlash(fr))} style={styles.button}>
                        {isPlaying && currentAudio === ensureSlash(fr)
                            ? <PauseCircle style={{ ...styles.icon, color: getIconColor(fr) }} />
                            : <PlayCircle style={{ ...styles.icon, color: getIconColor(fr) }} />}
                    </IconButton>
                </motion.div>
            </Grid>

            {/* English Box */}
            <Grid item xs={12} sm={4} sx={{ mb: 2 }}> {/* Margin bottom for spacing */}
                <motion.div
                    initial={{ opacity: 0, y: 50 }} // Start hidden and below
                    animate={{ opacity: 1, y: 0 }} // Slide up to visible
                    transition={{ duration: 0.5, delay: 0.6 }} // Delay for staggered effect
                    style={{ ...styles.box, backgroundImage: 'url(https://www.countryflags.com/wp-content/uploads/united-kingdom-flag-png-large.png)' }}
                >
                    <IconButton onClick={() => handlePlayPause(ensureSlash(en))} style={styles.button}>
                        {isPlaying && currentAudio === ensureSlash(en)
                            ? <PauseCircle style={{ ...styles.icon, color: getIconColor(en) }} />
                            : <PlayCircle style={{ ...styles.icon, color: getIconColor(en) }} />}
                    </IconButton>
                </motion.div>
            </Grid>

            {/* Audio element */}
            <audio ref={audioRef} onEnded={() => setIsPlaying(false)}>
                Your browser does not support the audio element.
            </audio>
        </Grid>
    );
};

const styles = {
    box: {
        width: '100%',
        height: '200px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        border: "white solid 5px",
    },
    button: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    icon: {
        fontSize: '60px',
    },
};

export default LangAudioPlayer;
