import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { motion } from 'framer-motion';

const UnderConstruction = () => {
    return (
        <Box
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.8 }}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%',
                height: '100vh',
                padding: 2,
            }}
        >
            <img style={{ maxWidth : "85%" , marginBottom : 20}} src={"/underConstruction.png"} alt="under-construction"   />
            <Typography
                component={motion.h1}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.3 }}
                sx={{
                    fontSize: { xs: '2rem', md: '4rem' },
                    fontWeight: 'bold',
                    color: '#333',
                }}
            >
                Site en construction
            </Typography>

            <Typography
                component={motion.p}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.5 }}
                sx={{
                    fontSize: { xs: '1rem', md: '1.5rem' },
                    color: '#666',
                    marginY: 2,
                }}
            >
                Nous travaillons dur pour vous offrir une meilleure expérience. Revenez bientôt !
            </Typography>

        </Box>
    );
};

export default UnderConstruction;
