export const vegetablesTheme = {
    "id": 164,
    "name": "الخضروات",
    "slug": "vegetables",
    "description": "يضم مجموعة من الخضروات المختلفة.",
    "textures": {
        "right": "/memoryGame/themes/vegetables/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/vegetables/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/vegetables/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/vegetables/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/vegetables/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/vegetables/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "الثوم",
            "slug": "garlic",
            "picture": "memoryGame/themes/vegetables/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/vegetables/sounds/garlic_ar.mp3",
                "fr": "memoryGame/themes/vegetables/sounds/garlic_fr.mp3",
                "en": "memoryGame/themes/vegetables/sounds/garlic_en.mp3"
            },
            "funFact": "الثوم يُستخدم في العديد من الأطباق حول العالم.",
            "info": {
                "description": "الثوم هو نبات يُستخدم كتوابل ويعتبر مفيدًا للصحة.",
                "bodyInfo": [
                    "يحتوي الثوم على مركبات تعمل كمضادات للأكسدة.",
                    "يمكن استخدامه في العديد من الأطباق.",
                    "يساعد في تعزيز المناعة."
                ],
                "funFacts": [
                    "كان الثوم يُستخدم في الطب الشعبي لآلاف السنين.",
                    "يمكن أن يساعد في خفض ضغط الدم.",
                    "يمتاز برائحته القوية."
                ]
            }
        },
        {
            "id": 2,
            "name": "الفاصوليا",
            "slug": "beans",
            "picture": "memoryGame/themes/vegetables/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/vegetables/sounds/beans_ar.mp3",
                "fr": "memoryGame/themes/vegetables/sounds/beans_fr.mp3",
                "en": "memoryGame/themes/vegetables/sounds/beans_en.mp3"
            },
            "funFact": "الفاصوليا مصدر جيد للبروتين.",
            "info": {
                "description": "الفاصوليا هي نوع من الخضروات الغنية بالبروتينات.",
                "bodyInfo": [
                    "تعتبر مصدرًا جيدًا للألياف.",
                    "تساعد في تحسين الهضم.",
                    "تحتوي على العديد من الفيتامينات والمعادن."
                ],
                "funFacts": [
                    "تستخدم الفاصوليا في العديد من الأطباق التقليدية.",
                    "يمكن أن تساعد في تنظيم مستوى السكر في الدم.",
                    "تأتي الفاصوليا بعدة ألوان، بما في ذلك الأخضر والأحمر."
                ]
            }
        },
        {
            "id": 3,
            "name": "البصل",
            "slug": "onion",
            "picture": "memoryGame/themes/vegetables/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/vegetables/sounds/onion_ar.mp3",
                "fr": "memoryGame/themes/vegetables/sounds/onion_fr.mp3",
                "en": "memoryGame/themes/vegetables/sounds/onion_en.mp3"
            },
            "funFact": "البصل يحتوي على مضادات الأكسدة.",
            "info": {
                "description": "البصل هو نبات شائع يُستخدم في الطهي.",
                "bodyInfo": [
                    "يتميز بنكهته القوية.",
                    "يمكن تناوله نيئًا أو مطبوخًا.",
                    "يحتوي على الفيتامينات والمعادن."
                ],
                "funFacts": [
                    "قد يسبب البصل الدموع عند تقطيعه.",
                    "يحتوي على مركبات مفيدة لصحة القلب.",
                    "يمكن أن يساعد في تقوية المناعة."
                ]
            }
        },
        {
            "id": 4,
            "name": "الخيار",
            "slug": "cucumber",
            "picture": "memoryGame/themes/vegetables/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/vegetables/sounds/cucumber_ar.mp3",
                "fr": "memoryGame/themes/vegetables/sounds/cucumber_fr.mp3",
                "en": "memoryGame/themes/vegetables/sounds/cucumber_en.mp3"
            },
            "funFact": "الخيار يحتوي على نسبة عالية من الماء.",
            "info": {
                "description": "الخيار هو خضار طازج ومليء بالماء.",
                "bodyInfo": [
                    "يعتبر خيارًا صحيًا للوجبات الخفيفة.",
                    "يمكن تناوله طازجًا أو مخللًا.",
                    "يعزز الترطيب."
                ],
                "funFacts": [
                    "يحتوي الخيار على فيتامين K.",
                    "يستخدم في تحضير السلطة.",
                    "يمكن أن يساعد في تحسين صحة الجلد."
                ]
            }
        },
        {
            "id": 5,
            "name": "الباذنجان",
            "slug": "eggplant",
            "picture": "memoryGame/themes/vegetables/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/vegetables/sounds/eggplant_ar.mp3",
                "fr": "memoryGame/themes/vegetables/sounds/eggplant_fr.mp3",
                "en": "memoryGame/themes/vegetables/sounds/eggplant_en.mp3"
            },
            "funFact": "الباذنجان غني بالألياف.",
            "info": {
                "description": "الباذنجان هو نبات يتميز بلونه الأرجواني.",
                "bodyInfo": [
                    "يمكن طهيه بعدة طرق.",
                    "يحتوي على مضادات الأكسدة.",
                    "يعتبر منخفض السعرات الحرارية."
                ],
                "funFacts": [
                    "يمكن استخدام الباذنجان في الأطباق المشوية.",
                    "يحتوي على البوتاسيوم.",
                    "يعتبر مصدرًا جيدًا للألياف."
                ]
            }
        },
        {
            "id": 6,
            "name": "الطماطم",
            "slug": "tomato",
            "picture": "memoryGame/themes/vegetables/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/vegetables/sounds/tomato_ar.mp3",
                "fr": "memoryGame/themes/vegetables/sounds/tomato_fr.mp3",
                "en": "memoryGame/themes/vegetables/sounds/tomato_en.mp3"
            },
            "funFact": "الطماطم تُعتبر فاكهة لكن تُستخدم كخضار.",
            "info": {
                "description": "الطماطم هي خضار شائعة تُستخدم في العديد من الأطباق.",
                "bodyInfo": [
                    "تعتبر مصدرًا غنيًا بالفيتامين C.",
                    "يمكن تناولها طازجة أو مطبوخة.",
                    "تحتوي على مضادات الأكسدة."
                ],
                "funFacts": [
                    "تعتبر الطماطم أساسًا للعديد من الصلصات.",
                    "يمكن أن تساعد في تعزيز صحة القلب.",
                    "تحتوي على الليكوبين، وهو مضاد أكسدة قوي."
                ]
            }
        },
        {
            "id": 7,
            "name": "الجزر",
            "slug": "carrot",
            "picture": "memoryGame/themes/vegetables/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/vegetables/sounds/carrot_ar.mp3",
                "fr": "memoryGame/themes/vegetables/sounds/carrot_fr.mp3",
                "en": "memoryGame/themes/vegetables/sounds/carrot_en.mp3"
            },
            "funFact": "الجزر يُعتبر مفيدًا لصحة العين.",
            "info": {
                "description": "الجزر هو خضار غني بالفيتامين A.",
                "bodyInfo": [
                    "يمكن تناوله نيئًا أو مطبوخًا.",
                    "يحتوي على الألياف.",
                    "يعتبر مفيدًا للجلد."
                ],
                "funFacts": [
                    "يستطيع الجزر تحسين الرؤية.",
                    "يمكن أن يساعد في تعزيز المناعة.",
                    "يحتوي الجزر على العديد من الفيتامينات."
                ]
            }
        },
        {
            "id": 8,
            "name": "البطاطا",
            "slug": "potato",
            "picture": "memoryGame/themes/vegetables/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/vegetables/sounds/potato_ar.mp3",
                "fr": "memoryGame/themes/vegetables/sounds/potato_fr.mp3",
                "en": "memoryGame/themes/vegetables/sounds/potato_en.mp3"
            },
            "funFact": "البطاطا تُعتبر من الأطعمة الأساسية في العديد من الثقافات.",
            "info": {
                "description": "البطاطا هي خضار نشوية تُستخدم في العديد من الأطباق.",
                "bodyInfo": [
                    "تحتوي على الكربوهيدرات.",
                    "يمكن تحضيرها بطرق مختلفة.",
                    "غنية بالفيتامين C."
                ],
                "funFacts": [
                    "يمكن أن تساعد البطاطا في تعزيز الطاقة.",
                    "تعتبر مصدرًا جيدًا للألياف.",
                    "تُستخدم في الأطباق المقلية والمسلوقة."
                ]
            }
        },
        {
            "id": 9,
            "name": "الفلفل",
            "slug": "pepper",
            "picture": "memoryGame/themes/vegetables/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/vegetables/sounds/pepper_ar.mp3",
                "fr": "memoryGame/themes/vegetables/sounds/pepper_fr.mp3",
                "en": "memoryGame/themes/vegetables/sounds/pepper_en.mp3"
            },
            "funFact": "الفلفل يحتوي على نسبة عالية من فيتامين C.",
            "info": {
                "description": "الفلفل هو نبات ملون يُستخدم في الطهي.",
                "bodyInfo": [
                    "يعتبر مصدرًا جيدًا للفيتامينات.",
                    "يمكن تناوله نيئًا أو مطبوخًا.",
                    "يمتاز بنكهته الحارة."
                ],
                "funFacts": [
                    "تأتي الفلفل بألوان متعددة مثل الأحمر والأخضر.",
                    "يحتوي على مضادات الأكسدة.",
                    "يمكن أن يساعد في تحسين عملية الأيض."
                ]
            }
        },
        {
            "id": 10,
            "name": "البروكلي",
            "slug": "broccoli",
            "picture": "memoryGame/themes/vegetables/cards/card-10.webp",
            "mp3": {
                "ar": "memoryGame/themes/vegetables/sounds/broccoli_ar.mp3",
                "fr": "memoryGame/themes/vegetables/sounds/broccoli_fr.mp3",
                "en": "memoryGame/themes/vegetables/sounds/broccoli_en.mp3"
            },
            "funFact": "البروكلي يعتبر من الخضروات المفيدة جدًا.",
            "info": {
                "description": "البروكلي هو خضار ينتمي لعائلة الكرنب.",
                "bodyInfo": [
                    "يحتوي على العديد من الفيتامينات.",
                    "يعتبر مصدرًا جيدًا للألياف.",
                    "يمكن تناوله مطبوخًا أو نيئًا."
                ],
                "funFacts": [
                    "يمكن أن يساعد البروكلي في تعزيز الصحة العامة.",
                    "يحتوي على مركبات مضادة للسرطان.",
                    "يعتبر مفيدًا لصحة القلب."
                ]
            }
        }
    ]
};
