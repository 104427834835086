export const transportationTheme = {
    "id": 171,
    "name": "وسائل النقل",
    "slug": "transportation",
    "description": "تستعرض وسائل النقل المختلفة المستخدمة في حياتنا.",
    "textures": {
        "right": "/memoryGame/themes/transportation/textures/sides/texture_right.webp",
        "left": "/memoryGame/themes/transportation/textures/sides/texture_left.webp",
        "top": "/memoryGame/themes/transportation/textures/sides/texture_top.webp",
        "bottom": "/memoryGame/themes/transportation/textures/sides/texture_bottom.webp",
        "front": "/memoryGame/themes/transportation/textures/sides/texture_front.webp",
        "back": "/memoryGame/themes/transportation/textures/sides/texture_back.webp"
    },
    "cards": [
        {
            "id": 1,
            "name": "القطار",
            "slug": "train",
            "picture": "memoryGame/themes/transportation/cards/card-01.webp",
            "mp3": {
                "ar": "memoryGame/themes/transportation/sounds/train_ar.mp3",
                "fr": "memoryGame/themes/transportation/sounds/train_fr.mp3",
                "en": "memoryGame/themes/transportation/sounds/train_en.mp3"
            },
            "funFact": "القطارات تسير على السكك الحديدية.",
            "info": {
                "description": "القطار هو وسيلة نقل سريعة وفعالة.",
                "bodyInfo": [
                    "يستخدم لنقل الركاب والبضائع.",
                    "توجد أنواع مختلفة من القطارات مثل السريعة والمعلقة.",
                    "يعمل بواسطة الكهرباء أو البخار."
                ],
                "funFacts": [
                    "تعتبر القطارات من أكثر وسائل النقل أمانًا.",
                    "تساعد في تقليل الازدحام على الطرق.",
                    "تربط بين المدن والبلدان بسهولة."
                ]
            }
        },
        {
            "id": 2,
            "name": "السفينة",
            "slug": "ship",
            "picture": "memoryGame/themes/transportation/cards/card-02.webp",
            "mp3": {
                "ar": "memoryGame/themes/transportation/sounds/ship_ar.mp3",
                "fr": "memoryGame/themes/transportation/sounds/ship_fr.mp3",
                "en": "memoryGame/themes/transportation/sounds/ship_en.mp3"
            },
            "funFact": "السفن تُستخدم لنقل البضائع والركاب عبر البحار.",
            "info": {
                "description": "السفينة هي وسيلة نقل بحرية كبيرة.",
                "bodyInfo": [
                    "تستخدم لنقل البضائع عبر المحيطات.",
                    "توجد أنواع مثل السفن السياحية وسفن الشحن.",
                    "تعمل بواسطة المحركات أو الأشرعة."
                ],
                "funFacts": [
                    "تعتبر السفن من أقدم وسائل النقل.",
                    "تساعد في التجارة الدولية.",
                    "تحتوي على تجهيزات للراحة والترفيه."
                ]
            }
        },
        {
            "id": 3,
            "name": "الطائرة",
            "slug": "airplane",
            "picture": "memoryGame/themes/transportation/cards/card-03.webp",
            "mp3": {
                "ar": "memoryGame/themes/transportation/sounds/airplane_ar.mp3",
                "fr": "memoryGame/themes/transportation/sounds/airplane_fr.mp3",
                "en": "memoryGame/themes/transportation/sounds/airplane_en.mp3"
            },
            "funFact": "تعتبر الطائرة أسرع وسيلة نقل جوي.",
            "info": {
                "description": "الطائرة تُستخدم لنقل الركاب والبضائع عبر الجو.",
                "bodyInfo": [
                    "تعمل بواسطة المحركات النفاثة أو المروحية.",
                    "توجد أنواع مختلفة مثل الطائرات التجارية والخاصة.",
                    "تعتبر وسائل النقل الجوية الأكثر أمانًا."
                ],
                "funFacts": [
                    "يمكن للطائرات الطيران على ارتفاعات عالية.",
                    "تساعد في الربط بين الدول والقارات.",
                    "تعتبر الرحلات الجوية تجربة ممتعة."
                ]
            }
        },
        {
            "id": 4,
            "name": "السيارة",
            "slug": "car",
            "picture": "memoryGame/themes/transportation/cards/card-04.webp",
            "mp3": {
                "ar": "memoryGame/themes/transportation/sounds/car_ar.mp3",
                "fr": "memoryGame/themes/transportation/sounds/car_fr.mp3",
                "en": "memoryGame/themes/transportation/sounds/car_en.mp3"
            },
            "funFact": "السيارات تُستخدم للتنقل اليومي.",
            "info": {
                "description": "السيارة هي وسيلة نقل فردية أو عائلية.",
                "bodyInfo": [
                    "تعمل بواسطة محرك يعمل بالبنزين أو الكهرباء.",
                    "توجد أنواع مختلفة مثل السيارات الصغيرة والعائلية.",
                    "تعتبر من وسائل النقل الأكثر شيوعًا."
                ],
                "funFacts": [
                    "تساعد في تسهيل التنقل داخل المدن.",
                    "توجد أنواع فاخرة ورياضية من السيارات.",
                    "تمتاز بسهولة الاستخدام والراحة."
                ]
            }
        },
        {
            "id": 5,
            "name": "الدراجة النارية",
            "slug": "motorcycle",
            "picture": "memoryGame/themes/transportation/cards/card-05.webp",
            "mp3": {
                "ar": "memoryGame/themes/transportation/sounds/motorcycle_ar.mp3",
                "fr": "memoryGame/themes/transportation/sounds/motorcycle_fr.mp3",
                "en": "memoryGame/themes/transportation/sounds/motorcycle_en.mp3"
            },
            "funFact": "تعتبر الدراجات النارية وسيلة سريعة ومرنة.",
            "info": {
                "description": "الدراجة النارية تُستخدم للنقل الفردي.",
                "bodyInfo": [
                    "تعمل بواسطة محرك صغير.",
                    "تعتبر خيارًا مفضلًا في المدن ذات الازدحام.",
                    "توجد أنواع مختلفة من الدراجات النارية."
                ],
                "funFacts": [
                    "تساعد في التوفير في استهلاك الوقود.",
                    "تعتبر من وسائل النقل المثيرة.",
                    "تحتاج إلى رخصة قيادة خاصة."
                ]
            }
        },
        {
            "id": 6,
            "name": "الشاحنة",
            "slug": "truck",
            "picture": "memoryGame/themes/transportation/cards/card-06.webp",
            "mp3": {
                "ar": "memoryGame/themes/transportation/sounds/truck_ar.mp3",
                "fr": "memoryGame/themes/transportation/sounds/truck_fr.mp3",
                "en": "memoryGame/themes/transportation/sounds/truck_en.mp3"
            },
            "funFact": "تستخدم الشاحنات لنقل البضائع الثقيلة.",
            "info": {
                "description": "الشاحنة هي وسيلة نقل تجارية.",
                "bodyInfo": [
                    "تعمل بواسطة محرك قوي.",
                    "تستخدم لنقل البضائع عبر المسافات الطويلة.",
                    "توجد أنواع مختلفة مثل الشاحنات الصغيرة والكبيرة."
                ],
                "funFacts": [
                    "تعتبر من وسائل النقل الرئيسية في التجارة.",
                    "تساعد في نقل المنتجات إلى الأسواق.",
                    "تحتاج إلى رخصة قيادة خاصة."
                ]
            }
        },
        {
            "id": 7,
            "name": "التاكسي",
            "slug": "taxi",
            "picture": "memoryGame/themes/transportation/cards/card-07.webp",
            "mp3": {
                "ar": "memoryGame/themes/transportation/sounds/taxi_ar.mp3",
                "fr": "memoryGame/themes/transportation/sounds/taxi_fr.mp3",
                "en": "memoryGame/themes/transportation/sounds/taxi_en.mp3"
            },
            "funFact": "التاكسي يوفر وسيلة نقل مريحة.",
            "info": {
                "description": "التاكسي هو وسيلة نقل خاصة بالركاب.",
                "bodyInfo": [
                    "يعمل على طلب العملاء عبر الهاتف أو التطبيقات.",
                    "يستخدم للتنقل داخل المدن.",
                    "يمتاز بالراحة والسهولة."
                ],
                "funFacts": [
                    "يعتبر خيارًا مناسبًا للتنقل السريع.",
                    "تختلف الأسعار حسب المسافة.",
                    "يمكن أن يحتوي على خدمات إضافية مثل الواي فاي."
                ]
            }
        },
        {
            "id": 8,
            "name": "الحافلة",
            "slug": "bus",
            "picture": "memoryGame/themes/transportation/cards/card-08.webp",
            "mp3": {
                "ar": "memoryGame/themes/transportation/sounds/bus_ar.mp3",
                "fr": "memoryGame/themes/transportation/sounds/bus_fr.mp3",
                "en": "memoryGame/themes/transportation/sounds/bus_en.mp3"
            },
            "funFact": "تستخدم الحافلات لنقل عدد كبير من الركاب.",
            "info": {
                "description": "الحافلة هي وسيلة نقل عامة.",
                "bodyInfo": [
                    "تعمل على الطرق وتغطي مسافات طويلة.",
                    "تعتبر وسيلة اقتصادية للتنقل.",
                    "توجد أنواع مختلفة من الحافلات."
                ],
                "funFacts": [
                    "تساعد في تقليل الزحام في المدن.",
                    "تعتبر الخيار المثالي للتنقل الجماعي.",
                    "توجد جداول زمنية محددة للحافلات."
                ]
            }
        },
        {
            "id": 9,
            "name": "الدراجة",
            "slug": "bicycle",
            "picture": "memoryGame/themes/transportation/cards/card-09.webp",
            "mp3": {
                "ar": "memoryGame/themes/transportation/sounds/bicycle_ar.mp3",
                "fr": "memoryGame/themes/transportation/sounds/bicycle_fr.mp3",
                "en": "memoryGame/themes/transportation/sounds/bicycle_en.mp3"
            },
            "funFact": "تعتبر الدراجة وسيلة صديقة للبيئة.",
            "info": {
                "description": "الدراجة تُستخدم كوسيلة نقل فردية.",
                "bodyInfo": [
                    "تعمل بواسطة القوة البشرية.",
                    "تساعد في ممارسة الرياضة.",
                    "تعتبر وسيلة اقتصادية وصديقة للبيئة."
                ],
                "funFacts": [
                    "يمكن استخدامها في الطرق الوعرة.",
                    "تساعد في تحسين اللياقة البدنية.",
                    "تعتبر من وسائل النقل الشعبية."
                ]
            }
        },
        {
            "id": 10,
            "name": "الهيلكوبتر",
            "slug": "helicopter",
            "picture": "memoryGame/themes/transportation/cards/card-10.webp", // Updated URL to follow the card format
            "mp3": {
                "ar": "memoryGame/themes/transportation/sounds/helicopter_ar.mp3",
                "fr": "memoryGame/themes/transportation/sounds/helicopter_fr.mp3",
                "en": "memoryGame/themes/transportation/sounds/helicopter_en.mp3"
            },
            "funFact": "الهيلكوبتر يُستخدم للنقل السريع في المناطق الوعرة.",
            "info": {
                "description": "الهيلكوبتر هي وسيلة نقل جوية متعددة الاستخدامات.",
                "bodyInfo": [
                    "يمكنها الهبوط والإقلاع من أماكن صغيرة.",
                    "تستخدم في الإسعاف والطوارئ.",
                    "تعمل بواسطة مراوح دوارة."
                ],
                "funFacts": [
                    "تساعد في الوصول إلى المناطق النائية.",
                    "تعتبر وسيلة مثيرة للنقل.",
                    "تحتاج إلى رخصة قيادة خاصة."
                ]
            }
        }
    ]
};
