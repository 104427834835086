import React from 'react';
import { motion } from 'framer-motion';

export default function VersoCard({ src }) {
    return (
        <motion.img
            style={{
                width: '250px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            }}
            src={src}
            alt=""
            initial={{ x: -100, opacity: 0 }} // Initial state: off-screen to the left
            whileInView={{ x: 0, opacity: 1 }} // Animate to original position
            transition={{ duration: 0.5 }} // Duration of the animation
        />
    );
}
